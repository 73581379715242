import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createPerk, updatePerk } from '../../actions/perkActions';
import { getInterests } from '../../actions/TagActions';
import PerkForm from '../../components/PerkForm/PerkForm';

const PerkSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'The perk name must be no more than 100 characters')
    .required('Required'),
  description: Yup.string()
    .min(20, 'The description must be at least 20 characters')
    .required('Required'),
  redeemLink: Yup.string()
    .url('This link is not a valid URL'),
  interests: Yup.array()
    .min(1, 'Perks must have at least one interest'),
});
class PerkFormContainer extends Component {
  componentDidMount() {
    this.props.getInterests();
  }

  onPerkSubmit = (values) => {
    const { closeModal, merchant, perkToUpdate } = this.props;
    const perk = {};
    perk.merchant = merchant.uuid;
    perk.name = values.name.trim();
    perk.description = values.description.trim();
    perk.redeemLink = values.redeemLink;
    perk.interests = values.interests;

    if (perkToUpdate) {
      this.props.updatePerk(perk, perkToUpdate.uuid);
    } else {
      this.props.createPerk(perk);
    }
    closeModal();
  }

  render() {
    const {
      perkToUpdate,
      merchant,
      interests,
      closeModal,
      loading,
    } = this.props;

    if (loading || !interests.length) {
      return <CircularProgress color="primary" />;
    }

    let initialValues, initialTouched;
    if (perkToUpdate) {
      initialValues = {
        name: perkToUpdate.name,
        description: perkToUpdate.description,
        redeemLink: perkToUpdate.redeemLink,
        interests: perkToUpdate.interests.map(interest => interest.uuid),
      };
      initialTouched = {
        name: true,
        description: true,
        redeemLink: true,
        interests: true,
      };
    } else {
      initialValues = {
        name: '',
        description: '',
        redeemLink: '',
        interests: [],
      };
    }

    return (
      <div id="PerkContainer">
        <h3>{`${perkToUpdate ? 'Update this' : 'Create a'} perk for ${merchant.name}`}</h3>
        <Formik
          validationSchema={PerkSchema}
          onSubmit={values => this.onPerkSubmit(values)}
          initialValues={initialValues}
          initialTouched={initialTouched}
          validateOnMount={!!perkToUpdate}
        >
          {formikProps => (
            <PerkForm
              {...formikProps}
              closeModal={closeModal}
              merchant={merchant}
              interests={interests}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ tags, perks: { loading } }) => ({
  loading,
  interests: tags.interests,
});

export default connect(mapStateToProps, {
  getInterests,
  createPerk,
  updatePerk,
})(PerkFormContainer);
