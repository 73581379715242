import React, { Component } from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button, 
} from '@material-ui/core';
import { Field } from 'formik';
import "./CopyGroupForm.css";
import _ from 'lodash';

class CopyGroupForm extends Component {
    handleChange = (name, setFieldValue) => (event) => {
        setFieldValue(name, event.target.value, true);
    };

  render() {
    const {
      setFieldValue, handleSubmit, buildings, values, groups, groupSelected
    } = this.props;

    const copiedGroups = Object.values(groups).filter(g => g.name === groupSelected.name);
    const forbiddenBuildings = copiedGroups.map(g => g.building);
    const forbiddenBuildingsUnique = _.uniqWith(forbiddenBuildings, _.isEqual);
    const forbiddenBuildingUniqueIds = forbiddenBuildingsUnique.map(b => b.uuid);
    const buildingsForSelect = Object.values(buildings).filter(b => !forbiddenBuildingUniqueIds.includes(b.uuid));

    return (
      <form className="group-form" onSubmit={handleSubmit}>
        <h3>Copy group</h3>
        <div className="form">
        <Field component={() => (
          <FormControl>
            <InputLabel id="building-label">Building</InputLabel>
            <Select
              name="buildingName"
              labelId="building-label"
              value={values.building}
              onChange={this.handleChange('building', setFieldValue)}
              className="selectField"
            >
              {forbiddenBuildingsUnique.map(b => 
                <MenuItem key={b.uuid} value="" disabled>
                  {b.name}
                </MenuItem>
              )}
               
              {buildingsForSelect.map(building =>
                  <MenuItem key={building.uuid} value={building.uuid}>{building.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        )}/>
        <Button type="submit" variant="contained" color="primary" disabled = {values.building === ""}>Copy</Button>
        </div>
      </form>
    );
  }
}

export default CopyGroupForm;
