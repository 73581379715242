import React, { Component } from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { getBuildings } from '../../actions/BuildingActions';
import { getBuildingPerks, handleAutoSuggestFormChange, updateBuildingPerkValid, updatePerkClick } from '../../actions/BuildingPerkActions';
import { getMerchants } from '../../actions/MerchantActions';

import AutoSuggestForm from '../../components/AutoSuggestForm/AutoSuggestForm';
import PerkList from '../../components/PerkList/PerkList';


class BuildingPerksContainer extends Component {
  componentDidMount() {
    const {
      autoSuggestFormValue,
      getBuildingPerks,
      getBuildings,
      getMerchants
    } = this.props;
    if (autoSuggestFormValue) {
      getBuildingPerks(autoSuggestFormValue);
    }
    getBuildings();
    getMerchants();
  }

  onBuildingPerkValidSwitchChange =
  (buildingPerkUuid, buildingperkCurrentValidBoolean, buildingUuid) => {
    this.props.updateBuildingPerkValid(
      buildingPerkUuid,
      buildingperkCurrentValidBoolean,
      buildingUuid,
    );
  }

  handleBuildingChange = ({ target: { value }}) => {
    const { getBuildingPerks, handleAutoSuggestFormChange } = this.props;
    getBuildingPerks(value);
    handleAutoSuggestFormChange(value);
  };

  CreatePerkHandleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  CreatePerkButtonClickHandler = () => {
    this.props.history.push('/perks/buildingperk/create');
  }

  render() {
    if (this.props.buildingsLoading || this.props.merchantsLoading) {
      return <LinearProgress mode="indeterminate" />;
    }
    return (
      <div>
        <Fab className="create-perk-button" color="primary" aria-label="add" onClick={this.CreatePerkButtonClickHandler}>
          <AddIcon />
        </Fab>
        <AutoSuggestForm
          buildings={this.props.buildings}
          loading={this.props.buildingsLoading}
          handleChange={this.handleBuildingChange}
          autoSuggestFormValue={this.props.autoSuggestFormValue}
          state={this.state}
        />
        <PerkList
          buildingPerks={this.props.buildingPerks}
          loading={this.props.buildingPerksLoading}
          onValidSwitchChange={this.onBuildingPerkValidSwitchChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ buildings, buildingPerks, merchants }) => ({
  buildings: buildings.buildings,
  buildingsLoading: buildings.loading,
  buildingPerks: buildingPerks.buildingPerks,
  buildingPerksLoading: buildingPerks.loading,
  autoSuggestFormValue: buildingPerks.autoSuggestFormValue || '',
  merchantsLoading: merchants.loading,
  merchants: merchants.merchants,
});

export default connect(mapStateToProps, {
  getBuildings,
  getBuildingPerks,
  getMerchants,
  handleAutoSuggestFormChange,
  updateBuildingPerkValid,
  updatePerkClick,
})(BuildingPerksContainer);
