import mapKeys from 'lodash/mapKeys';

import { FETCHING_NOTES, NOTES_FETCHED, NOTE_FETCHED } from '../actions/PostActions';
import { RESET_NOTES } from '../actions/AuthActions';

const initialState = {
  loading: true,
  notes: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_NOTES:
      return { ...state, loading: true };
    case NOTES_FETCHED:
      return { ...state, notes: mapKeys(action.payload, 'uuid'), loading: false };
    case RESET_NOTES:
      return initialState;
    case NOTE_FETCHED:
      return {
        ...state,
        notes: {
          ...state.events, [action.payload.uuid]: action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};
