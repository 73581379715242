import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Card, CardContent, List, ListItem, ListItemText } from '@material-ui/core';
import placeholder from '../../images/placeholder2.png';
import './NoteDetail.css';

const NoteDetail = ({ note, loading }) => {
  if (loading || !note) {
    return <LinearProgress mode="indeterminate" />;
  }
  const renderImage = () => {
    if (note && note.image && note.image.md) {
      return <img src={note.image.md} alt="" className="note-image" />;
    }
    return <img src={placeholder} alt="" className="note-image" />;
  };
  const allComments = note.comments;
  const areComments = () => {
    if (allComments.length === 0) {
      return (
        <h3> No Comments! </h3>
      );
    }
    return (
      <h3>Comments:</h3>
    );
  };
  const renderComments = allComments.map(comment => (
    <ListItem
      key={comment.uuid}
    >
      <ListItemText
        primary={`${comment.author.firstName} ${comment.author.lastName} (${Moment(comment.createdAt).fromNow()})`}
        secondary={`${comment.contents}`}
      />
    </ListItem>
  ));


  const renderNoteDetails = () => (
    <Fade in timeout={500}>
      <Card>
        <CardContent className="note-detail-description">
          <h3>
            {`${note.author.firstName} ${note.author.lastName} (${note.building.shortName})`}
          </h3>
          <p className="note-contents">
            {note.description}
          </p>
        </CardContent>
      </Card>
    </Fade>
  );
  return (
    <div className="note-detail-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} zeroMinWidth>
          {renderImage()}
        </Grid>
        <Grid item med={12}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={12} md={12}>
              {renderNoteDetails()}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card >
                <CardContent className="comments-card-content">
                  <div>
                    <List>
                      {areComments()}
                      {renderComments}
                    </List>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Link to={`/building/${note.building.uuid}`}> Back </Link>
    </div>
  );
};
export default NoteDetail;
