import React from 'react';
import momentTimezone from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card, CardContent,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Modal,
  Fade,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';


import EditEventForm from '../EditEventForm/EditEventForm';
import RenderCheckInRsvp from '../RenderCheckInRsvp/RenderCheckInRsvp';
import RenderBuildingResidentDirectory from '../RenderBuildingResidentDirectory/RenderBuildingResidentDirectory';


import placeholder from '../../images/placeholder2.png';
import Tags from '../Tags/Tags';
import './EventDetail.css';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'string',
];

const updateEventSchema = Yup.object().shape({
  title: Yup.string()
    .required('The Title of the Event is required'),
  startTime: Yup.date()
    .required('Please include the start time of the Event'),
  locationString: Yup.string()
    .required('Please include the locatiion of the Event'),
  description: Yup.string()
    .min(10, 'The description must be at least 10 characters')
    .required('Please include a description'),
  maxGuests: Yup.number()
    .min(0, 'Max Guests must be at least 0')
    .max(30, 'Max Guests cannot exceed 30 guests')
    .required('Please include the maximum number of guests one can bring'),
  maxEventAttendees: Yup.number()
    .min(0, 'Max Capacity must be at least 0')
    .max(500, 'Max Capacity cannot exceed 500')
    .required('Please include the maximum number of spaces available'),
  interests: Yup.array()
    .min(1, 'Please select at least one Interest')
    .required('Please select at least one Interest'),
  structures: Yup.array()
    .min(1, 'Please select at least one Structure')
    .required('Please select at least one Structure'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      value => (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});

const EventDetail = ({
  event,
  loading,
  CheckInCsv,
  editEventClicked,
  onEditEventButtonClick,
  eventEditSubmitHandler,
  onDeleteEventButtonClick,
  deleteEventModalOpen,
  onDeleteEventModalNotConfirmed,
  onConfirmDeleteEventButtonClick,
  buildingResidents,
  buildingResidentsLoading,
  handleResidentCardClick,
  onCheckInResidentButtonClick,
  toggleResidentCheckIn,
  onChangeSendPushNotificationSwitch,
  onChangeSendFeedbackSwitch,
  structures,
  interests,
  handleEventFeedback,
}) => {
  if (loading || !event) {
    return <LinearProgress mode="indeterminate" />;
  }
  const eventDataClone = { ...event };

  const timeDateWithTimeZone = momentTimezone.tz(event.startTime, event.building.timezone);
  eventDataClone.startTime = timeDateWithTimeZone.format('YYYY-MM-DDTHH:mm');

  const renderImage = () => {
    if (!toggleResidentCheckIn) {
      if (event && event.image && event.image.md) {
        return <img src={event.image.md} alt="" className="event-image" />;
      }
      return <img src={placeholder} alt="" className="event-image" />;
    }
    return null;
  };
  const rsvps = event.checkIns.filter(checkIn => checkIn.type === 'rsvp');
  const checkIns = event.checkIns.filter(checkIn => checkIn.type === 'checkIn');
  const checkInUserIds = _.map(checkIns, 'user.uuid');
  const csvFileName = `${event.title}_at_${event.building.name}.csv`;
  const buildingResidentsMinusCheckIns = _.omit(buildingResidents, checkInUserIds);

  const totalAttendeeCount = (eventCheckIns) => {
    let checkInAttendeeCount = 0;
    eventCheckIns.forEach((checkIn) => {
      checkInAttendeeCount += checkIn.totalAttendees;
    });
    return checkInAttendeeCount;
  };

  const renderCSVButton = () => {
    if (event.checkIns.length > 0 && CheckInCsv != null) {
      return (
        <CSVLink data={CheckInCsv} filename={csvFileName}>
          <Button variant="contained">
            CheckIns to CSV
          </Button>
        </CSVLink>);
    }
    return null;
  };
  const renderEventDetails = () => {
    if (!editEventClicked) {
      totalAttendeeCount(rsvps);
      return (
        <Fade in={!editEventClicked} timeout={500}>
          <Card>
            <CardContent className="event-detail-description">
              <h3>{event.title} in {event.building.shortName}</h3>
              <p><strong>Description:</strong> {event.description}</p>
              <p><strong>Location:</strong> {event.locationString}</p>
              <p><strong>Start time:</strong> {timeDateWithTimeZone.format('h:mm a, MMMM Do YYYY')}</p>
              <p><strong>Max guests:</strong> {event.maxGuests || 'none'}</p>
              <p><strong>Max Capacity:</strong> {event.maxEventAttendees || 'none'}</p>
              <Tags type="structures" tagObjects={event.structures} presentational />
              <Tags type="interests" tagObjects={event.interests} presentational />
              <div className="check-in-rsvp-expansion-container">
                <div className="check-in-rsvp-expansion-panel">
                  <ExpansionPanel >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      {`Check ins: ${totalAttendeeCount(checkIns)}`}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <ul> <RenderCheckInRsvp CheckInsOrRsvps={checkIns} /> </ul>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div className="check-in-rsvp-expansion-panel">
                  <ExpansionPanel >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      {`Rsvps: ${totalAttendeeCount(rsvps)}`}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <ul> <RenderCheckInRsvp CheckInsOrRsvps={rsvps} /> </ul>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>
              <div className="event-detail-button-group">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onEditEventButtonClick}
                >
                    Edit Event
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onDeleteEventButtonClick}
                >
                    Delete Event
                </Button>
                {renderCSVButton()}
                <Button
                  variant="text"
                  color="primary"
                  onClick={onCheckInResidentButtonClick}
                >
                    Check In Residents
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEventFeedback}
                >
                  Event Feedback
                </Button>
              </div>
              <FormControlLabel
                className="event-send-notifcation-switch"
                control={
                  <Switch
                    onChange={() => onChangeSendPushNotificationSwitch(
                      event.uuid,
                      event.pushNotifyResidents,
                     )}
                    checked={event.pushNotifyResidents}
                    color="primary"
                  />
                  }
                label="Send Notification To Residents"
              />
              <div>
                <FormControlLabel
                  className="event-request-feedback-switch"
                  control={
                    <Switch
                      onChange={() => onChangeSendFeedbackSwitch(
                        event.uuid,
                        event.requestResidentFeedback,
                      )}
                      checked={event.requestResidentFeedback}
                      color="primary"
                    />
                    }
                  label="Send Feedback To Residents"
                />
              </div>
            </CardContent>
          </Card>
        </Fade>
      );
    }
    return null;
  };

  const renderEventEditForm = () => {
    const image = { preview: eventDataClone.image.md, type: 'string' };
    const initialValues = {
      ...eventDataClone, interests: _.map(event.interests, 'uuid'), structures: _.map(event.structures, 'uuid'), image,
    };
    const initialTouched = {
      title: true,
      locationString: true,
      description: true,
      maxGuests: true,
      maxEventAttendees: true,
      startTime: true,
      structures: true,
      interests: true,
      image: true,
    }
    if (editEventClicked) {
      return (
        <Fade in={editEventClicked} timeout={750}>
          <Card>
            <CardContent>
              <div className="edit-event-form-container">
                <Formik
                  initialValues={initialValues}
                  initialTouched={initialTouched}
                  validateOnMount={true}
                  validationSchema={updateEventSchema}
                  onSubmit={eventEditSubmitHandler}
                >
                  {formikProps => (
                    <EditEventForm
                      {...formikProps}
                      onEditEventButtonClick={onEditEventButtonClick}
                      structures={structures}
                      interests={interests}
                    />
                  )}
                </Formik>
              </div>
            </CardContent>
          </Card>
        </Fade>
      );
    }
    return null;
  };

  const BuildingResidentDirectory = () => {
    if (toggleResidentCheckIn) {
      return (
        <RenderBuildingResidentDirectory
          buildingResidents={buildingResidentsMinusCheckIns}
          loading={buildingResidentsLoading}
          handleResidentCardClick={handleResidentCardClick}
        />
      );
    }
    return null;
  };
  return (
    <div className="event-detail-container">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5} zeroMinWidth>
          {BuildingResidentDirectory()}
          {renderImage()}
        </Grid>
        <Grid item xs={12} sm={7}>
          {renderEventDetails()}
          {renderEventEditForm(eventDataClone)}
        </Grid>
      </Grid>
      <Modal
        open={deleteEventModalOpen}
        onClose={onDeleteEventModalNotConfirmed}
      >
        <div className="modal">
          <h1> Confirm Delete</h1>
          <div className="modal-button-cancel">
            <Button
              variant="text"
              color="primary"
              onClick={onDeleteEventModalNotConfirmed}
            >
                Cancel
            </Button>
          </div>
          <div className="modal-button-confirm">
            <Button
              variant="text"
              color="secondary"
              onClick={onConfirmDeleteEventButtonClick}
            >
                Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Link to={`/building/${event.building.uuid}`}>
        <Button
          className="create-event-submit-button"
          variant="contained"
          color="primary"
          type="submit"
        >
            Back
        </Button>
      </Link>
    </div>
  );
};

export default EventDetail;
