import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

const categories = [
  'Restaurant',
  'Entertainment',
  'Pet Care Service',
  'Cleaning Service',
  'Health & Wellness Service',
  'Fitness Provider',
  'Child Care Service',
  'Car Wash Service',
  'Laundry',
  'Handyman Service',
  'Car Sharing',
  'Home',
  'Suggestion Box',
  'Dry Cleaning',
];

const MerchantForm = ({
  handleSubmit,
  handleChange,
  touched,
  errors,
  values,
  handleBlur,
  closeForm,
  buildings,
}) => (
  <form
    autoComplete="off"
    onSubmit={handleSubmit}
  >
    <div>
      <TextField
        name="name"
        value={values.name}
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
        label="Name"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
      />
      <div className="merch-select-container">
        <Field
          component={() => (
            <FormControl
              variant="outlined"
              error={touched.category && Boolean(errors.category)}
              fullWidth
            >
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={values.category}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>Select a Category</MenuItem>
                {categories.map((category) => (
                  <MenuItem value={category} key={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {touched.category && Boolean(errors.category) ? errors.category : 'This will be the category displayed for Perks'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>
      <div className="merch-select-container">
        <Field
          component={() => (
            <FormControl
              variant="outlined"
              error={touched.building && Boolean(errors.building)}
              fullWidth
            >
              <InputLabel>Building</InputLabel>
              <Select
                name="building"
                value={values.building}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>Select a Building</MenuItem>
                {buildings.map((building) => (
                  <MenuItem value={building.uuid} key={building.uuid}>
                    {building.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {touched.category && Boolean(errors.category) ? errors.category : 'This will be the building associated with this merchant.'}
              </FormHelperText>
            </FormControl>
          )}
        />
      </div>
    </div>

    <Field
      name="image"
      component={(formikProps) => <ImageDropzone {...formikProps} />}
    />
    <div className="submit-button-container">
      <Button
        className="merchant-submit-button"
        variant="contained"
        color="primary"
        type="submit"
      >
        Submit
      </Button>
      <Button
        variant="text"
        color="secondary"
        onClick={closeForm}
      >
        Cancel
      </Button>
    </div>
  </form>
);

export default MerchantForm;
