/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export default function Accept(props) {
  const onDrop = useCallback((acceptedFiles) => {
    props.handleImageDrop(acceptedFiles, props.formikProps);
  }, [props]);

  const {
    getRootProps, getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 9961472,
    onDrop,
  });

  return (
    <section className="container">
      <div id="dropzone" {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>
    </section>
  );
}
