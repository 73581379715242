import React from 'react';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';

const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  content: {
    fontStyle: 'italic',
    marginTop: 7,
    marginBottom: 7,
  },
  commentDetail: {
    color: '#808080',
    fontWeight: '200',
    fontSize: 13,
    marginTop: 7,
  },
  textContainer: {
    alignContent: 'flex-start',
    flexGrow: 1,
  },
  name: {
    marginTop: 0,
    marginBottom: 5,
  },
  removed: {
    color: '#C5C9CE',
    textDecoration: 'line-through',
  },
  removedIndicator: {
    color: '#C4C4C4',
    marginTop: 0,
    marginBottom: 5,
    fontVariant: 'small-caps',
  },
};

const parentStyles = {
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 20,
  },
};

const childStyles = {
  image: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginLeft: 60,
    marginRight: 15,
  },
};

const renderMessageToAdmin = (
  removed,
  removedBy,
  hidden,
  parentRemoved,
  parentHidden,
  styles,
) => {
  if (removed) {
    return (
      <p style={styles.removedIndicator}>
        comment has been
        {' '}
        <strong>
          removed by
          {' '}
          {removedBy}
        </strong>
      </p>
    );
  }
  if (hidden) {
    return (
      <p style={styles.removedIndicator}>
        comment has been
        {' '}
        <strong>hidden</strong>
      </p>
    );
  }
  if (parentRemoved || parentHidden) {
    return (
      <p style={styles.removedIndicator}>
        {`This reply is not displayed. Parent comment has been
        ${parentRemoved ? 'removed' : 'hidden'}.`}
      </p>
    );
  }
  return null;
};

const renderTime = (commentTime) => {
  const timeFromNow = moment(commentTime).fromNow();

  if (timeFromNow === 'a day ago') {
    return 'Yesterday';
  }
  if (timeFromNow.includes('month')) {
    return `${timeFromNow} on ${moment(commentTime).format('MMM D')}`;
  }
  return timeFromNow;
};

const Comment = ({
  comment,
  parentRemoved,
  parentHidden,
  comment: { removed, hidden, removedBy },
  handleRemovedSwitch,
  handleHiddenSwitch,
  buildingName,
}) => {
  let styles;
  if (comment.parent) {
    styles = { ...commonStyles, ...childStyles };
  } else {
    styles = { ...commonStyles, ...parentStyles };
  }
  return (
    <div style={styles.container}>
      <img style={styles.image} alt="avatar" src={comment.user.profileImage} />
      <div style={styles.textContainer}>
        {renderMessageToAdmin(
          removed,
          removedBy,
          hidden,
          parentRemoved,
          parentHidden,
          styles,
        )}
        <h4
          style={
            removed || hidden
              ? { ...styles.name, ...styles.removed }
              : styles.name
          }
        >
          {`${comment.user.firstName} ${comment.user.lastName}`}
        </h4>
        <p
          style={
            removed || hidden
              ? { ...styles.content, ...styles.removed }
              : styles.content
          }
        >
          {comment.contents}
        </p>
        <p style={styles.commentDetail}>{renderTime(comment.createdAt)}</p>
      </div>
      {!hidden && (
        <div>
          Deleted:
          <Switch
            onChange={() => handleRemovedSwitch(comment)}
            checked={removed}
            color="primary"
          />
        </div>
      )}
      {!removed && (
        <div>
          Hidden:
          <Switch
            onChange={() => handleHiddenSwitch(comment)}
            checked={hidden}
            color="secondary"
          />
        </div>
      )}
    </div>
  );
};

export default Comment;
