/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../CreateBuildingUserForm/CreateBuildingUserForm.css';

const NAME_REGEX = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð1234567890 ,.'-]+$/u;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^\d{10}$/;

const ValidatedUserForm = Yup.object().shape({
  building: Yup.string().required('Required'),
  firstName: Yup.string()
    .required('Required')
    .max(25, 'Oops! We can only accept 25 characters in this section.')
    .matches(
      NAME_REGEX,
      'Invalid Format',
    ),
  lastName: Yup.string()
    .required('Required')
    .max(25, 'Oops! We can only accept 25 characters in this section.')
    .matches(
      NAME_REGEX,
      'Invalid Format',
    ),
  email: Yup.string()
    .required('Required')
    .matches(
      EMAIL_REGEX,
      'Invalid Email',
    ),
  phone: Yup.string()
    .required('Required')
    .matches(PHONE_REGEX, 'Invalid Phone'),
  roomNumber: Yup.string().required('Required'),
  password: Yup.string().required('Required!').min(6, 'Enter more than 5 characters for password'),
});

const CreateVerifiedUserForm = ({ buildings, onCreateVerifiedUserPress }) => {
  const buildingArr = Object.values(buildings);
  return (
    <div>
      <h3>Create Verified User</h3>
      <Formik
        initialValues={{
          firstName: '', lastName: '', building: '', roomNumber: '', email: '', phone: '', password: '',
        }}
        validationSchema={ValidatedUserForm}
        onSubmit={onCreateVerifiedUserPress}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
        }) => (
          <form onSubmit={handleSubmit} id="building-user-form">
            <div className="half-form-width">
              <label className="select-label" htmlFor="admin-building-select">Building</label>
              <select
                id="admin-building-select"
                name="building"
                value={values.building}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="" disabled>Select Building</option>
                {buildingArr.map((building) => (
                  <option key={building.uuid} value={building.uuid}>
                    {building.name}
                  </option>
                ))}
              </select>
              <ErrorMessage name="building" render={(msg) => <div className="error-message-label">{msg}</div>} />
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="email"
                  label="Email"
                  onChange={handleChange}
                  error={errors.email}
                  fullWidth
                />
                <ErrorMessage name="email" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="firstName"
                  label="First Name"
                  onChange={handleChange}
                  error={errors.firstName}
                  fullWidth
                />
                <ErrorMessage name="firstName" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="lastName"
                  label="Last Name"
                  onChange={handleChange}
                  error={errors.lastName}
                  fullWidth
                />
                <ErrorMessage name="lastName" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="phone"
                  label="Phone"
                  onChange={handleChange}
                  error={errors.phone}
                  fullWidth
                />
                <ErrorMessage name="phone" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="roomNumber"
                  label="Room Number"
                  onChange={handleChange}
                  error={errors.roomNumber}
                  fullWidth
                />
                <ErrorMessage name="roomNumber" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <div>
                <TextField
                  name="password"
                  label="Password"
                  onChange={handleChange}
                  error={errors.password}
                  type="password"
                  fullWidth
                />
                <ErrorMessage name="password" render={(msg) => <div className="error-message-label">{msg}</div>} />
              </div>
            </div>
            <div className="half-form-width">
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateVerifiedUserForm;
