/* eslint-disable no-tabs */
import React from 'react';
import {
  LinearProgress,
  IconButton,
  TableContainer,
  Paper,
} from '@material-ui/core';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Table from '../Table/Table';
import '../GroupsProposals/GroupsProposals.css';

const Styles = styled.div`
	padding: 1rem;
	white-space: nowrap;
	overflow-x: auto;
	table {
		display: inline-block;
		border-spacing: 0;
		tr {
			:nth-child(even) {
				background-color: #f2f2f2;
			}
			:hover {
				background-color: #e9e9e9;
			}
		}

		th,
		td {
			white-space: normal;
			margin: 0;
			padding: 0.5rem;
			text-align: center;
		}
	}

	input {
		width: 100%;
	}
`;

const ScheduledComments = ({
  scheduledComments, handleEdit, handleDelete, loading,
}) => {
  const tableArray = React.useMemo(
    () => [
      {
        Header: 'Scheduled chat comments',
        id: 'scheduledComments',
        columns: [
          {
            Header: 'Contents',
            accessor: 'contents',
            id: 'Contents',
            filter: 'fuzzyText',
          },
          {
            Header: 'Building',
            accessor: 'building.name',
            id: 'buildingname',
            filter: 'fuzzyText',
          },
          {
            Header: 'Post Time',
            id: 'postTime',
            filterable: false,
            accessor: (comment) => (
              <div>
                <div>{moment.parseZone(comment.meta?.postTime).format('MMM D, YYYY')}</div>
                <div>{moment.parseZone(comment.meta?.postTime).format('ddd [@] h:mm a')}</div>
              </div>
            ),
          },
          {
            Header: 'Actions',
            id: 'actions',
            width: 140,
            Cell: (data) => {
              const { uuid, startTime } = data.row.original;
              return (
                <div>
                  {!moment().isAfter(startTime) && (
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    size="small"
                    onClick={() => handleEdit(uuid)}
                  >
                    <EditIcon />
                  </IconButton>
                  )}
                  <IconButton
                    aria-label="Delete"
                    color="secondary"
                    size="small"
                    onClick={() => handleDelete(uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  if (loading || scheduledComments.length < 0) return <LinearProgress mode="indeterminate" />;

  return (
    <TableContainer className="tableContainer" component={Paper}>
      <Styles>
        <Table
          tableName="buildings"
          data={Object.values(scheduledComments)}
          totalDataCount={Object.values(scheduledComments).length}
          columns={tableArray}
        />
      </Styles>
    </TableContainer>
  );
};

export default ScheduledComments;
