import React from 'react';
import size from 'lodash/size';
import {
  LinearProgress,
  Button,
  TableContainer,
  Paper,
} from '@material-ui/core';
import styled from 'styled-components';
import Table from '../Table/Table';


import '../GroupsProposals/GroupsProposals.css';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;

const GroupsApproved = ({
  buildings,
  loading,
  handleViewComments,
}) => {
  const tableArray = React.useMemo(() => [
    {
      Header: 'Chats',
      id: 'chats',
      columns: [
        {
          Header: 'Building',
          width: 140,
          accessor: 'name',
          id: 'buildingname',
          filter: 'fuzzyText',
        },
      ],
    },
    {
      Header: 'Additional Info',
      id: 'additionalinfo',
      columns: [
        {
          Header: 'Comments',
          id: 'comments',
          width: 125,
          Cell: ({ row }) => (
            <Button
              color="primary"
              size="small"
              onClick={() => handleViewComments(row.original.uuid)}
            >
              Show comments
            </Button>
          ),
        },
      ],
    },
  ], [handleViewComments]);

  if (loading || !size(buildings)) {
    return <LinearProgress mode="indeterminate" />;
  }

  return (
    <TableContainer className="tableContainer" component={Paper}>
      <h2>Chat</h2>
      <Styles>
        <Table
          tableName="buildings"
          data={Object.values(buildings)}
          totalDataCount={Object.values(buildings).length}
          columns={tableArray}
        />
      </Styles>
    </TableContainer>
  );
};

export default GroupsApproved;
