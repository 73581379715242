import React, { Component } from 'react';

import GroupsProposalsContainer from '../GroupsProposalsContainer/GroupsProposalsContainer';
import GroupsRejectedContainer from '../GroupsRejectedContainer/GroupsRejectedContainer';
import './GroupsContainer.css';

class GroupsContainer extends Component {
  render() {
    return (
      <div id="GroupsContainer">
        <GroupsProposalsContainer />
        <GroupsRejectedContainer />
      </div>
    );
  }
}

export default GroupsContainer;
