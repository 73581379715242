import axios from 'axios';
import bugsnag from 'bugsnag-js';
import { map } from 'lodash';
import Cookies from 'universal-cookie';
import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCH_PROPOSEDGROUPS = 'FETCH_PROPOSEDGROUPS';
export const PROPOSEDGROUPS_FETCHED = 'PROPOSEDGROUPS_FETCHED';
export const REJECTEDGROUPS_FETCHED = 'REJECTEDGROUPS_FETCHED';
export const PROPOSEDGROUPS_UPDATED = 'PROPOSEDGROUPS_UPDATED';
export const PROPOSEDGROUPS_DELETED = 'PROPOSEDGROUPS_DELETED';
export const CHOOSE_GROUP = 'CHOOSE_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const GROUP_CREATED = 'GROUP_CREATED';
export const GET_GROUPS = 'GET_GROUPS';
export const GROUPS_FETCHED = 'GROUPS_FETCHED';
export const GROUP_UPDATED = 'GROUP_UPDATED';
export const GROUP_DELETED = 'GROUP_DELETED';
export const OPEN_GROUP_EVENT_SNACKBAR = 'OPEN_GROUP_EVENT_SNACKBAR';


export const getGroupActions = (rejected) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: FETCH_PROPOSEDGROUPS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/proposedgroup`,
    headers: { token },
    params: { rejected },
  })
    .then((response) => {
      if (!rejected) {
        dispatch({
          type: PROPOSEDGROUPS_FETCHED,
          payload: response.data,
        });
      } else {
        dispatch({
          type: REJECTEDGROUPS_FETCHED,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const patchApprovedStatus = (proposedGroup) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  const data = { approved: !proposedGroup.approved };
  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/proposedgroup/${proposedGroup.uuid}`,
    headers: { token },
    data,
  })
    .then((response) => {
      dispatch({
        type: PROPOSEDGROUPS_UPDATED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const chooseGroup = (group) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: CHOOSE_GROUP,
    payload: group,
  });
};

export const createGroup = (group) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  const data = { loading: true };
  dispatch({
    type: CREATE_GROUP,
    payload: data,
  });

  axios({
    method: 'POST',
    url: `${ROOT_URL}/group/`,
    headers: { token },
    data: group,
  })
    .then((response) => {
      dispatch({
        type: GROUP_CREATED,
        payload: response.data,
      });
      getGroupActions();
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getGroupsFromUserBuildings = (buildingUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: GET_GROUPS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/group`,
    headers: { token },
    params: { building: [buildingUuid] },
  })
    .then((groupResponse) => {
      dispatch({
        type: GROUPS_FETCHED,
        payload: groupResponse.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getGroups = (buildingUuids, active, reload) => (
  dispatch,
) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  if (!reload) {
    dispatch({
      type: GET_GROUPS,
    });
  }
  axios({
    method: 'GET',
    url: `${ROOT_URL}/group`,
    headers: { token },
    params: { building: buildingUuids, active, limit: 300 },
  })
    .then((response) => {
      dispatch({
        type: GROUPS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateProposedGroup = (proposedGroup, reason) => (
  dispatch,
) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  const data = {
    rejected: !proposedGroup.rejected,
    rejectionReason: reason,
    approved: false,
  };
  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/proposedgroup/${proposedGroup.uuid}`,
    headers: { token },
    data,
  })
    .then((response) => {
      dispatch({
        type: PROPOSEDGROUPS_UPDATED,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.message,
        },
      });
      bugsnagClient.notify(error);
    });
};

export const deleteProposedGroup = (proposedGroup) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    method: 'DELETE',
    url: `${ROOT_URL}/proposedgroup/${proposedGroup.uuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: PROPOSEDGROUPS_DELETED,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.message,
        },
      });
      bugsnagClient.notify(error);
    });
};

export const updateTheGroup = (group, updatedGroup) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/v1/group/${group.uuid}`,
    headers: { token },
    data: updatedGroup,
  })
    .then((response) => {
      dispatch({
        type: GROUP_UPDATED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const deleteTheGroup = (group) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    method: 'DELETE',
    url: `${ROOT_URL}/group/${group.uuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: GROUP_DELETED,
        payload: response.status,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.message,
        },
      });
      bugsnagClient.notify(error);
    });
};
