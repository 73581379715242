import mapKeys from 'lodash/mapKeys';
import {
  SET_MERCHANT_LOADING,
  OPEN_MERCHANT_SNACKBAR,
  MERCHANTS_FETCHED,
  MERCHANT_FETCHED,
} from '../actions/MerchantActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';

const initialState = {
  merchants: {},
  loading: true,
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_MERCHANT_LOADING:
      return { ...state, loading: payload };
    case MERCHANTS_FETCHED:
      return { ...state, merchants: mapKeys(payload, 'uuid'), loading: false };
    case MERCHANT_FETCHED:
      return {
        ...state,
        merchants: { ...state.merchants, [payload.uuid]: payload },
      };
    case OPEN_MERCHANT_SNACKBAR:
      return { ...state, snackbarOpen: true, errorMessage: payload.errorMessage };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};
