import React from 'react';

import { GridList, GridListTile, GridListTileBar, CardMedia } from '@material-ui/core';
import map from 'lodash/map';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';

import placeholder from '../../images/placeholder2.png';
import './PerkList.css';


const PerkList = ({ buildingPerks, loading, onValidSwitchChange }) => {
  if (_.size(buildingPerks) === 0) {
    return null;
  }

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }
  if (!loading) {
    const renderImage = (perk) => {
      const { merchant } = perk;
      if (merchant.image) {
        return <CardMedia className="perk-list-perk-image" image={merchant.image} alt="" />;
      }
      return <CardMedia className="perk-list-perk-image" image={placeholder} alt="" />;
    };

    return (
      <div className="perk-list-container">
        <GridList
          cellHeight={150}
          className="perk-grid-list"
          cols={3}
          spacing={5}
        >
          {map(buildingPerks, buildingPerk => (

            <GridListTile
              key={buildingPerk.uuid}
            >
              {renderImage(buildingPerk.perk)}
              <GridListTileBar
                title={buildingPerk.perk.merchant.name}
                subtitle={buildingPerk.perk.description}
                actionIcon={<Switch
                  onChange={() =>
                    onValidSwitchChange(
                      buildingPerk.uuid,
                      buildingPerk.valid,
                      buildingPerk.building,
                      )
                  }
                  checked={buildingPerk.valid}
                  value={buildingPerk.uuid}
                  color="primary"
                />}
              />
            </GridListTile>
        ))}
        </GridList>
      </div>
    );
  }
  return null;
};

export default PerkList;
