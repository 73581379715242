const JsonToCSV = (JsonArray) => {
  const JsonFields = ['firstName', 'lastName', 'unitNumber', 'email', 'phoneNumber', 'leaseStartDate', 'leaseEndDate', 'moveInDate', 'buildingName'];

  let csvStr = `${JsonFields.join(',')}\n`;
  JsonArray.forEach((element) => {
    const { firstName } = element;
    const { lastName } = element;
    const { unitNumber } = element;
    const { email } = element;
    const { phoneNumber } = element;
    const { leaseStartDate } = element;
    const { leaseEndDate } = element;
    const { moveInDate } = element;
    const { buildingName } = element;
    csvStr += `${firstName},${lastName},${unitNumber},${email},${phoneNumber},${leaseStartDate},${leaseEndDate},${moveInDate},${buildingName}\n`;
  });
  return csvStr;
};

export default JsonToCSV;
