import React, { Component } from 'react';
import { connect } from 'react-redux';

import GroupsRejected from '../../components/GroupsRejected/GroupsRejected';
import { getGroupActions, chooseGroup, deleteProposedGroup } from '../../actions/GroupActions';
import CustomModal from '../../components/CustomModal/CustomModal';
import ReviewRejectedGroup from '../../components/ReviewRejectedGroup/ReviewRejectedGroup';
import '../GroupsContainer/GroupsContainer.css';

class GroupsRejectedContainer extends Component {
  state = { showModal: false };

  componentDidMount() {
    this.props.getGroupActions(true);
  }

  reviewRejectedGroup = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row.original);
    this.handleShowModal(true);
  }

  deleteRejectedGroup = (row) => {
    const { deleteProposedGroup } = this.props;
    if (window.confirm('Are you sure you want to delete this rejected group proposal?')) deleteProposedGroup(row.original);
  }

  handleShowModal = (show) => {
    this.setState({ showModal: show });
  }

  renderModal = () => {
    const { rejectedGroup } = this.props;
    const content = (
      <ReviewRejectedGroup rejectedGroup={rejectedGroup} />
    );
    return (
      <CustomModal
        content={content}
        closeModal={() => this.handleShowModal(false)}
      />
    );
  };

  render() {
    const { showModal } = this.state;
    const { rejectedGroups, loading } = this.props;
    return (
      <div className="marginTop1">
        <GroupsRejected
          loading={loading}
          rejectedGroups={rejectedGroups}
          reviewRejectedGroup={this.reviewRejectedGroup}
          deleteRejectedGroup={this.deleteRejectedGroup}
        />
        {showModal && this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ groups }) => ({
  rejectedGroups: groups.rejectedGroups,
  loading: groups.loading,
  rejectedGroupReview: groups.rejectedGroupReview,
  rejectedGroup: groups.groupSelected,
});

export default connect(
  mapStateToProps,
  { getGroupActions, chooseGroup, deleteProposedGroup },
)(GroupsRejectedContainer);
