import React from 'react';
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import map from 'lodash/map';
import './AutoSuggestForm.css';

class AutoSuggestForm extends React.Component {
  render() {
    const { buildings, handleChange, autoSuggestFormValue } = this.props;
    const suggestions = map(buildings, (building) => ({
      value: building.uuid,
      label: building.shortName,
    }));
    return (
      <Paper className="auto-suggest-form-container">
        <h3>Perks by Building</h3>
        <FormControl fullWidth>
          <InputLabel>Building</InputLabel>
          <Select
            name="building"
            value={autoSuggestFormValue}
            onChange={handleChange}
          >
            <MenuItem value="" disabled>Select Building</MenuItem>
            {suggestions.map(suggestion => (
              <MenuItem value={suggestion.value} key={suggestion.label}>
                {suggestion.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Select the building to view available perks
          </FormHelperText>
        </FormControl>
      </Paper>
    );
  }
}

export default AutoSuggestForm;
