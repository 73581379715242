import React from "react";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const ReviewUrlForm = ({
  handleSubmit,
  handleChange,
  touched,
  errors,
  values,
  handleBlur,
  closeForm,
}) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <div>
      <TextField
        name="reviewUrl"
        value={values.reviewUrl}
        helperText={touched.reviewUrl ? errors.reviewUrl : ""}
        error={touched.reviewUrl && Boolean(errors.reviewUrl)}
        label="Review url"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
      />
    </div>
    <div className="submit-button-container">
      <Button
        className="merchant-submit-button"
        variant="contained"
        color="primary"
        type="submit"
      >
        Submit
      </Button>
      <Button variant="text" color="secondary" onClick={closeForm}>
        Cancel
      </Button>
    </div>
  </form>
);

export default ReviewUrlForm;
