import React from 'react';

import { Card, CardContent } from '@material-ui/core';

import '../../containers/EventFeedbackContainer/EventFeedbackContainer.css';


const EventFeedbackNone = ({ buildingInfo }) => (
  <div>
    { buildingInfo ?
      <Card id="building-title" className="animated bounce infinite">
        {buildingInfo.name}
      </Card> : null }
    <Card id="Event-Feedback-None" className="animated fadeInDown delay-1s">
      <CardContent>
              There is no Event Feedback at this time.
      </CardContent>
      <CardContent>
        Coming Soon!
      </CardContent>
    </Card>
  </div>
);


export default EventFeedbackNone;
