/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import matchSorter from 'match-sorter';
import {
  useTable,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useBlockLayout
} from 'react-table';
import {
  Table as MaUTable,
  TableSortLabel,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@material-ui/core';

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function Table({
  tableName, columns, data, renderRowSubComponent, initPageSize, totalDataCount
}) {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      width: 200,
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initPageSize || 5 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout
  );

  return (
    <div style={{ margin: '0 auto' }}>
      <MaUTable
        className="table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={`${tableName}-header${i}r1`}>
              {headerGroup.headers.map((column, j) => (
                <TableCell {...column.getHeaderProps()} key={`${tableName}-h-r2-c${j}`}>
                  <div {...column.getSortByToggleProps()}>
                    <TableSortLabel
                      active={column.isSorted}
                      hideSortIcon={!column.canSort}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      {column.render('Header')}
                    </TableSortLabel>
                  </div>
                  {column.canFilter && (
                  <div className="filter">
                    {column.render('Filter')}
                  </div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <>
                <TableRow
                  key={`${tableName}-${row.id}`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps()}
                      className="cell"
                      key={`${tableName}-c${cell.column.id}r${cell.row.id}`}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
                {row.isExpanded ? (
                  <TableRow {...row.getRowProps()} key={`${tableName}-exp${row.id}`}>
                    <TableCell key={`${tableName}-exp${row.id}cell`} colSpan={allColumns.length}>
                      {renderRowSubComponent({ row })}
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            );
          })}
        </TableBody>
      </MaUTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalDataCount || pageSize * pageCount}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={(e, newPage) => gotoPage(newPage)}
        onChangeRowsPerPage={e => setPageSize(e.target.value)}
      />
    </div>
  );
}
