import React from 'react';
import Moment from 'moment';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card, CardContent, CardHeader, Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import './UserDetail.css';

const UserDetail = ({
  loading, buildingResident, deleteUser, forbidDeactivation, blockUser,
}) => {
  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }

  const RenderBuildingResidentCheckinRsvps = () => {
    if (buildingResident.checkIns) {
      return (
        <p>
          Check ins / RSVPS:
          {buildingResident.checkIns.length}
        </p>
      );
    }
    return null;
  };
  const RenderBuildingResidentComments = () => {
    if (buildingResident.comments) {
      return (
        <p>
          Comments:
          {buildingResident.comments.length}
          {' '}

        </p>
      );
    }
    return null;
  };
  const RenderBuildingResidentPosts = () => {
    if (buildingResident.posts) {
      return (
        <p>
          Posts:
          {buildingResident.posts.length}
        </p>
      );
    }
    return null;
  };

  return (
    <Grid container alignItems="center" justify="center" spacing={3}>
      <Grid item xs={11} sm={6} md={4}>
        <Card className="user-detail-card">
          <CardHeader
            title={`${buildingResident.firstName} ${buildingResident.lastName}`}
          />
          <CardContent>
            <p>
              Resident in
              {buildingResident.building.shortName}
              {' '}

            </p>
            <p>
              Email:
              {buildingResident.email}
            </p>
            <p>
              User Created at
              {' '}
              {Moment(buildingResident.createdAt).format(
                'h:mm a, MMMM Do YYYY',
              )}
            </p>
            {RenderBuildingResidentCheckinRsvps()}
            {RenderBuildingResidentComments()}
            {RenderBuildingResidentPosts()}
            <Link to={`/building/${buildingResident.building.uuid}`}>
              {' '}
              Back
              {' '}
            </Link>
            {!buildingResident.deactivated && !forbidDeactivation && !loading && (
            <Button
              className="deactivate-btn"
              variant="outlined"
              onClick={() => deleteUser(buildingResident.uuid)}
            >
              Deactivate account
            </Button>
            )}
            {!buildingResident.deactivated && !forbidDeactivation && !loading && (
            <Button
              className="deactivate-btn"
              variant="outlined"
              onClick={() => blockUser(buildingResident.uuid)}
            >
              Block malicious user
            </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserDetail;
