import React from 'react';
import momentTimezone from 'moment-timezone';
import Button from '@material-ui/core/Button';
import './GroupEventConfirmDelete.css';

const formatTime = (groupEvent) => {
  return momentTimezone
    .tz(groupEvent.startTime, groupEvent.building.timezone)
    .format('MMM D, YYYY [at] h:mm a');
};

const handleDeleteGroupEvent = (groupEventId, deleteGroupEvent, closeForm) => {
  deleteGroupEvent(groupEventId);
  closeForm();
};

const GroupEventConfirmDelete = ({ groupEvent, deleteGroupEvent, closeForm }) => {
  return (
    <div id="group-event-confirm-delete">
      <h4>Are you sure you want to delete this gathering?</h4>
      <ul>
        <li><span>Title:</span> {groupEvent.title}</li>
        <li><span>Start Time:</span> {formatTime(groupEvent)}</li>
        <li><span>Group:</span> {groupEvent.group.name}</li>
        <li><span>Building:</span> {groupEvent.building.name}</li>
      </ul>
      <p>Deleting this gathering will also delete all RSVPs</p>
      <div>
        <Button
          variant="contained"
          color="secondary"
          aria-label="add"
          onClick={() => handleDeleteGroupEvent(groupEvent.uuid, deleteGroupEvent, closeForm)}
        >
          Confirm Delete
        </Button>
        <Button
          variant="contained"
          color="default"
          aria-label="add"
          onClick={closeForm}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default GroupEventConfirmDelete;
