import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';

import CreateEventForm from '../../components/CreateEventForm/CreateEventForm';
import { createEvent } from '../../actions/PostActions';
import { getEventStructures, getInterests } from '../../actions/TagActions';
import { getBuilding } from '../../actions/BuildingActions';
import { roles } from '../../constants';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];
const createEventSchema = Yup.object().shape({
  title: Yup.string()
    .required('The Title of the Event is required'),
  startTime: Yup.date()
    .required('Please include the start time of the Event'),
  locationString: Yup.string()
    .required('Please include the locatiion of the Event'),
  description: Yup.string()
    .min(10, 'The description must be at least 10 characters')
    .required('Please include a description'),
  maxGuests: Yup.number()
    .min(0, 'Max Guests must be at least 0')
    .max(30, 'Max Guests cannot exceed 30 guests')
    .required('Please include the maximum number of guests one can bring'),
  maxEventAttendees: Yup.number()
    .min(0, 'Max Capacity must be at least 0')
    .max(500, 'Max Capacity cannot exceed 500')
    .required('Please include the maximum number of spaces available'),
  interests: Yup.array()
    .min(1, 'Please select at least one interest')
    .required('Please select at least one interest'),
  structures: Yup.array()
    .min(1, 'Please select at least one structure')
    .required('Please select at least one structure'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});

class CreateEventContainer extends Component {
  async componentDidMount() {
    await this.props.getBuilding(this.props.match.params.buildingUuid);

    this.props.getEventStructures();
    this.props.getInterests();
  }

  eventCreateSubmitHandler = (values) => {
    const buildingTimezone = this.props.buildings[this.props.match.params.buildingUuid].timezone;
    const data = new FormData();
    data.append('title', values.title);
    data.append('description', values.description);
    data.append('startTime', momentTimezone.tz(values.startTime, buildingTimezone).toDate().toUTCString());
    data.append('type', 'event');
    data.append('locationString', values.locationString);
    data.append('structures', JSON.stringify(values.structures));
    data.append('interests', JSON.stringify(values.interests));
    data.append('maxGuests', values.maxGuests);
    data.append('maxEventAttendees', values.maxEventAttendees);
    this.props.createEvent(data, this.props.match.params.buildingUuid);

    if (values.image) {
      data.append('image', values.image);
    }
  }

  render() {
    if (this.props.buildings[this.props.match.params.buildingUuid]?.role === roles.VCM) return <Redirect to="/403" />;

    return (
      <div>
        <div className="EventForm">
          <Card id="FormTitleCard" className="animated fadeInDown delay-2s">
            <h4 className="H4TextTitle">Create Event</h4>
          </Card>
          <Formik
            initialValues={{
              startTime: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
              maxGuests: 5,
              maxEventAttendees: 100,
              locationString: '',
              image: '',
              description: '',
              title: '',
              structures: [],
              interests: [],
            }}
            validationSchema={createEventSchema}
            onSubmit={this.eventCreateSubmitHandler}
          >
            {(formikProps) => (
              <CreateEventForm
                {...formikProps}
                clearImageButtonClickHandler={this.clearImageButtonClickHandler}
                structures={this.props.structures}
                interests={this.props.interests}
              />
            )}
          </Formik>
        </div>
        <Link to={`/building/${this.props.match.params.buildingUuid}`} id="LinkText">
          <Button variant="contained" color="primary" id="materialUIBackButton" className="animated fadeInRight delay-3s">
            Back
          </Button>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ buildings, tags }) => ({
  buildings: buildings.buildings,
  structures: tags.structures,
  interests: tags.interests,
});

export default connect(mapStateToProps, {
  createEvent, getBuilding, getEventStructures, getInterests,
})(CreateEventContainer);
