import React from "react";
import { CSVLink } from "react-csv";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Card, CardContent } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import RenderBuildingResidents from "../RenderBuildingResidents/RenderBuildingResidents";
import "./RenderBuildingResidentDirectory.css";
import { roles } from "../../constants";

const entrataBuildings = [
  "Carlyle Place",
  "Meridian at Gallery Place",
  "Meridian at Eisenhower",
  "Meridian on First",
  "Meridian at Grosvenor Station",
  "Meridian at Mt Vernon Triangle",
  "Meridian at Braddock Station",
  "Madison at Ballston Station",
  "Meridian at Ballston",
  "Parc Meridian",
  "The Alloy",
  "The Emerson",
  "360H Street",
  "Eastside Bond",
  "Belvoir Square",
  "Annadel",
  "Aster",
  "Fountains at Emerald Park",
  "Verdant at Green Valley",
  "Brio",
  "Creekwood",
  "Elan Huntington Beach",
  "Next on Lex",
  "Serenity at Larkspur",
  "Summit at Sausalito",
  "The Arbors",
  "The Breakwater",
  "The Exchange",
  "Channel House",
  "Eleven Fifty Clay Street",
  "The Vale at the Parks",
  "Mason at Van Dorn",
  "Arioso Apartments",
  "The Lex at Lowry",
  "The District at SoCo",
  "Skyline Towers",
  "Park Place at Van Dorn",
  "The Hollywood Ardmore",
  "888 at Grand Hope Park",
  "Prizma",
  "Trellis House",
  "295J",
  "Dallas Urby",
  "Harrison Urby",
  "Jersey City Urby",
  "Newark Urby",
  "Stamford Urby",
  "Two South Willow",
  "Staten Island Urby",
  "Morrison Yard Residences",
  "Ovation at Arrowbrook",
  "Lone Oak",
  "The Mix at CTR City",
  "Holden at Oak Grove",
];

const RenderBuildingResidentDirectory = ({
  buildingResidents,
  loading,
  handleResidentCardClick,
  residentCSV,
  residentScoreCSV,
  buildingName,
  handleActiveUserSwitch,
  handleNewUserSwitch,
  getUserCSV,
  activeUsersOn,
  newUsersOn,
  buildingUuid,
  getEntrataData,
  getResidentScoreCSV,
}) => {
  const active = activeUsersOn ? "Active" : "";
  const newUser = newUsersOn ? "New" : "";

  const renderBuildingResidentNumber = () => {
    if (!loading) {
      return <h4>{_.size(buildingResidents)} Residents</h4>;
    }
    return null;
  };

  const fileName = `${buildingName}${active}${newUser}Residents.csv`;
  const renderCSVButton = () => {
    if (_.size(buildingResidents) > 0) {
      if (!residentCSV) {
        return (
          <Button
            variant="contained"
            color="inherit"
            onClick={() => getUserCSV()}
          >
            Export CSV
          </Button>
        );
      }
      return (
        <CSVLink data={residentCSV} filename={fileName}>
          <Button variant="contained" color="primary">
            Download Residents
          </Button>
        </CSVLink>
      );
    }
    return null;
  };
  const renderResidentScoreCSVButton = () => {
    if (_.size(buildingResidents) > 0) {
      if (!residentScoreCSV) {
        return (
          <Button
            variant="contained"
            color="inherit"
            onClick={() => getResidentScoreCSV()}
          >
            Export Resident Score CSV
          </Button>
        );
      }
      return (
        <div>
          <CSVLink data={residentScoreCSV} filename={fileName}>
            <Button variant="contained" color="primary">
              Download Residents with Score CSV
            </Button>
          </CSVLink>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Card
        raised
        className="building-resident-card"
        id="building-resident-card-scroll"
      >
        <CardContent>
          {renderBuildingResidentNumber()}
          <div className="csv-actions">
            {renderCSVButton()}
            {window.localStorage.getItem("role") === roles.SUPERADMIN &&
              renderResidentScoreCSVButton()}
            {entrataBuildings.includes(buildingName) && (
              <Button
                variant="contained"
                color="inherit"
                onClick={() => getEntrataData(buildingName, buildingUuid)}
              >
                Download Entrata CSV
              </Button>
            )}
            {/* <Button variant="contained" color="inherit" onClick={redirectToUpload}>
              Upload CSV
            </Button> */}
          </div>
          <div className="building-resident-switches">
            <FormControlLabel
              control={
                <Switch color="primary" onChange={handleActiveUserSwitch} />
              }
              label="Show Monthly Active Users"
            />
            <FormControlLabel
              control={
                <Switch color="primary" onChange={handleNewUserSwitch} />
              }
              label="Show New Users"
            />
          </div>
          <Grid container spacing={3}>
            <RenderBuildingResidents
              buildingResidents={buildingResidents}
              loading={loading}
              onClick={handleResidentCardClick}
            />
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default RenderBuildingResidentDirectory;
