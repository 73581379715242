/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export default function CSVDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    props.handleDrop(acceptedFiles[0]);
  }, [props]);

  const {
    getRootProps, getInputProps,
  } = useDropzone({
    accept: 'text/csv',
    maxSize: 1000000,
    multiple: false,
    onDrop,
  });


  return (
    <section className="container">
      <div id="dropzone" {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
      </div>
    </section>
  );
}
