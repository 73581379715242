import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from "universal-cookie";

import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const GET_EVENT_STRUCTURES = 'GET_EVENT_STRUCTURES';
export const GET_INTERESTS = 'GET_INTERESTS';

export const getEventStructures = () => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/eventstructure`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: GET_EVENT_STRUCTURES,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getInterests = () => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/interest`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: GET_INTERESTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};
