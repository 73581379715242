import React from 'react';
import '../../containers/EventFeedbackContainer/EventFeedbackContainer.css';

const GoBackToBuildingDetail = ({ buildingInfo, goBack }) => {
  if (!buildingInfo) {
    return null;
  }
  return (
    <div className="animated fadeInRight delay-2s" >
      <button
        onClick={() => goBack(buildingInfo)}
        id="back-button"
      >
        Back
      </button>
    </div>
  );
};

export default GoBackToBuildingDetail;
