import axios from "axios";
import Cookies from "universal-cookie";
import bugsnag from "bugsnag-js";
import { ROOT_URL } from "../config";
import JsonToCSV from "./JsonToCSV";

const bugsnagClient = bugsnag("8822370330acd587983bc49c6f829cd4");
const getEntrataDataScript = async (buildingName, buildingId) => {
  let leases = [];
  let propertyIds = [];

  const cookies = new Cookies();
  const token = cookies.get("token");

  switch (buildingName) {
    case "Carlyle Place":
      propertyIds = [1102202];
      break;
    case "Meridian at Gallery Place":
      propertyIds = [1102212];
      break;
    case "Meridian at Eisenhower":
      propertyIds = [1102211];
      break;
    case "Meridian on First":
      propertyIds = [1102216];
      break;
    case "Meridian at Grosvenor Station":
      propertyIds = [1102213];
      break;
    case "Meridian at Mt Vernon Triangle":
      propertyIds = [1102214, 1102215];
      break;
    case "Meridian at Braddock Station":
      propertyIds = [1102209];
      break;
    case "Madison at Ballston Station":
      propertyIds = [1102268, 1102207, 1102206];
      break;
    case "Meridian at Ballston":
      propertyIds = [1102208];
      break;
    case "Parc Meridian":
      propertyIds = [1102258];
      break;
    case "The Alloy":
      propertyIds = [1126235];
      break;
    case "The Emerson":
      propertyIds = [1126234];
      break;
    case "360H Street":
      propertyIds = [1102199];
      break;
    case "Eastside Bond":
      propertyIds = [754971];
      break;
    case "Belvoir Square":
      propertyIds = [927752];
      break;
    case "Annadel":
      propertyIds = [1072898];
      break;
    case "Aster":
      propertyIds = [1127223];
      break;
    case "Brio":
      propertyIds = [1150139];
      break;
    case "Creekwood":
      propertyIds = [1150140];
      break;
    case "Elan Huntington Beach":
      propertyIds = [1150141];
      break;
    case "Next on Lex":
      propertyIds = [1150144];
      break;
    case "Serenity at Larkspur":
      propertyIds = [1072896];
      break;
    case "Summit at Sausalito":
      propertyIds = [1150145];
      break;
    case "The Arbors":
      propertyIds = [1150146];
      break;
    case "The Breakwater":
      propertyIds = [1150147];
      break;
    case "The Exchange":
      propertyIds = [1150148];
      break;
    case "Fountains at Emerald Park":
      propertyIds = [1150142];
      break;
    case "Verdant at Green Valley":
      propertyIds = [1072897];
      break;
    case "Channel House":
      propertyIds = [1057922];
      break;
    case "Eleven Fifty Clay Street":
      propertyIds = [1057923];
      break;
    case "The Vale at the Parks":
      propertyIds = [1125655];
      break;
    case "Mason at Van Dorn":
      propertyIds = [555480];
      break;
    case "Arioso Apartments":
      propertyIds = [231581];
      break;
    case "The Lex at Lowry":
      propertyIds = [1126399];
      break;
    case "The District at SoCo":
      propertyIds = [1057903];
      break;
    case "Skyline Towers":
      propertyIds = [891184];
      break;
    case "Park Place at Van Dorn":
      propertyIds = [233409];
      break;
    case "The Hollywood Ardmore":
      propertyIds = [1057916];
      break;
    case "888 at Grand Hope Park":
      propertyIds = [1057913];
      break;
    case "Prizma":
      propertyIds = [669495];
      break;
    case "Trellis House":
      propertyIds = [630789];
      break;
    case "295J":
      propertyIds = [857886];
      break;
    case "Dallas Urby":
      propertyIds = [1165675];
      break;
    case "Harrison Urby":
      propertyIds = [1127928, 653357, 653356];
      break;
    case "Jersey City Urby":
      propertyIds = [653358];
      break;
    case "Newark Urby":
      propertyIds = [1156663];
      break;
    case "Stamford Urby":
      propertyIds = [736799];
      break;
    case "Two South Willow":
      propertyIds = [1088888];
      break;
    case "Staten Island Urby":
      propertyIds = [746483, 746484];
      break;
    case "Morrison Yard Residences":
      propertyIds = [68153];
      break;
    case "Ovation at Arrowbrook":
      propertyIds = [1134963, 1199134];
      break;
    case "Lone Oak":
      propertyIds = [1169395];
      break;
    case "The Mix at CTR City":
      propertyIds = [1203209];
      break;
    case "Holden at Oak Grove":
      propertyIds = [1240625];
      break;
    default:
      break;
  }
  const residents = [];
  try {
    await Promise.all(
      propertyIds.map(async (propertyId) => {
        const response = await axios({
          method: "GET",
          url: `${ROOT_URL}/entrata/${buildingId}`,
          headers: { token },
          params: { propertyId },
        });
        leases = response.data.response.result.leases.lease;
        await leases.forEach((lease) => {
          lease.customers.customer.forEach((customer) => {
            residents.push({
              firstName: customer.firstName,
              lastName: customer.lastName,
              unitNumber: lease.unitNumberSpace,
              email: customer.addresses.address
                ? customer.addresses.address.email
                : null,
              phoneNumber: customer.phone ? customer.phone.phoneNumber : null,
              leaseStartDate: lease.leaseIntervals.leaseInterval[0].startDate,
              leaseEndDate: lease.leaseIntervals.leaseInterval[0].endDate,
              moveInDate: customer.moveInDate,
              buildingName,
            });
          });
        });
      })
    );
  } catch (error) {
    bugsnagClient.notify(error);
  }
  const csvStr = JsonToCSV(residents);

  // download CSV
  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${buildingName}.csv`;
  hiddenElement.click();
};

export default getEntrataDataScript;
