import axios from 'axios';
import bugsnag from 'bugsnag-js';
import { ROOT_URL } from '../config';
import { OPEN_GROUP_EVENT_SNACKBAR } from './GroupActions';
import Cookies from "universal-cookie";

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const GET_GROUP_EVENT_COMMENTS = 'GET_GROUP_EVENT_COMMENTS';
export const SET_GROUP_EVENT_COMMENT_LOADING = 'SET_GROUP_EVENT_COMMENT_LOADING';
export const UPDATE_GROUP_EVENT_COMMENT = 'UPDATE_GROUP_EVENT_COMMENT';

export const getGroupEventComments = (groupEvent) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: SET_GROUP_EVENT_COMMENT_LOADING,
    payload: true,
  });
  axios({
    url: `${ROOT_URL}/groupeventcomment`,
    method: 'GET',
    headers: { token },
    params: {
      groupEvent,
      sort: 'createdAt DESC',
    },
  })
    .then((response) => {
      dispatch({
        type: GET_GROUP_EVENT_COMMENTS,
        payload: response.data,
      });
      dispatch({
        type: SET_GROUP_EVENT_COMMENT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateComment = (comment) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/groupeventcomment/${comment.uuid}`,
    method: 'PATCH',
    headers: { token },
    params: comment,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_GROUP_EVENT_COMMENT,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnag.notify(error);
    });
};

export const deleteComment = (comment) => () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/groupeventcomment/${comment.uuid}`,
    method: 'DELETE',
    headers: { token },
  })
    .then(() => {
      alert(comment.parent ? 'Comment successfully deleted' : 'Thread successfully deleted');
    })
    .catch((error) => {
      bugsnag.notify(error);
    });
};


export const createGroupEventComment = (comment, scheduled) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: scheduled ? `${ROOT_URL}/scheduledgroupeventcomment` : `${ROOT_URL}/groupeventcomment`,
    method: 'POST',
    headers: { token },
    data: comment,
  }).catch((error) => {
    dispatch({
      type: OPEN_GROUP_EVENT_SNACKBAR,
      payload: {
        snackbarOpen: true,
        errorMessage: error.response.status === 404 ? "Creator email or group provided didn't match any data in the building"
          : error.response.data.message,
      },
    });
  });
};
