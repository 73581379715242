import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { Field } from 'formik';
import Button from '@material-ui/core/Button';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

import Tags from '../Tags/Tags';
import './CreateEventForm.css';

const CreateEventForm = ({
  handleSubmit, values, touched, errors,
  interests, structures, setFieldValue, handleBlur, isValid,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFieldValue(name, value);
  };

  let datePickerLabel = 'Date';
  if (navigator.userAgent.indexOf('Firefox') > -1) {
    datePickerLabel = 'YYYY-MM-DDTHH:MM Date';
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit} >
      <TextField
        name="title"
        helperText={touched.title ? errors.title : ''}
        error={touched.title && Boolean(errors.title)}
        label="Title *"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        fullWidth
      />
      <TextField
        name="locationString"
        helperText={touched.locationString ? errors.locationString : ''}
        error={touched.locationString && Boolean(errors.locationString)}
        label="Location *"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.locationString}
        fullWidth
      />
      <TextField
        name="description"
        helperText={touched.description ? errors.description : ''}
        error={touched.description && Boolean(errors.description)}
        label="Description *"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.description}
        multiline
        rows={4}
        fullWidth
      />
      <div className="create-event-max-guests-field">
        <Field
          name="maxGuests"
          component={TextField}
          type="number"
          label="Max Guests"
          value={values.maxGuests}
          onChange={handleChange}
          InputProps={{ inputProps: { min: 0, max: 30, name: 'maxGuests' } }}
        />
        { errors.maxGuests ? <p className="interestErrorTag" style={{ marginBottom: '10px' }}> { errors.maxGuests } </p> : '' }
      </div>
      <div className="create-event-max-attendees-field">
        <Field
          name="maxEventAttendees"
          component={TextField}
          type="number"
          label="Max Capacity"
          value={values.maxEventAttendees}
          onChange={handleChange}
          InputProps={{ inputProps: { min: 0, max: 500, name: 'maxEventAttendees' } }}
        />
        { errors.maxEventAttendees ? <p className="interestErrorTag" style={{ marginBottom: '10px' }}> { errors.maxEventAttendees } </p> : '' }
      </div>
      <div className="create-event-start-time-field">
        <Field
          name="startTime"
          component={TextField}
          type="datetime-local"
          label={datePickerLabel}
          value={values.startTime}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
              }}
          InputProps={{
            inputProps:
            { name: 'startTime' },
          }}
        />
      </div>
      <Grid>
        <Field
          name="structures"
          component={formikProps => (
            <Tags
              {...formikProps}
              type="structures"
              tagObjects={structures}
            />)
          }
        />
        <Field
          name="interests"
          component={formikProps => (
            <Tags
              {...formikProps}
              type="interests"
              tagObjects={interests}
            />)
          }
        />
      </Grid>
      <Grid item xs={8} sm={4} zeroMinWidth>
        <Field
          name="image"
          component={formikProps => <ImageDropzone {...formikProps} />}
        />
      </Grid>
      <div className="submit-button-container">
        <Button
          className="create-event-submit-button"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid}
        >Submit
        </Button>
      </div>
    </form>
  );
};

export default CreateEventForm;

