import mapKeys from 'lodash/mapKeys';
import {
  SET_PERK_LOADING,
  PERK_CREATED,
  PERK_FETCHED,
  PERKS_FETCHED,
  OPEN_PERK_SNACKBAR,
} from '../actions/perkActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';

const initialState = {
  loading: true,
  perks: {},
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_PERK_LOADING:
      return { ...state, loading: payload };
    case PERK_FETCHED:
      return {
        ...state,
        perks: { ...state.perks, [payload.uuid]: payload },
      };
    case PERKS_FETCHED:
      return { ...state, perks: mapKeys(payload, 'uuid') };
    case PERK_CREATED:
      return {
        ...state,
        perks: { ...state.perks, [payload.uuid]: payload },
      };
    case OPEN_PERK_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        errorMessage: payload,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};
