import React, { Component } from 'react';
import { Field } from 'formik';
import {
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core/';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import Tags from '../Tags/Tags';

class CreateGroupForm extends Component {
  buildingList = () => Object.values(this.props.buildings)
    .map(building => <option key={building.uuid} value={building.uuid}>{building.name}</option>);

  handleCustomChange = (event, setFieldValue) => {
    const { id, value } = event.target;
    console.log(id, value, event)
    if (id === 'active' || id === 'featured') {
      setFieldValue(id, !JSON.parse(value), true);
    } else {
      setFieldValue(id, value, true);
    }
  }


  render() {
    const {
      values, errors, touched, setFieldValue, handleSubmit,
      setFieldTouched, interests, handleChange
    } = this.props;
    const buildings = Object.values(this.props.buildings);
    return (
      <form  className="group-form" onSubmit={handleSubmit}>
        <h3>Create Group Form</h3>
        <TextField
          id="title"
          name="title"
          helperText={touched.title ? errors.title : ''}
          error={touched.title && Boolean(errors.title)}
          label="Title"
          onChange={handleChange}
          onBlur={() => setFieldTouched('title', true, false)}
          value={values.title}
          fullWidth
        />
        <Field component={() => (
          <FormControl
            error={touched.building && Boolean(errors.building)}
          >
            <InputLabel id="building-label">Building</InputLabel>
            <Select
              name="building"
              labelId="building-label"
              value={values.building}
              onChange={handleChange}
            >
              <MenuItem value="" disabled>Select a Building</MenuItem>
              {buildings.map(building => (
                <MenuItem value={building.uuid} key={building.uuid}>
                  {building.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className="interestErrorTag">
              {touched.building && Boolean(errors.building) ? errors.building : null}
            </FormHelperText>
          </FormControl>
        )}/>
        <TextField
          id="description"
          name="description"
          multiline
          rows={3}
          helperText={touched.description ? errors.description : ''}
          error={touched.description && Boolean(errors.description)}
          label="Description"
          onChange={handleChange}
          onBlur={() => setFieldTouched('description', true, false)}
          value={values.description}
          fullWidth
        />
        <div>
          <Checkbox
            id="active"
            color="primary"
            checked={JSON.parse(values.active)}
            value={JSON.stringify(values.active)}
            onChange={event => this.handleCustomChange(event, setFieldValue)}
            onBlur={() => setFieldTouched('active', true, false)}
          />
          <label htmlFor="active">Viewable to residents</label>
        </div>
        <div>
        <Checkbox
          id="featured"
          color="primary"
          checked={JSON.parse(values.featured)}
          value={JSON.stringify(values.featured)}
          onChange={event => this.handleCustomChange(event, setFieldValue)}
          onBlur={() => setFieldTouched('featured', true, false)}
        />
        <label htmlFor="featured">Featured</label>
        </div>
        <Field
          name="interests"
          component={formikProps => (
            <Tags
              {...formikProps}
              type="interests"
              tagObjects={interests}
            />)
          }
        />
        <Field
          name="image"
          component={formikProps => <ImageDropzone {...formikProps} />}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
            Create Group
        </Button>
      </form >
    );
  }
}

export default CreateGroupForm;
