import _ from 'lodash';
import {
  SET_GROUP_EVENT_COMMENT_LOADING,
  GET_GROUP_EVENT_COMMENTS,
  UPDATE_GROUP_EVENT_COMMENT,
} from '../actions/GroupEventCommentActions';

const initialState = {
  comments: {},
  loading: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_GROUP_EVENT_COMMENT_LOADING:
      return { ...state, loading: payload };
    case GET_GROUP_EVENT_COMMENTS: {
      const comments = _.mapKeys(payload, 'uuid');
      return { ...state, comments };
    }
    case UPDATE_GROUP_EVENT_COMMENT: {
      const { parent, uuid: commentId } = payload;
      const commentsCopy = { ...state.comments };
      if (parent) {
        const commentCopy = commentsCopy[parent.uuid];
        const childComments = { ...commentCopy.children };

        commentCopy.children = _.map(childComments, (child) => {
          if (child.uuid === commentId) {
            return payload;
          }
          return child;
        });
        commentsCopy[parent.uuid] = commentCopy;
      } else {
        commentsCopy[commentId] = payload;
      }
      return { ...state, comments: commentsCopy };
    }
    default:
      return state;
  }
};
