import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UpdateGroupForm from '../UpdateGroupForm/UpdateGroupForm';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'string',
];

const updateGroupSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, 'The title must be no more than 500 characters')
    .required('Title is Required'),
  description: Yup.string()
    .min(10, 'The description must be at least 10 characters')
    .required('Description is required'),
  interests: Yup.array(),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});

const UpdateGroup = ({
  groupSelected,
  interests,
  handleUpdateSubmit,
  closeModal,
}) => {
  const {
    building, featured, active, name, description, imageUrl,
  } = groupSelected;
  const interestsUUIDs = [];

  const handleUpdateButton = (values) => {
    const formData = new FormData();
    formData.append('name', values.name.trim());
    formData.append('building', building.uuid);
    formData.append('description', values.description);
    formData.append('active', values.active);
    formData.append('featured', values.featured);
    formData.append('interests', JSON.stringify(values.interests));
    if (values.image.preview !== imageUrl) {
      formData.append('imageUrl', values.image);
    }
    handleUpdateSubmit(groupSelected, formData);
    closeModal();
  };

  return (
    <div>
      <Formik
        initialValues={{
          name,
          building: building.uuid,
          description,
          featured,
          active,
          buildingName: building.name,
          interests: interestsUUIDs,
          image: imageUrl ? { preview: imageUrl, type: 'string' } : null,
        }}
        validationSchema={updateGroupSchema}
        onSubmit={(values) => handleUpdateButton(values)}
      >
        {(formikProps) => (
          <UpdateGroupForm
            {...formikProps}
            interests={interests}
          />
        )}
      </Formik>
    </div>
  );
};

export default UpdateGroup;
