import mapKeys from 'lodash/mapKeys';

import {
  FETCH_PROPOSEDGROUPS,
  PROPOSEDGROUPS_FETCHED,
  REJECTEDGROUPS_FETCHED,
  PROPOSEDGROUPS_UPDATED,
  PROPOSEDGROUPS_DELETED,
  CHOOSE_GROUP,
  CREATE_GROUP,
  GROUP_CREATED,
  GET_GROUPS,
  GROUPS_FETCHED,
  GROUP_UPDATED,
  GROUP_DELETED,
  OPEN_GROUP_EVENT_SNACKBAR,
} from '../actions/GroupActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';

const initialState = {
  groupProposals: {},
  rejectedGroups: {},
  createGroup: {},
  groupSelected: {},
  loading: true,
  groups: {},
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPOSEDGROUPS:

      return { ...state, loading: true };

    case PROPOSEDGROUPS_FETCHED: {
      return {
        ...state,
        groupProposals: mapKeys(action.payload, 'uuid'),
        loading: false,
      };
    }
    case REJECTEDGROUPS_FETCHED: {
      return {
        ...state,
        rejectedGroups: mapKeys(action.payload, 'uuid'),
        loading: false,
      };
    }

    case PROPOSEDGROUPS_UPDATED: {
      return {
        ...state,
        groupProposals: {
          ...state.groupProposals,
          [action.payload.uuid]: {
            rejected: action.payload.rejected,
            approved: action.payload.approved,
          },
        },
        rejectedGroups: {
          ...state.rejectedGroups,
          [action.payload.uuid]: {
            ...action.payload,
          },
        },
      };
    }

    case PROPOSEDGROUPS_DELETED: {
      const rejectedGroups = { ...state.rejectedGroups };
      delete rejectedGroups[action.payload.uuid];
      return {
        ...state,
        rejectedGroups: { ...rejectedGroups },
      };
    }

    case CHOOSE_GROUP:
      return {
        ...state,
        groupSelected: action.payload,
      };

    case CREATE_GROUP:
      return {
        ...state,
        loading: true,
      };

    case GROUP_CREATED: {
      const groupsClone = { ...state.groups };
      groupsClone[action.payload.uuid] = action.payload;
      return {
        ...state,
        createGroup: action.payload,
        groups: groupsClone,
        loading: false,
      };
    }

    case GET_GROUPS:
      return {
        ...state,
        loading: true,
      };

    case GROUPS_FETCHED: {
      const newGroups = mapKeys(action.payload, 'uuid');
      return {
        ...state,
        groups: { ...newGroups },
        loading: false,
      };
    }

    case GROUP_UPDATED:
    const groupsCopy = { ...state.groups };
    groupsCopy[action.payload.uuid] = action.payload;
      return {
        ...state,
        groups: groupsCopy,
      };

    case GROUP_DELETED:
      const groups = { ...state.groups };
      delete groups[state.groupSelected.uuid];
      return {
        ...state,
        groupSelected: {},
        groups: { ...groups },
      };

    case OPEN_GROUP_EVENT_SNACKBAR:

      return {
        ...state,
        snackbarOpen: action.payload.snackbarOpen,
        errorMessage: action.payload.errorMessage,
      }

    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        errorMessage: '',
      };

    default:
      return state;
  }
};
