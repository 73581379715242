import React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import './GroupEventDetails.css';

const GroupEventDetails = ({ groupEvent }) => {
  const {
    title,
    description,
    image,
    locationString,
    meta: {
      startTime,
    },
    whatToBring,
    maxEventAttendees,
    maxGuests,
    group,
    building,
    creator: {
      firstName,
      lastName,
      email,
      phone,
    },
  } = groupEvent;
  const phoneString = phone ? `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}` : null;

  return (
    <Paper className="gathering-details-container">
      <h3>{title}</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {image
            ? <img className="gathering-image" src={image} alt={title} /> : (
              <div className="no-gathering-image">
                <InsertPhotoIcon fontSize="large" color="disabled" />
                <p>No Image Available</p>
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={9}>
          <div className="detail-content-container">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <p className="detail-label">Creator</p>
              </Grid>
              <Grid item xs={10}>
                <p>{`${firstName} ${lastName}`}</p>
                <p>{email}</p>
                {phone ? <p>{phoneString}</p> : ''}
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Location</p>
              </Grid>
              <Grid item xs={10}>
                <p>{locationString}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">When</p>
              </Grid>
              <Grid item xs={10}>
                <p>{moment.parseZone(startTime).format('ddd, MMM D, YYYY [at] h:mm a')}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Building</p>
              </Grid>
              <Grid item xs={10}>
                <p>{building.name}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Group</p>
              </Grid>
              <Grid item xs={10}>
                <p>{group.name}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Description</p>
              </Grid>
              <Grid item xs={10}>
                <p>{description}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">What to bring</p>
              </Grid>
              <Grid item xs={10}>
                <p>{whatToBring}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Total Number of Attendees</p>
              </Grid>
              <Grid item xs={10}>
                <p>{maxEventAttendees}</p>
              </Grid>
              <Grid item xs={2}>
                <p className="detail-label">Max Additional Guests per RSVP</p>
              </Grid>
              <Grid item xs={10}>
                <p>{maxGuests}</p>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </Grid>
    </Paper>
  );
};

export default GroupEventDetails;
