import axios from 'axios';
import bugsnag from 'bugsnag-js';
import _ from 'lodash';
import Cookies from 'universal-cookie';

import { push } from 'connected-react-router';
import { ROOT_URL } from '../config';
import { getFeedback } from './EventFeedbackActions';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHING_EVENTS = 'FETCHING_EVENTS';
export const EVENTS_FETCHED = 'EVENTS_FETCHED';
export const EVENT_FETCHED = 'EVENT_FETCHED';
export const FETCHING_NOTES = 'FETCHING_NOTES';
export const NOTES_FETCHED = 'NOTES_FETCHED';
export const EDIT_EVENT = 'EDIT_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const GET_CHECK_INS = 'GET_CHECK_INS';
export const NOTE_FETCHED = 'NOTE_FETCHED';
export const EVENT_CSV_DOWNLOADING = 'EVENT_CSV_DOWNLOADING';
export const EVENT_CSV_DOWNLOADED = 'EVENT_CSV_DOWNLOADED';
export const OPEN_EVENT_SNACKBAR = 'OPEN_EVENT_SNACKBAR';

export const getEvents = buildingUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: FETCHING_EVENTS,
  });
  const params = {
    where: {
      type: 'event',
      building: buildingUuid,
      // "createdAt": {
      //   '>': moment().subtract(7, 'days')
      // }
    },
    sort: '',
    limit: 20,
  };
  axios({
    url: `${ROOT_URL}/post?where=${JSON.stringify(params.where)}&sort=${params.sort}&limit=${params.limit}`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: EVENTS_FETCHED,
        payload: response.data,
      });
      const eventUuids = _.map(response.data, 'uuid');
      dispatch(getFeedback(eventUuids));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getCheckIns = eventUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  axios({
    url: `${ROOT_URL}/post/${eventUuid}/checkins`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: GET_CHECK_INS,
        payload: {
          eventUuid,
          checkIns: response.data,
        },
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getEvent = eventUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch({
    type: FETCHING_EVENTS,
  });
  axios({
    url: `${ROOT_URL}/post/${eventUuid}`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: EVENT_FETCHED,
        payload: response.data,
      });
      dispatch(getCheckIns(eventUuid));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const checkinsToCsv = eventUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch({
    type: EVENT_CSV_DOWNLOADING,
  });

  axios({
    url: `${ROOT_URL}/post/${eventUuid}/checkins/csv`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: EVENT_CSV_DOWNLOADED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getNote = noteUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  dispatch({
    type: FETCHING_NOTES,
  });
  axios({
    url: `${ROOT_URL}/post/${noteUuid}`,
    method: 'GET',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: NOTE_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const editEvent = editedEvent => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const uuid = editedEvent.get('uuid');
  dispatch({
    type: EDIT_EVENT,
  });
  axios({
    url: `${ROOT_URL}/post/${uuid}`,
    method: 'PATCH',
    headers: { token },
    data: editedEvent,
  })
    .then((response) => {
      if (response.data.prosperworksMessage) {
        dispatch({
          type: OPEN_EVENT_SNACKBAR,
          payload: response.data.prosperworksMessage,
        });
      }
      dispatch({
        type: EVENT_FETCHED,
        payload: response.data,
      });
      dispatch(getCheckIns(uuid));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const createEvent = (newEvent, buildingUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  const params = {
    where: {
      building: buildingUuid,
    },
  };
  dispatch({
    type: CREATE_EVENT,
  });
  axios({
    url: `${ROOT_URL}/post?where=${JSON.stringify(params.where)}`,
    method: 'POST',
    headers: { token },
    data: newEvent,
  })
    .then((response) => {
      dispatch({
        type: EVENT_FETCHED,
        payload: response.data,
      });
      if (response.data.prosperworksMessage) {
        dispatch({
          type: OPEN_EVENT_SNACKBAR,
          payload: response.data.prosperworksMessage,
        });
      }
      dispatch(push(`/building/${response.data.building.uuid}`));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const deleteEvent = event => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const eventUuid = event.uuid;
  dispatch({
    type: DELETE_EVENT,
  });
  axios({
    url: `${ROOT_URL}/post/${eventUuid}`,
    method: 'DELETE',
    headers: { token },
  })
    .then((response) => {
      dispatch(push(`/building/${response.data.building.uuid}`));
      if (response.data.prosperworksMessage) {
        dispatch({
          type: OPEN_EVENT_SNACKBAR,
          payload: response.data.prosperworksMessage,
        });
      }
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};
