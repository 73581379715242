import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const ReviewRejectedGroup = ({
  rejectedGroup: {
    createdAt,
    title,
    building,
    description,
    rejectionReason,
    suggestingUser: { firstName, lastName, email },
  }
}) => (
  <div className="group-modal-content">
    <h3>Rejected Group</h3>
    <Grid container spacing={3}>
      <Grid item xs={12} md={9}>
        <div className="detail-content-container">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <p className="detail-label">Title</p>
            </Grid>
            <Grid item xs={9}>
              <p>{title}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">Rejection Date</p>
            </Grid>
            <Grid item xs={9}>
              <p>{moment(createdAt).format('MMM D, YYYY')}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">User</p>
            </Grid>
            <Grid item xs={9}>
              <p>{`${firstName} ${lastName}`}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">Email</p>
            </Grid>
            <Grid item xs={9}>
              <p>{email}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">Building</p>
            </Grid>
            <Grid item xs={9}>
              <p>{building.name}</p>
            </Grid>
            <Grid item sm={3} xs={12}>
              <p className="detail-label">Description</p>
            </Grid>
            <Grid item sm={9} xs={12}>
              <p>{description}</p>
            </Grid>
            <Grid item sm={3} xs={12}>
              <p className="detail-label">Reason</p>
            </Grid>
            <Grid item sm={9} xs={12}>
              <p>{rejectionReason ? rejectionReason : <span>No Reason Provided</span>}</p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default ReviewRejectedGroup;
