import React, { Component } from 'react';
import {
  TextField,
  Checkbox,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from '@material-ui/core';
import { Field } from 'formik';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import Tags from '../Tags/Tags';

class UpdateGroupForm extends Component {
  handleChange = (event, setFieldValue) => {
    const { id, value } = event.target;
    if (id === 'active' || id === 'featured') {
      setFieldValue(id, !JSON.parse(value), true);
    } else {
      setFieldValue(id, value, true);
    }
  }

  updateObjects = (tagObjects) => {
    const { interests } = this.props.values;
    const updatedObjects = [];

    tagObjects.forEach((tagObject) => {
      const tagObjectCopy = { ...tagObject };
      if (interests.includes(tagObjectCopy.uuid)) {
        tagObjectCopy.active = true;
      }
      updatedObjects.push(tagObjectCopy);
    });

    return updatedObjects;
  };

  render() {
    const {
      touched, errors, setFieldTouched, values, setFieldValue, handleSubmit, interests,
    } = this.props;
    return (
      <form className="group-form" onSubmit={handleSubmit}>
        <h3>Update Group Form</h3>
        <TextField
          id="name"
          name="name"
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          label="Title"
          onChange={event => this.handleChange(event, setFieldValue)}
          onBlur={() => setFieldTouched('name', true, false)}
          value={values.name}
          fullWidth
        />
        <Field component={() => (
          <FormControl disabled>
            <InputLabel id="building-label">Building</InputLabel>
            <Select
              name="buildingName"
              labelId="building-label"
              value={values.buildingName}
              onChange={this.handleChange}
            >
              <MenuItem value={values.buildingName}>{values.buildingName}</MenuItem>
            </Select>
          </FormControl>
        )}/>
        <TextField
          id="description"
          name="description"
          multiline
          rows={3}
          helperText={touched.description ? errors.description : ''}
          error={touched.description && Boolean(errors.description)}
          label="Description"
          onChange={event => this.handleChange(event, setFieldValue)}
          onBlur={() => setFieldTouched('description', true, false)}
          value={values.description}
          fullWidth
        />
        <div>
          <Checkbox
            id="active"
            color="primary"
            checked={JSON.parse(values.active)}
            value={JSON.stringify(values.active)}
            onChange={event => this.handleChange(event, setFieldValue)}
            onBlur={() => setFieldTouched('active', true, false)}
          />
          <label htmlFor="active">Active</label>
        </div>
        <div>
          <Checkbox
            id="featured"
            color="primary"
            checked={JSON.parse(values.featured)}
            value={JSON.stringify(values.featured)}
            onChange={event => this.handleChange(event, setFieldValue)}
            onBlur={() => setFieldTouched('featured', true, false)}
          />
          <label htmlFor="featured">Featured</label>
        </div>
        <Field
          name="interests"
          component={formikProps => (
            <Tags
              {...formikProps}
              type="interests"
              tagObjects={interests}
            />)
          }
        />
        <Field
          name="image"
          component={formikProps => <ImageDropzone {...formikProps} />}
        />
        <Button type="submit" variant="contained" color="primary">Update</Button>
      </form>
    );
  }
}

export default UpdateGroupForm;
