import React from 'react';
import styled from 'styled-components';
import { Select, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '../../components/Table/Table';
import { roles } from '../../constants';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;

const ROLES = [roles.VCM, roles.PM, roles.SUPERADMIN];


const AdminsTable = ({ admins, handleChange, deleteAdmin }) => {
  const GroupProposalsArray = React.useMemo(() => [{
    Header: 'Admin',
    id: 'admin',
    columns: [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ user }) => `${user.firstName} ${user.lastName}`,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: 'user.email',
      },
      {
        Header: 'Building',
        id: 'building',
        accessor: 'building.name',
      },
      {
        Header: 'Role',
        id: 'role',
        accessor: 'role',
      },
      {
        Header: 'Change role',
        id: 'change-role',
        accessor: ({role, user }) => (
          <p>
            New role:
            <Select
              name="buildingName"
              labelId="building-label"
              onChange={(event) => handleChange(user.uuid, event.target.value)}
            >
              {ROLES.map((r) => {
                if (r !== role) return <MenuItem value={r}>{r}</MenuItem>;
                return null;
              })}
            </Select>
          </p>
        ),
      },
      {
        Header: 'Deactivate admin',
        id: 'deactivate',
        accessor: ({ uuid }) => (
          <Button variant="outlined" className="building-list-button" onClick={() => deleteAdmin(uuid)}>
            Deactivate
          </Button>
        ),
      },
    ],
  },
  ], []);

  return (
    <Styles>
      <Table
        tableName="proposed"
        data={admins}
        totalDataCount={admins.length}
        columns={GroupProposalsArray}
      />
    </Styles>
  );
};

export default AdminsTable;
