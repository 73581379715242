import React from 'react';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import './CustomModal.css';

const CustomModal = ({ content, closeModal }) => (
  <div id="custom-modal">
    <div className="custom-overlay">
      <div className="content-container">
        <Fab
          aria-label="add"
          className="close-button"
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: -10,
            right: -20,
          }}
        >
          <CloseIcon />
        </Fab>
        <div className="modal-content">
          {content}
        </div>
      </div>
    </div>
  </div>
);
export default CustomModal;
