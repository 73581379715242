import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import authReducer from "./AuthReducer";
import buildingReducer from "./BuildingReducer";
import eventReducer from "./EventReducer";
import noteReducer from "./NoteReducer";
import BuildingResidentReducer from "./BuildingResidentReducer";
import MerchantReducer from "./MerchantReducer";
import BuildingPerkReducer from "./BuildingPerkReducer";
import perkReducer from "./perkReducer";
import TagReducer from "./TagReducer";
import EventFeedbackReducer from "./EventFeedbackReducer";
import GroupReducer from "./GroupReducer";
import GroupCommentReducer from "./GroupCommentReducer";
import GroupEventReducer from "./GroupEventReducer";
import GroupEventCommentReducer from "./GroupEventCommentReducer";
import BuildingConfigReducer from "./BuildingConfigReducer";
import BuildingDashboardConfigReducer from "./BuildingDashboardConfigReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    buildings: buildingReducer,
    events: eventReducer,
    notes: noteReducer,
    buildingResidents: BuildingResidentReducer,
    merchants: MerchantReducer,
    buildingPerks: BuildingPerkReducer,
    perks: perkReducer,
    tags: TagReducer,
    eventfeedback: EventFeedbackReducer,
    groups: GroupReducer,
    groupComments: GroupCommentReducer,
    groupEvents: GroupEventReducer,
    groupEventComments: GroupEventCommentReducer,
    buildingConfig: BuildingConfigReducer,
    buildingDashboardConfig: BuildingDashboardConfigReducer,
  });
