import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getGroupEventComments, updateComment, deleteComment } from '../../actions/GroupEventCommentActions';
import CommentList from '../../components/CommentList/CommentList';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 215,
    minWidth: '40vw',
  },
  infoContainer: {
    marginTop: 25,
    marginBottom: 25,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 600,
  },
};
class GroupEventCommentsContainer extends Component {
  componentDidMount() {
    const { groupEvent } = this.props;
    this.props.getGroupEventComments(groupEvent.uuid);
  }

  render() {
    const {
      comments,
      loading,
      buildingName,
      closeModal,
      updateComment: updateCommentAction,
      deleteComment: deleteCommentAction,
    } = this.props;
    if (loading) {
      return (
        <div style={styles.container}>
          <CircularProgress color="primary" />
        </div>
      );
    }

    const commentsArr = Object.values(comments);
    if (!commentsArr.length) return null;
    const { groupEvent } = commentsArr[0];
    return (
      <div>
        <div style={styles.infoContainer}>
          <h3>{`Comments for ${groupEvent.title}`}</h3>
          <p>
            <span style={styles.boldText}>
              Date:
            </span>
            {moment(groupEvent.startTime).format(' dddd, MMM D [at] h:mm a')}
          </p>
          <p>{groupEvent.description}</p>
        </div>
        <CommentList
          comments={commentsArr}
          updateComment={updateCommentAction}
          deleteComment={deleteCommentAction}
          closeModal={closeModal}
          buildingName={buildingName}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ groupEventComments: { comments, loading } }, props) => ({
  groupEvent: props.groupEvent,
  comments,
  loading,
});

export default connect(
  mapStateToProps,
  { getGroupEventComments, updateComment, deleteComment },
)(GroupEventCommentsContainer);
