import { FETCHED_FEEDBACK, CREATED_EVENTFEEDBACK_CSV } from '../actions/EventFeedbackActions';
import { FETCHING_EVENTS } from '../actions/PostActions';

const initialState = {
  loading: true,
  feedback: [],
  csv: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EVENTS:
      return initialState;
    case FETCHED_FEEDBACK:
      return { ...state, feedback: action.payload, loading: false };
    case CREATED_EVENTFEEDBACK_CSV:
      return { ...state, csv: action.payload };
    default:
      return state;
  }
};