import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getScheduledGroupEventComments,
  deleteScheduledGroupComment,
} from '../../actions/GroupCommentActions';
import ScheduleCommentModal from '../../components/ScheduleCommentModal/ScheduleCommentModal.js';

import CustomModal from '../../components/CustomModal/CustomModal';
import ScheduledComments from '../../components/ScheduledComments/ScheduledComments';
import Snackbar from '../../components/Snackbar/Snackbar';
import '../GroupsContainer/GroupsContainer.css';

class ScheduledCommentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModal: false,
      targetComment: null,
    };
  }

  fetchScheduledGroupEventComments() {
    this.props.getScheduledGroupEventComments(this.props.selectedId);
  }

  componentDidMount() {
    this.fetchScheduledGroupEventComments();
  }

  handleCloseModal = () => {
    this.setState({
      viewModal: false,
      targetComment: null,
    });
  };

  renderModal = () => {
    const content = (
      <ScheduleCommentModal
        closeForm={() => this.handleCloseModal()}
        targetComment={this.state.targetComment}
      />
    );
    return (
      <CustomModal
        content={content}
        closeModal={() => this.handleCloseModal()}
      />
    );
  };

  handleEdit = (uuid) => {
    const { scheduledComments } = this.props;
    this.setState({
      viewModal: true,
      targetComment: scheduledComments.find((sc) => sc.uuid === uuid),
    });
  };

  handleDelete = (uuid) => {
    if (
      window.confirm('Are you sure you want to delete this scheduled post?')
    ) {
      this.props.deleteScheduledGroupComment(uuid);
    }
  };

  render() {
    const { snackbarOpen, errorMessage, scheduledComments } = this.props;
    const { viewModal } = this.state;
    const snackbar = (
      <Snackbar snackbarOpen={snackbarOpen} errorMessage={errorMessage} />
    );

    return (
      <div className="marginTop1">
        {snackbarOpen ? snackbar : null}
        {viewModal ? this.renderModal() : null}
        <ScheduledComments
          scheduledComments={scheduledComments}
          handleDelete={this.handleDelete}
          handleEdit={this.handleEdit}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  groupComments: { scheduledComments, loading },
}) => ({
  scheduledComments,
  loading,
});

export default connect(mapStateToProps, {
  getScheduledGroupEventComments,
  deleteScheduledGroupComment,
})(ScheduledCommentsContainer);
