import mapKeys from 'lodash/mapKeys';
import {
  FETCHING_RESIDENTS,
  RESIDENTS_FETCHED,
  RESIDENT_FETCHED,
  CSV_UPLOAD_DATA,
  OPEN_RESIDENT_SNACKBAR,
  DEACTIVATE_RESIDENT,
  BLOCK_RESIDENT,
} from '../actions/BuildingResidentActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';


const initialState = {
  buildingResidents: [],
  uploadData: {},
  snackbarOpen: false,
  errorMessage: '',
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_RESIDENTS:
      return { ...state, loading: true };
    case RESIDENTS_FETCHED:
      return { ...state, buildingResidents: mapKeys(action.payload, 'uuid'), loading: false };
    case RESIDENT_FETCHED:
      return {
        ...state,
        buildingResidents: {
          ...state.buildingResidents,
          [action.payload.uuid]: action.payload,
        },
        loading: false,
      };
    case DEACTIVATE_RESIDENT:
      return {
        ...state,
        buildingResidents: {
          ...state.buildingResidents,
          [action.payload.uuid]: action.payload,
        },
      };
    case BLOCK_RESIDENT:
      return {
        ...state,
        buildingResidents: {
          ...state.buildingResidents,
          [action.payload.uuid]: action.payload,
        },
      };
    case CSV_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.payload,
        loading: false,
      };
    case OPEN_RESIDENT_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        errorMessage: action.payload,
        loading: false,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};
