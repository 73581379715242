import React, { Component } from 'react';
import {
    TextField,
    Button,
  } from '@material-ui/core';
import { Field } from 'formik';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

class UpdateBuildingForm extends Component {
  handleChange = (event, setFieldValue) => {
    const { id, value } = event.target;
    setFieldValue(id, value, true);
  }
    render() {
      const {
        touched, errors, setFieldTouched, values, setFieldValue, handleSubmit,
      } = this.props;
      return (
        <form className="group-form" onSubmit={handleSubmit}>
          <h3>Update Building Form</h3>
          <TextField
            id="name"
            name="name"
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            label="Title"
            onChange={event => this.handleChange(event, setFieldValue)}
            onBlur={() => setFieldTouched('name', true, false)}
            value={values.name}
            fullWidth
          />
           <TextField
            id="shortName"
            name="shortName"
            helperText={touched.shortName ? errors.shortName : ''}
            error={touched.shortName && Boolean(errors.shortName)}
            label="Title"
            onChange={event => this.handleChange(event, setFieldValue)}
            onBlur={() => setFieldTouched('shortName', true, false)}
            value={values.shortName}
            fullWidth
          />
          <Field
            name="image"
            component={formikProps => <ImageDropzone {...formikProps} />}
          />
          <Button type="submit" variant="contained" color="primary">Update</Button>
        </form>
      );
    }
  }
  
  export default UpdateBuildingForm;
  