import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import throttle from 'lodash/throttle';
import Cookies from 'universal-cookie';

import rootReducer from './reducers';

export const history = createBrowserHistory();

const cookies = new Cookies();
const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const token = cookies.get('token');

const store = createStore(
  rootReducer(history),
  composedEnhancers,
);

// store.subscribe(throttle(() => {
//   cookies.set('token', store.getState().auth.token)
// }, 1000));

export default store;
