import React from 'react';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import './ImageDropzone.css';
import AcceptImage from '../AcceptImage/AcceptImage';

const handleClearImageClick = ({
  setFieldValue,
  setFieldTouched,
}) => {
  setFieldValue('image', {}, true);
  setFieldTouched('image', true, false);
};


const handleImageDrop = (
  files,
  { setFieldValue, setFieldTouched },
) => {
  setFieldValue('image', files[0], true);
  setFieldTouched('image', true, false);
};

const renderDropzone = (image, formikProps) => {
  const { errors, touched } = formikProps;
  return (
    <div id="image-dropzone">
      <h3>Drop image or click</h3>
      {errors.image && touched.image
        && (
          <FormHelperText className="caption error">
            {errors.image}
          </FormHelperText>
        )
      }
      <AcceptImage handleImageDrop={handleImageDrop} formikProps={formikProps} />
    </div>
  );
};

const renderImageInfo = (image, formikProps) => {
  return (
    <div className="image-info-container">
      <h3>Image</h3>
      <Button
        variant="contained"
        size="small"
        color={image.preview === '' ? null : 'secondary'}
        onClick={() => handleClearImageClick(formikProps)}
      >
        {image.preview === '' ? 'Upload' : 'Clear'}
      </Button>
      <div className="image-container">
        <img src={image.preview || URL.createObjectURL(image)} alt="" />
      </div>
    </div>
  );
};

const ImageDropzone = ({
  form,
}) => {
  const { values } = form;
  const { image } = values;
  if (image && Object.keys(image).length && !(image.hasOwnProperty('preview') && image.preview === '')) { // For an object or a string
    return renderImageInfo(image, form);
  }

  return (
    <div>
      {renderDropzone(image, form)}
    </div>
  );
};
export default ImageDropzone;
