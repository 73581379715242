import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardContent, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Archive from '@material-ui/icons/Archive';
import { CSVLink } from 'react-csv';
import _ from 'lodash';


const EventFeedbackGraph = (props) => {
  const renderCSVButton = () => {
    if (props.csv) {
      return (
        <CSVLink
          data={props.csv}
          filename="building_feedback.csv"
          target="_blank"
        >
          <IconButton>
            <Archive />
          </IconButton>
        </CSVLink>
      );
    }
    return null;
  };

  let data = _.countBy(props.feedback, 'generalRating');
  data = [
    {
      generalRating: 'Did not attend',
      count: data[0] ? data[0] : 0,
    },
    {
      generalRating: '🙁',
      count: data[1] ? data[1] : 0,
    },
    {
      generalRating: '🙂',
      count: data[2] ? data[2] : 0,
    },
    {
      generalRating: '😍',
      count: data[3] ? data[3] : 0,
    },
  ];

  return (
    <Card className={props.eventFeedbackAnimation}>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <h3 className="animated fadeInDown delay-0.5s">
              { props.graphTitle }
            </h3>
          </Grid>
          <Grid item xs={2}>
            {renderCSVButton()}
          </Grid>
          <Grid item xs={12}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={data}
                margin={{
                top: 0, right: 20, left: 0, bottom: 5,
              }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="generalRating" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};


export default EventFeedbackGraph;
