import React from 'react';
import size from 'lodash/size';
import { LinearProgress, TableContainer, Paper, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/Check';
import TimesCircleIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import Table from '../Table/Table';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;
const GroupsProposals = ({
  loading,
  groupProposals,
  handleCreate,
  handleReject
}) => {
  const GroupProposalsArray = React.useMemo(() => [{
    Header: 'Suggesting User',
    id: 'suggesting',
    columns: [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ suggestingUser: user }) => `${user.firstName} ${user.lastName}`,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: 'suggestingUser.email',
      },
    ],
  },
  {
    Header: 'Proposal Details',
    id: 'proposed',
    columns: [
      {
        Header: 'Title',
        id: 'title',
        accessor: 'title',
      },
      {
        Header: 'Building',
        id: 'buildingname',
        accessor: 'building.name',
      },
    ],
  },
  {
    Header: 'Additional Info',
    id: 'additional',
    columns: [
      {
        Header: 'Description',
        id: 'description',
        width: 250,
        accessor: 'description',
      },
      {
        Header: 'Actions',
        id: 'proposed_actions',
        width: 90,
        Cell: ({ row }) => (
          <div>
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => handleCreate(row)}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              aria-label="Delete"
              color="secondary"
              size="small"
              onClick={() => handleReject(row)}
            >
              <TimesCircleIcon />
            </IconButton>
          </div>
        ),
      },
    ],
  },
  ], [handleCreate, handleReject]);

  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }

  if (!size(groupProposals)) {
    return null;
  }

  const arr = Object.keys(groupProposals).map((groupProposal) => groupProposals[groupProposal]);
  const groups = arr.filter((group) => group.suggestingUser
    && group.building && !group.approved && !group.rejected);


  return (
    <>
      <TableContainer className="tableContainer" component={Paper}>
        <h2>Group Proposals</h2>
        <Styles>
          <Table
            tableName="proposed"
            data={groups}
            totalDataCount={groups.length}
            columns={GroupProposalsArray}
          />
        </Styles>
      </TableContainer>
    </>
  );
};

export default GroupsProposals;
