import React from 'react';
import moment from 'moment-timezone';
import {
  Button,
  IconButton,
  Paper,
  TableContainer,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import styled from 'styled-components';
import Table from '../Table/Table';

import GroupEventDetails from '../GroupEventDetails/GroupEventDetails';
import './GroupEvents.css';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }
    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;
const GroupEvents = ({
  groupEvents,
  handleUpdateEvent,
  handleCreateEvent,
  handleScheduleComment,
  handleDuplicateEvent,
  handleDeleteEvent,
  handleViewComment,
  buildingName
}) => {
  const groupEventsArr = Object.values(groupEvents);

  const tableArray = React.useMemo(() => [{
    Header: 'Gatherings',
    columns: [
      {
        Header: 'Gathering',
        id: 'title',
        accessor: 'title',
        width: 250,
      },
      {
        Header: 'Date',
        id: 'startDate',
        filterable: false,
        accessor: (event) => (
          <div>
            <div>{moment.parseZone(event.meta.startTime).format('MMM D, YYYY')}</div>
            <div>{moment.parseZone(event.meta.startTime).format('ddd [@] h:mm a')}</div>
          </div>
        ),
      },
      {
        Header: 'Group',
        id: 'groupname',
        accessor: 'group.name',
      },
      {
        Header: 'Building',
        accessor: 'building.name',
        id: 'buildingname',
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: 140,
        Cell: (data) => {
          const { uuid, startTime } = data.row.original;
          return (
            <div>
              {!moment().isAfter(startTime) && (
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  size="small"
                  onClick={() => handleUpdateEvent(uuid)}
                >
                  <EditIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="Duplicate"
                color="teal"
                size="small"
                onClick={() => handleDuplicateEvent(uuid)}
              >
                <FileCopyIcon />
              </IconButton>
              <IconButton
                aria-label="Delete"
                color="secondary"
                size="small"
                onClick={() => handleDeleteEvent(uuid)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
      {
        Header: 'Comments',
        id: 'comments',
        width: 125,
        Cell: (data) => {
          const { meta: { countComments } } = data.row.original;
          if (countComments) {
            return (
              <Button
                color="primary"
                size="small"
                onClick={() => handleViewComment(data.row.original.uuid)}
              >
                {`${countComments} comment${countComments > 1 ? 's' : ''}`}
              </Button>
            );
          }
          return null;
        },
      },

      {
        Header: () => <strong>More</strong>,
        id: 'expander',
        width: 50,
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            <IconButton
              aria-label="add"
            >
              {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </span>
        ),
      },
    ],
  }], [handleDeleteEvent, handleUpdateEvent, handleViewComment]);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => <GroupEventDetails groupEvent={groupEventsArr[row.index]} />,
    [groupEventsArr],
  );


  return (
    <div>
      <div className="gathering-header">
        <Button onClick={handleCreateEvent} color="primary">
          Create new gathering
        </Button>
        <Button onClick={handleScheduleComment} color="secondary">
          Schedule or Post a Comment
        </Button>
        <h2>Gatherings in {buildingName}</h2>
      </div>
      <TableContainer className="tableContainer" component={Paper}>
        <Styles>
          <Table
            tableName="groupevent"
            data={groupEventsArr}
            columns={tableArray}
            renderRowSubComponent={renderRowSubComponent}
            initPageSize={25}
            totalDataCount={groupEventsArr.length}
          />
        </Styles>
      </TableContainer>
    </div>
  );
};

export default GroupEvents;
