import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import CreateBuildingForm from '../../components/CreateBuildingForm/CreateBuildingForm';
import { createBuilding, getBuildings } from '../../actions/BuildingActions';
import Snackbar from '../../components/Snackbar/Snackbar';

import './CreateBuildingContainer.css';
import { roles } from '../../constants';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'string'];

const createBuildingSchema = Yup.object().shape({
  name: Yup.string()
    .required('The name of the Building is required'),
  shortName: Yup.string()
    .required('Please include a short name'),
  timeZone: Yup.string()
    .required('Please input the Time Zone'),
  VCM: Yup.string()
    .required('Please input the Time Zone'),
  skippable: Yup.boolean()
    .required('This field is required'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});

const createBuildingSchemaWithOnboarding = Yup.object().shape({
  name: Yup.string()
    .required('The name of the Building is required'),
  shortName: Yup.string()
    .required('Please include a short name'),
  timeZone: Yup.string()
    .required('Please input the Time Zone'),
  VCM: Yup.string()
    .required('Please input the Time Zone'),
  skippable: Yup.boolean()
    .required('This field is required'),
  raffleDescription: Yup.string().required('Raffle description is required'),
  raffleEndDate: Yup.date().min(moment()).required('End date is required and must be a future date'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});


class CreateBuildingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboard: false,
    };
  }

  componentDidMount() {
    const { getBuildings } = this.props;
    getBuildings();
  }

  buildingCreateSubmitHandler = (values) => {
    const buildingCreating = new FormData();
    buildingCreating.append('name', values.name);
    buildingCreating.append('shortName', values.shortName);
    buildingCreating.append('timezone', values.timeZone);
    buildingCreating.append('VCM', values.VCM);
    buildingCreating.append('onboardingSkippable', values.skippable);
    if (this.state.onboard) {
      buildingCreating.append('raffleDescription', values.raffleDescription);
      buildingCreating.append('raffleEndDate', values.raffleEndDate);
    }
    buildingCreating.append('image', values.image);
    this.props.createBuilding(buildingCreating);
  }

  toggleOnboard = () => {
    this.setState({ onboard: !this.state.onboard });
  }

  render() {
    const { buildings } = this.props;
    const buildingsArr = Object.values(buildings);
    if (buildingsArr?.find((b) => b.role === roles.PM)) return <Redirect to="/403" />;

    return (
      <div className="CreateBuildingContainer">
        <Snackbar
          snackbarOpen={this.props.snackbarOpen}
          errorMessage={this.props.errorMessage}
        />
        <div id="CreateBuildingContainerId" className="animated fadeInDown delay-1s">
          <Card id="FormTitleCard" className="animated fadeInDown delay-2s">
            <h4 className="H4TextTitle">Create Building Form</h4>
          </Card>
          <Formik
            initialValues={{
              name: '',
              shortName: '',
              timeZone: 'America/New_York',
              VCM: 1,
              image: {},
              raffleDescription: '',
              raffleEndDate: '',
              skippable: true,
            }}
            validationSchema={this.state.onboard ? createBuildingSchemaWithOnboarding : createBuildingSchema}
            onSubmit={this.buildingCreateSubmitHandler}
          >
            {(formikProps) => (
              <CreateBuildingForm
                onboard={this.state.onboard}
                toggleOnboard={this.toggleOnboard}
                {...formikProps}
              />
            )}
          </Formik>
          <Link to="/building" id="LinkText">
            <Button variant="contained" color="primary" id="materialUIBackButton" className="animated fadeInRight delay-3s">
              Back
            </Button>
          </Link>
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
  snackbarOpen: buildings.snackbarOpen,
  errorMessage: buildings.errorMessage,
});

export default connect(mapStateToProps, { createBuilding, getBuildings })(CreateBuildingContainer);
