/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import HomeIcon from '@material-ui/icons/Home';
import { Redirect, Link } from 'react-router-dom';
import size from 'lodash/size';
import map from 'lodash/map';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import './BuildingList.css';
import {
  Edit as EditIcon,
} from '@material-ui/icons';


const BuildingList = ({
  buildings,
  onBuildingSelectHandler,
  loading,
  onBuildingChangeActive,
  onAddUserPress,
  forbidCreating,
  onCreateVerifiedUserPress,
  isSuperAdmin,
  handleChange,
  onUpdateBuildingPress,
}) => {
  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }

  if (size(buildings) === 0) {
    return <Redirect to="/403" />;
  }

  const renderBuildingList = map(buildings, (building) => (
    <div key={building.uuid}>
      <Grid
        container
        className="codeControl"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={8} sm={8} md={8}>
          <ListItem
            button
            key={building.uuid}
            onClick={() => {
              onBuildingSelectHandler(building.uuid);
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={building.name} />
          </ListItem>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <div>
            <select
              name="VCM"
              onChange={(e) => {
                handleChange(e, building.uuid);
              }}
            >
              <option value={null} />
              {Array.from({ length: 10 }, (_, k) => k + 1).map((num) => (
                <option value={num} selected={num === building.VCM}>
                  {num}
                </option>
              )) }
            </select>
          </div>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>

          <FormControlLabel
            className="building-active-switch"
            control={(
              <Switch
                onChange={() => onBuildingChangeActive(building)}
                checked={building.active}
              />
            )}
          />
          <IconButton
            aria-label="Edit"
            color="primary"
            size="small"
            onClick={() => onUpdateBuildingPress(building)}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <div className="building-list-container">
      <div className="building-list-button-container">
        {!forbidCreating && (
          <Link to="/building/new">
            <Button variant="outlined" className="building-list-button">
              Create Building
            </Button>
          </Link>
        )}
        {!forbidCreating && (
          <Button
            variant="outlined"
            className="building-list-button"
            onClick={onCreateVerifiedUserPress}
          >
            Create Verified User
          </Button>
        )}
        <Button
          variant="outlined"
          className="building-list-button"
          onClick={onAddUserPress}
        >
          Add Users
        </Button>
        {isSuperAdmin && (
        <Link to="/admins">
          <Button
            variant="outlined"
            className="building-list-button"
          >
            Edit Admins
          </Button>
        </Link>
        )}
      </div>
      <List>{renderBuildingList}</List>
    </div>
  );
};

export default BuildingList;
