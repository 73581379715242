import React from 'react';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Tags from '../Tags/Tags';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

const EditEventForm = ({
  values,
  touched,
  errors,
  interests,
  structures,
  handleSubmit,
  onEditEventButtonClick,
  handleBlur,
  handleChange,
}) => (
  <form autoComplete="off" onSubmit={handleSubmit}>
    <TextField
      name="title"
      label="Title"
      value={values.title}
      helperText={touched.title ? errors.title : ''}
      error={touched.title && Boolean(errors.title)}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
    />
    <TextField
      name="locationString"
      label="location"
      value={values.locationString}
      helperText={touched.locationString ? errors.locationString : ''}
      error={touched.locationString && Boolean(errors.locationString)}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
    />
    <TextField
      name="description"
      label="description"
      value={values.description}
      helperText={touched.description ? errors.description : ''}
      error={touched.description && Boolean(errors.description)}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      multiline
      rows={4}
    />
    <div className="update-event-max-guests-field">
      <Field
        name="maxGuests"
        component={TextField}
        type="number"
        label="Max Guests"
        value={values.maxGuests}
        onChange={handleChange}
        InputProps={{ inputProps: { min: 0, max: 30, name: 'maxGuests' } }}
      />
      { errors.maxGuests ? <p className="interestErrorTag"> { errors.maxGuests } </p> : '' }
    </div>
    <div className="update-event-max-attendees-field">
      <Field
        name="maxEventAttendees"
        component={TextField}
        type="number"
        label="Max Capacity"
        value={values.maxEventAttendees}
        onChange={handleChange}
        InputProps={{ inputProps: { min: 0, max: 500, name: 'maxEventAttendees' } }}
      />
      { errors.maxEventAttendees ? <p className="interestErrorTag"> { errors.maxEventAttendees } </p> : '' }
    </div>
    <Field
      name="startTime"
      component={TextField}
      type="datetime-local"
      label="date"
      value={values.startTime}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
          }}
      InputProps={{
        inputProps:
        { name: 'startTime' },
      }}
    />
    <Field
      name="structures"
      component={formikProps => (
        <Tags
          {...formikProps}
          type="structures"
          tagObjects={structures}
        />)
        }
    />
    <Field
      name="interests"
      component={formikProps => (
        <Tags
          {...formikProps}
          type="interests"
          tagObjects={interests}
        />)
        }
    />
    <Grid item xs={8} sm={4} zeroMinWidth>
      <Field
        name="image"
        component={formikProps => <ImageDropzone {...formikProps} />}
      />
    </Grid>
    <div className="edit-event-form-buttons">
      <Button variant="contained" color="primary" type="submit"> Submit </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={onEditEventButtonClick}
      >
          Cancel
      </Button>
    </div>
  </form>
);

export default EditEventForm;
