import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserDetail from '../../components/UserDetail/UserDetail';

import {
  getBuildingResident,
  deactivateResident,
  blockResident,
} from '../../actions/BuildingResidentActions';
import { getBuildings } from '../../actions/BuildingActions';
import { roles } from '../../constants';


class UserDetailContainer extends Component {
  async componentDidMount() {
    const {
      match,
      getBuildingResident: getBuildingResidentAction,
      getBuildings,
    } = this.props;
    getBuildingResidentAction(match.params.userUuid);
    await getBuildings();
  }

  deleteUser = (userId) => {
    const { deactivateResident: deactivateResidentAction } = this.props;
    if (window.confirm('Are you sure you want to deactivate this user?')) {
      deactivateResidentAction(userId);
    }
  };

  blockUser = (userId) => {
    const { blockResident: blockResidentAction } = this.props;
    if (window.confirm('Are you sure you want to block this malicious user?')) {
      blockResidentAction(userId);
    }
  }

  render() {
    const { buildingResident, buildingResidentsLoading, buildings } = this.props;

    const buildingsArr = Object.values(buildings);
    const forbidDeactivation = buildingsArr?.find((b) => b.role === roles.PM);

    return (
      <UserDetail
        buildingResident={buildingResident}
        loading={buildingResidentsLoading || !buildingsArr.length}
        deleteUser={this.deleteUser}
        blockUser={this.blockUser}
        forbidDeactivation={forbidDeactivation}
      />
    );
  }
}

const mapStateToProps = ({ buildingResidents, buildings }, props) => ({
  buildingResidentsLoading: buildingResidents.loading,
  buildingResident:
    buildingResidents.buildingResidents[props.match.params.userUuid],
  buildings: buildings.buildings,
});

export default connect(mapStateToProps, {
  getBuildingResident,
  deactivateResident,
  blockResident,
  getBuildings,
})(UserDetailContainer);
