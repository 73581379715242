import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { Card, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getEvents } from '../../actions/PostActions';
import { getBuilding, updateBuildingCode } from '../../actions/BuildingActions';
import {
  getBuildingConfig,
  updateBuildingConfig,
} from '../../actions/BuildingConfigActions';
import {
  updateBuildingDashboardConfig,
  getBuildingDashboardConfig,
} from '../../actions/BuildingDashboardConfigActions';

import Snackbar from '../../components/Snackbar/Snackbar';
import BuildingResidentContainer from '../BuildingResidentContainer/BuildingResidentContainer';
import './BuildingDetailContainer.css';
import CustomModal from '../../components/CustomModal/CustomModal';
import ReviewUrlFormContainer from '../ReviewUrlFormContainer/ReviewUrlFormContainer';
import ScheduledCommentsContainer from '../ScheduledCommentsContainer/ScheduledCommentsContainer';
import { history } from '../../store';

class BuildingDetailContainer extends Component {
  state = {
    showLocal: false,
    showGoogleReview: false,
    showReviewUrlModal: false,
    showDashboardLocal: false,
  };

  componentDidMount() {
    this.props.getBuilding(this.props.match.params.buildingUuid);
    this.props.getBuildingConfig(this.props.match.params.buildingUuid);
    this.props.getBuildingDashboardConfig(this.props.match.params.buildingUuid);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.loading !== prevProps.loading
      && this.props.loading === false
    ) {
      // In UI it is 'show' instead of 'hide', so we need to take an opposite value of hidePerks
      this.setState({
        showLocal: !this.props.hidePerks,
        showGoogleReview: this.props.showGoogleReview,
      });
    }
    if (
      this.props.buildDashConfigLoading !== prevProps.buildDashConfigLoading
      && this.props.buildDashConfigLoading === false
    ) {
      this.setState({
        showDashboardLocal: this.props.showDashboardLocal,
      });
    }
  }

  onPostSelectHandler = (eventUuid) => {
    this.props.history.push(`${this.props.match.url}/event/${eventUuid}`);
  };

  onChangeHidePerksHandler = () => {
    this.setState({ showLocal: !this.state.showLocal });
    this.props.updateBuildingConfig({
      buildingConfig: this.props.buildingConfig,
      hidePerks: !this.props.hidePerks,
      showGoogleReview: this.props.showGoogleReview,
      id: this.props.match.params.buildingUuid,
    });
  };

  onChangeShowGoogleReviewHandler = () => {
    this.setState({ showGoogleReview: !this.state.showGoogleReview });
    this.props.updateBuildingConfig({
      buildingConfig: this.props.buildingConfig,
      hidePerks: this.props.hidePerks,
      showGoogleReview: !this.props.showGoogleReview,
      id: this.props.match.params.buildingUuid,
    });
  };

  onChangeShowDashboardLocalHandler = async () => {
    await this.setState({ showDashboardLocal: !this.state.showDashboardLocal });
    this.props.updateBuildingDashboardConfig({
      buildingDashboardConfig: this.props.buildingDashboardConfig,
      showDashboardLocal: this.state.showDashboardLocal,
      buildingId: this.props.match.params.buildingUuid,
    });
  };

  handleShowReviewUrlModal = (value) => {
    this.setState({
      showReviewUrlModal: value,
    });
  };

  render() {
    const {
      showLocal,
      showGoogleReview,
      showReviewUrlModal,
      showDashboardLocal,
    } = this.state;
    if (!(this.props.buildings || this.props.building)) {
      return null;
    }
    return (
      <div className="container">
        <Snackbar
          snackbarOpen={this.props.snackbarOpen}
          errorMessage={this.props.errorMessage}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} zeroMinWidth />
          <Grid item xs={12} sm={4}>
            <Card className="button-card">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => { history.push(`/groups/${this.props.building.uuid}`); }}
              >
                SEE GROUPS AND CHAT
              </Button>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => { history.push(`/groupevents/${this.props.building.uuid}`); }}
              >
                SEE GATHERINGS
              </Button>
            </Card>
            <FormControlLabel
              className="building-config-switch "
              control={(
                <Switch
                  checked={showLocal}
                  onChange={this.onChangeHidePerksHandler}
                  color="primary"
                />
              )}
              label="Show Local in Mobile App"
            />
            <FormControlLabel
              className="building-config-switch "
              control={(
                <Switch
                  checked={showDashboardLocal}
                  onChange={this.onChangeShowDashboardLocalHandler}
                  color="primary"
                />
              )}
              label="Show View Offers in PM Dashboards"
            />
            <FormControlLabel
              className="building-config-switch "
              control={(
                <Switch
                  checked={showGoogleReview}
                  onChange={this.onChangeShowGoogleReviewHandler}
                  color="primary"
                />
              )}
              label="Show Google Review Prompt in Gathering Feedback"
            />
            {this.props.building.codes.map((code) => {
              if (code.valid) {
                return (
                  <Card raised className="building-code-card">
                    <p>
                      Code:
                      {' '}
                      <code>{code.code}</code>
                    </p>
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?data=${code.link}&amp;size=100x100`}
                      alt=""
                      title=""
                      width="150px"
                    />
                    <p>
                      Magic Link:
                      {' '}
                      <code>{code.link}</code>
                    </p>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        if (
                          // eslint-disable-next-line no-alert
                          window.confirm(
                            'This will make the old building code unusable. Are you sure?',
                          )
                        ) {
                          this.props.updateBuildingCode(code.uuid);
                        }
                      }}
                    >
                      Generate new code
                    </Button>
                  </Card>
                );
              }
              return null;
            })}
            <BuildingResidentContainer
              history={this.props.history}
              buildingUuid={this.props.match.params.buildingUuid}
              building={this.props.building}
            />
            <Card className="button-card">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  this.setState({
                    showReviewUrlModal: true,
                  });
                }}
              >
                REVIEW URL
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} zeroMinWidth />
        </Grid>
        <ScheduledCommentsContainer
          selectedId={this.props.match.params.buildingUuid}
        />

        {showReviewUrlModal ? (
          <CustomModal
            content={(
              <ReviewUrlFormContainer
                closeModal={() => this.handleShowReviewUrlModal(false)}
                building={this.props.building}
              />
            )}
            closeModal={() => this.handleShowReviewUrlModal(false)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (
  {
    events, buildings, buildingConfig, buildingDashboardConfig,
  },
  props,
) => ({
  events: events.events,
  errorMessage: events.errorMessage,
  snackbarOpen: events.snackbarOpen,
  building: buildings.buildings[props.match.params.buildingUuid],
  buildingConfig: buildingConfig.buildingConfig,
  buildingDashboardConfig: buildingDashboardConfig.buildingDashboardConfig,
  hidePerks: buildingConfig.hidePerks,
  showGoogleReview: buildingConfig.showGoogleReview,
  loading: buildingConfig.loading,
  showDashboardLocal: buildingDashboardConfig.showDashboardLocal,
  buildDashConfigLoading: buildingDashboardConfig.loading,
});
export default connect(mapStateToProps, {
  getEvents,
  getBuilding,
  updateBuildingCode,
  getBuildingConfig,
  updateBuildingConfig,
  getBuildingDashboardConfig,
  updateBuildingDashboardConfig,
})(BuildingDetailContainer);
