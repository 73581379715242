import React from 'react';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import map from 'lodash/map';
import './MerchantList.css';

const MerchantList = ({
  loading,
  merchants,
  onMerchantSelectHandler,
  handleCreateMerchant,
}) => {
  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }
  return (
    <div id="merchant-list-container">
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleCreateMerchant}
      >
        <AddIcon />
      </Fab>
      <GridList
        cellHeight={200}
        className="merchant-grid-list"
        cols={3}
        spacing={5}
      >
        {map(merchants, merchant => (
          <GridListTile
            className="merchant-grid-list-tile"
            onClick={() => { onMerchantSelectHandler(merchant.uuid); }}
            key={merchant.uuid}
          >
            <img src={merchant.image} alt="" />
            <GridListTileBar
              title={merchant.name}
              subtitle={`Created on ${moment(merchant.createdAt).format('h:mm a, MMMM Do YYYY')}`}
            />
          </GridListTile>
          ))}

      </GridList>
    </div>
  );
};


export default MerchantList;
