import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDiscussionComments, updateComment, deleteComment } from '../../actions/GroupCommentActions';
import CommentList from '../../components/CommentList/CommentList';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 215,
    minWidth: '40vw',
  },
  infoContainer: {
    marginTop: 25,
    marginBottom: 25,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 600,
  },
};
class BuildingChatCommentsContainer extends Component {
  componentDidMount() {
    const { building, getDiscussionComments } = this.props;
    getDiscussionComments(building);
  }

  render() {
    const {
      buildingComments,
      loading,
      updateComment: updateCommentAction,
      deleteComment: deleteCommentAction,
      closeModal,
    } = this.props;
    if (loading) {
      return (
        <div style={styles.container}>
          <CircularProgress color="primary" />
        </div>
      );
    }
    const commentsArr = Object.values(buildingComments);
    if (!commentsArr.length) return null;
    const { building } = commentsArr[0];
    return (
      <div>
        <div style={styles.infoContainer}>
          <h3>{`Comments for chat from ${building.name}`}</h3>
        </div>
        <CommentList
          comments={commentsArr}
          updateComment={updateCommentAction}
          deleteComment={deleteCommentAction}
          closeModal={closeModal}
          chat
          buildingName={building.name}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ groupComments: { buildingComments, loading } }) => ({
  buildingComments,
  loading,
});

export default connect(
  mapStateToProps,
  { getDiscussionComments, updateComment, deleteComment },
)(BuildingChatCommentsContainer);
