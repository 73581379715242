import axios from 'axios';
import bugsnag from 'bugsnag-js';
import { push } from 'connected-react-router';
import Cookies from "universal-cookie";

import { ROOT_URL } from '../config';
import { history } from '../store';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGOUT = 'LOGOUT';
export const RESET_BUILDINGS = 'RESET_BUILDINGS';
export const RESET_EVENTS = 'RESET_EVENTS';
export const RESET_NOTES = 'RESET_NOTES';
export const OPEN_AUTH_SNACKBAR = 'OPEN_AUTH_SNACKBAR';
export const ROLE_FETCHED = 'ROLE_FETCHED';

const cookies = new Cookies();
export const loginWithEmail = ({ email, password }) => (dispatch) => {
  dispatch({
    type: LOGGING_IN,
  });

  const cookies = new Cookies();

  const data = new FormData();
  data.append('email', email);
  data.append('password', password);

  axios({
    method: 'POST',
    url: `${ROOT_URL}/auth/login`,
    data,
    headers: {
      'client': 'ROYAL'
    }
  })
    .then(async (response) => {
      if (response.data.token) {
        cookies.set('token', response.data.token);
      }
      const { message } = response.data.message;
      if (message && response.status === 200) {
        dispatch({
          type: OPEN_AUTH_SNACKBAR,
          payload: 'Invalid credentials',
        });
      } else {
          axios({
            method: 'GET',
            url: `${ROOT_URL}/v2/user/role/${response.data.user.uuid}`,
            headers: { token: cookies.get('token') }
          })
          .then(async (response) => {
              dispatch({
                  type: ROLE_FETCHED,
                  payload: response.data,
                });
                window.localStorage.setItem('role', response.data);
            })
          .catch((error) => {
              bugsnagClient.notify(error);
          });
          dispatch({
            type: LOGGED_IN,
            payload: response.data,
          });
        }
      if(cookies.get('token')) {
        history.push('/building');
      }
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const logout = () => (dispatch) => {
  cookies.remove('token');
  window.localStorage.removeItem('role');
  history.push('/login');
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: RESET_EVENTS,
  });
  dispatch({
    type: RESET_BUILDINGS,
  });
  dispatch({
    type: RESET_NOTES,
  });
};