import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createMerchant, updateMerchant } from '../../actions/MerchantActions';
import { getBuildings } from '../../actions/BuildingActions';
import MerchantForm from '../../components/MerchantForm/MerchantForm';
import './MerchantFormContainer.css';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'string',
];
const MerchantSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  category: Yup.string()
    .required('Required'),
  building: Yup.string()
    .required('Required'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => (value && SUPPORTED_FORMATS.includes(value.type)),
    ),
});

class MerchantFormContainer extends Component {
  componentDidMount() {
    const { getBuildings, buildings } = this.props;
    if (Object.values(buildings).length === 0) { getBuildings(); }
  }

  handleCreateMerchant = (values) => {
    const { closeModal } = this.props;

    const data = new FormData();
    data.append('name', values.name.trim());
    data.append('category', values.category);
    data.append('building', values.building);
    data.append('image', values.image);

    this.props.createMerchant(data);
    closeModal();
  }

  handleUpdateMerchant = (values) => {
    const { closeModal, updatableMerchant } = this.props;
    const data = new FormData();
    data.append('name', values.name.trim());
    data.append('category', values.category.trim());
    data.append('building', values.building.trim());
    data.append('image', values.image);

    this.props.updateMerchant(data, updatableMerchant.uuid);

    closeModal();
  }

  handleSubmitPress = (values, formType) => {
    if (formType === 'create') {
      this.handleCreateMerchant(values);
    }
    if (formType === 'update') {
      this.handleUpdateMerchant(values);
    }
  }

  render() {
    const { updatableMerchant, closeModal, buildings } = this.props;
    let initialValues; let
      initialTouched;
    let formType;
    if (updatableMerchant) {
      formType = 'update';
      const {
        name, image, category, building,
      } = updatableMerchant;
      initialValues = {
        name,
        category,
        building,
        image: { preview: image, type: 'string' },
      };
      initialTouched = {
        name: true,
        category: true,
        building: true,
        image: true,
      };
    } else {
      formType = 'create';
      initialValues = {
        name: '',
        category: '',
        building: '',
        image: {},
      };
    }

    return (
      <div>
        <h3>
          {updatableMerchant
            ? `Update the merchant ${updatableMerchant.name}`
            : 'Create a new Merchant'}
        </h3>
        <Formik
          initialValues={initialValues}
          initialTouched={initialTouched}
          validateOnMount={!!updatableMerchant}
          validationSchema={MerchantSchema}
          onSubmit={(values) => this.handleSubmitPress(values, formType)}
        >
          {(formikProps) => (
            <MerchantForm
              {...formikProps}
              updatableMerchant={Boolean(updatableMerchant)}
              closeForm={closeModal}
              buildings={Object.values(buildings)}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { buildings, groups } = state;
  return {
    buildings: buildings.buildings,
    groups: groups.groups,
  };
};

export default connect(mapStateToProps, {
  createMerchant,
  updateMerchant,
  getBuildings,
})(MerchantFormContainer);
