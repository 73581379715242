import React from 'react';
import Button from '@material-ui/core/Button';
import { TextField, Stepper, Step, StepLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

import './CreateBuildingPerkForm.css';

function getSteps() {
  return ['Select Building', 'Select Merchant', 'Select Perk'];
}

function getStepContent(stepIndex, buildings, merchants, perks, state, handleChange) {
  switch (stepIndex) {
    case 0:
      return (
        <div>
          <TextField
            label="Building"
            className="building-drop-down"
            select
            required
            value={state.buildingDropDownSelection}
            onChange={handleChange('buildingDropDownSelection')}
            margin="normal"
          >
            {_.map(buildings, building => (
              <MenuItem key={building.uuid} value={building.uuid}>
                {building.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    case 1:
      return (
        <div>
          <TextField
            label="Merchant"
            className="merchant-drop-down"
            select
            required
            value={state.merchantDropDownSelection}
            onChange={handleChange('merchantDropDownSelection')}
            margin="normal"
          >
            {_.map(merchants, merchant => (
              <MenuItem key={merchant.uuid} value={merchant.uuid}>
                {merchant.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    case 2:
      return (
        <div>
          <TextField
            label="Perk"
            className="perk-drop-down"
            select
            required
            value={state.perkDropDownSelection}
            onChange={handleChange('perkDropDownSelection')}
            margin="normal"
          >
            {_.map(perks, perk => (
              <MenuItem key={perk.uuid} value={perk.uuid}>
                {perk.description}
              </MenuItem>
            ))}s
          </TextField>
        </div>
      );
    default:
      return 'Unknown stepIndex';
  }
}

const disableButtonLogic = function disableButtonLogic(activeStep, state) {
  if (activeStep === 0 && state.buildingDropDownSelection === '') {
    return true;
  }
  if (activeStep === 1 && state.merchantDropDownSelection === '') {
    return true;
  }
  if (activeStep === 2 && state.perkDropDownSelection === '') {
    return true;
  }
  return false;
};

const CreateBuildingPerkForm = ({
  state,
  buildings,
  handleChange,
  handleNext,
  handleBack,
  handleReset,
  merchants,
  perks,
}) => {
  const steps = getSteps();
  const { activeStep } = state;
  return (
    <div className="root">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content-container">
        {activeStep === steps.length ? (
          <div>
            <Typography>Building Perk Created!</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div className="step-content-container">
            {getStepContent(activeStep, buildings, merchants, perks, state, handleChange)}
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button
                disabled={disableButtonLogic(activeStep, state)}
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateBuildingPerkForm;
