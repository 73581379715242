import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from 'universal-cookie';

import { ROOT_URL } from '../config';

import { getCheckIns } from './PostActions';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHING_RESIDENTS = 'FETCHING_RESIDENTS';
export const RESIDENTS_FETCHED = 'RESIDENTS_FETCHED';
export const DEACTIVATE_RESIDENT = 'DEACTIVATE_RESIDENT';
export const RESIDENT_FETCHED = 'RESIDENT_FETCHED';
export const CSV_UPLOAD_DATA = 'CSV_UPLOAD_DATA';
export const OPEN_RESIDENT_SNACKBAR = 'OPEN_RESIDENT_SNACKBAR';
export const BLOCK_RESIDENT = 'BLOCK_RESIDENT';

export const getBuildingResidents = (buildingUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  const params = {
    building: buildingUuid,
  };

  dispatch({
    type: FETCHING_RESIDENTS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/v2/admin/signups?building=${params.building}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: RESIDENTS_FETCHED,
        payload: response.data.users,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};


export const getBuildingResident = (residentUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: FETCHING_RESIDENTS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/user/${residentUuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: RESIDENT_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const checkInBuildingResident = (eventUuid, residentId, type) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  const data = new FormData();
  data.append('type', type);
  data.append('user', residentId);

  axios({
    url: `${ROOT_URL}/v2/post/${eventUuid}/checkin`,
    method: 'POST',
    headers: { token },
    data,
  })
    .then(() => {
      dispatch(getCheckIns(eventUuid));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const uploadResidentCSV = (csvFile, buildingId) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  if (csvFile.length === 0) {
    dispatch({
      type: OPEN_RESIDENT_SNACKBAR,
      payload: 'You can only upload a .csv file',
    });
    return;
  }

  dispatch({
    type: FETCHING_RESIDENTS,
  });
  const data = new FormData();
  data.append('csvFile', csvFile);

  axios({
    url: `${ROOT_URL}/building/${buildingId}/bulkuser/create`,
    method: 'POST',
    headers: { token },
    data,
  })
    .then((response) => {
      dispatch({
        type: CSV_UPLOAD_DATA,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_RESIDENT_SNACKBAR,
        payload: error.response.data.message,
      });
    });
};

export const deactivateResident = (residentUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/user/${residentUuid}/deactivate`,
    method: 'POST',
    headers: { token },
  }).then((response) => {
    dispatch({
      type: DEACTIVATE_RESIDENT,
      payload: response.data,
    });
  })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const blockResident = (residentUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/user/${residentUuid}/block`,
    method: 'POST',
    headers: { token },
  }).then((response) => {
    dispatch({
      type: BLOCK_RESIDENT,
      payload: response.data,
    });
  })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const clearUploadData = () => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: CSV_UPLOAD_DATA,
    payload: {},
  });
};
