import React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';

const RenderBuildingResidents = ({ buildingResidents, loading, onClick }) => {
  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  return _.map(buildingResidents, (buildingResident) => {
    const fullName = `${buildingResident.firstName} ${buildingResident.lastName}`;
    return (
      <Grid key={buildingResident.uuid} item xs={5} sm={6} zeroMinWidth>
        <Chip
          onClick={() => { onClick(buildingResident.uuid); }}
          avatar={<Avatar src={buildingResident.profileImage} />}
          label={fullName}
        />
      </Grid>
    );
  });
};


export default RenderBuildingResidents;
