import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';

const RejectGroup = ({
  proposedGroup: {
    title,
    building,
    description,
    suggestingUser: { firstName, lastName, email },
  },
  handleChange,
  handleSubmit,
  reasonText,
}) => (
  <div className="group-modal-content">
    <h3>Reject Group</h3>
    <Grid container spacing={3}>
      <Grid item xs={12} md={9}>
        <div className="detail-content-container">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <p className="detail-label">Title</p>
            </Grid>
            <Grid item xs={9}>
              <p>{title}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">User</p>
            </Grid>
            <Grid item xs={9}>
              <p>{`${firstName} ${lastName}`}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">Email</p>
            </Grid>
            <Grid item xs={9}>
              <p>{email}</p>
            </Grid>
            <Grid item xs={3}>
              <p className="detail-label">Building</p>
            </Grid>
            <Grid item xs={9}>
              <p>{building.name}</p>
            </Grid>
            <Grid item sm={3} xs={12}>
              <p className="detail-label">Description</p>
            </Grid>
            <Grid item sm={9} xs={12}>
              <p>{description}</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="rejection-reason-form"
                name="rejection-reason-form"
                value={reasonText}
                multiline
                rows={3}
                label="Reason for Rejecting Group"
                onChange={value => handleChange(value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      onClick={handleSubmit}
    >
      Submit
    </Button>
  </div>
);

export default RejectGroup;
