import _ from 'lodash';
import {
  SET_GROUP_EVENT_LOADING,
  OPEN_GROUP_EVENT_SNACKBAR,
  GET_GROUP_EVENTS,
  CREATE_GROUP_EVENT,
  UPDATE_GROUP_EVENT,
  DELETE_GROUP_EVENT,
} from '../actions/GroupEventActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';

const initialState = {
  groupEvents: {},
  loading: false,
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_GROUP_EVENT_LOADING:
      return { ...state, loading: payload };
    case OPEN_GROUP_EVENT_SNACKBAR:
      return {
        ...state,
        snackbarOpen: payload.snackbarOpen,
        errorMessage: payload.errorMessage,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        errorMessage: '',
      };
    case GET_GROUP_EVENTS: {
      const groupEvents = _.mapKeys(payload.groupEvents, 'uuid');
      return { ...state, groupEvents, loading: false };
    }
    case CREATE_GROUP_EVENT:
      return {
        ...state,
        groupEvents: { ...state.groupEvents, [payload.uuid]: payload },
      };
    case UPDATE_GROUP_EVENT:
      return {
        ...state,
        groupEvents: { ...state.groupEvents, [payload.uuid]: payload },
      };
    case DELETE_GROUP_EVENT: {
      const groupEvents = { ...state.groupEvents };
      delete groupEvents[payload.groupEventId];
      return { ...state, groupEvents };
    }
    default:
      return state;
  }
};
