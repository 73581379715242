import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from 'universal-cookie';
import { ROOT_URL } from '../config';
import { OPEN_GROUP_EVENT_SNACKBAR } from './GroupActions';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const GET_GROUP_COMMENTS = 'GET_GROUP_COMMENTS';
export const SET_GROUP_COMMENT_LOADING = 'SET_GROUP_COMMENT_LOADING';
export const SET_SCHEDULED_GROUP_COMMENTS = 'SET_SCHEDULED_GROUP_COMMENTS';
export const DELETE_SCHEDULED_GROUP_COMMENTS = 'DELETE_SCHEDULED_GROUP_COMMENTS';
export const UPDATE_SCHEDULED_GROUP_COMMENTS = 'UPDATE_SCHEDULED_GROUP_COMMENTS';
export const UPDATE_GROUP_COMMENT = 'UPDATE_GROUP_COMMENT';
export const GET_BUILDING_COMMENTS = 'GET_BUILDING_COMMENTS';
export const UPDATE_BUILDING_CHAT_COMMENT = 'UPDATE_BUILDING_CHAT_COMMENT';

export const getGroupComments = (group) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: SET_GROUP_COMMENT_LOADING,
    payload: true,
  });
  axios({
    url: `${ROOT_URL}/groupcomment`,
    method: 'GET',
    headers: { token },
    params: {
      group,
      sort: 'createdAt DESC',
      params: { limit: 300 },
    },
  })
    .then((response) => {
      dispatch({
        type: GET_GROUP_COMMENTS,
        payload: response.data,
      });
      dispatch({
        type: SET_GROUP_COMMENT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getDiscussionComments = (building) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: SET_GROUP_COMMENT_LOADING,
    payload: true,
  });
  axios({
    url: `${ROOT_URL}/globalcomment`,
    method: 'GET',
    headers: { token },
    params: {
      building,
      limit: 300,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_BUILDING_COMMENTS,
        payload: response.data,
      });
      dispatch({
        type: SET_GROUP_COMMENT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getScheduledGroupEventComments = (uuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  axios({
    url: `${ROOT_URL}/scheduledgroupcomment`,
    method: 'GET',
    headers: { token },
    params: {
      group: 'null',
      building: uuid,
      limit: 300,
    },
  })
    .then((response) => {
      dispatch({
        type: SET_SCHEDULED_GROUP_COMMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const deleteScheduledGroupComment = (uuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: `${ROOT_URL}/ScheduledGroupComment/${uuid}`,
    method: 'DELETE',
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: DELETE_SCHEDULED_GROUP_COMMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateScheduledGroupComment = (comment, uuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: `${ROOT_URL}/scheduledgroupcomment/${uuid}`,
    method: 'PATCH',
    headers: { token },
    data: comment,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_SCHEDULED_GROUP_COMMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      alert(
        error.response.status === 404
          ? "Creator email provided didn't match any data in the building"
          : 'An error occured',
      );
      bugsnagClient.notify(error);
    });
};

export const createGroupComment = (comment, scheduled) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: scheduled
      ? `${ROOT_URL}/scheduledgroupcomment`
      : `${ROOT_URL}/groupcomment`,
    method: 'POST',
    headers: { token },
    data: comment,
  }).catch((error) => {
    dispatch({
      type: OPEN_GROUP_EVENT_SNACKBAR,
      payload: {
        snackbarOpen: true,
        errorMessage:
          error.response.status === 404
            ? "Creator email or group provided didn't match any data in the building"
            : error.response.data.message,
      },
    });
  });
};

export const updateComment = (comment, chat) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const data = { ...comment, hiddenBy: 'ADMIN', removalReason: '' };
  axios({
    url: `${ROOT_URL}/groupcomment/${comment.uuid}`,
    method: 'PATCH',
    headers: { token },
    data,
  })
    .then((response) => {
      if (chat) {
        dispatch({
          type: UPDATE_BUILDING_CHAT_COMMENT,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UPDATE_GROUP_COMMENT,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      bugsnag.notify(error);
    });
};

export const deleteComment = (comment) => () => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: `${ROOT_URL}/groupcomment/${comment.uuid}`,
    method: 'DELETE',
    headers: { token },
  })
    .then(() => {
      alert(
        comment.parent
          ? 'Comment successfully deleted'
          : 'Thread successfully deleted',
      );
    })
    .catch((error) => {
      bugsnag.notify(error);
    });
};
