import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CreateGroupForm from '../CreateGroupForm/CreateGroupForm';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'string'];

const createGroupSchema = Yup.object().shape({
  title: Yup.string()
    .max(500, 'The title must be no more than 500 characters')
    .required('Title is Required'),
  building: Yup.string().required('Building is required'),
  description: Yup.string()
    .min(10, 'The description must be at least 10 characters')
    .required('Description is required'),
  interests: Yup.array()
    .min(1, 'Please select at least one interest!')
    .required('Interest Field is required'),
  image: Yup.mixed()
    .required('Uploading an image is required')
    .test(
      'fileFormat',
      'Image must be .jpg, .jpeg, or .png',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});

class CreateGroups extends Component {
  handleTheSubmit = (group) => {
    const {
      groupSelected,
      createGroup,
      handleChangeOverlayCreate,
      patchApprovedStatus,
      getGroupsFromUserBuildings,
    } = this.props;

    const groupCreating = new FormData();
    groupCreating.append('name', group.title.trim());
    groupCreating.append('building', group.building);
    groupCreating.append('description', group.description);
    groupCreating.append('active', group.active);
    groupCreating.append('featured', group.featured);
    groupCreating.append('interests', JSON.stringify(group.interests));
    if (groupSelected && groupSelected.suggestingUser) {
      groupCreating.append('groupProposer', groupSelected.suggestingUser.uuid);
    }

    if (group.image) {
      groupCreating.append('imageUrl', group.image);
    }

    createGroup(groupCreating);
    handleChangeOverlayCreate();
    if (groupSelected) {
      patchApprovedStatus(groupSelected);
      getGroupsFromUserBuildings();
    }
  };

  render() {
    const {
      groupSelected, createGroup, interests, buildings,
    } = this.props;

    return (
      <div>
        <Formik
          initialValues={{
            title: (groupSelected && groupSelected.title) || '',
            building: (groupSelected && groupSelected.building.uuid) || '',
            description: (groupSelected && groupSelected.description) || '',
            featured: false,
            active: false,
            interests: [],
            image: {},
          }}
          validationSchema={createGroupSchema}
          onSubmit={this.handleTheSubmit}
        >
          {(formikProps) => (
            <CreateGroupForm
              {...formikProps}
              buildings={buildings}
              groupSelected={groupSelected}
              createGroup={createGroup}
              interests={interests}
            />
          )}
        </Formik>
      </div>
    );
  }
}

export default CreateGroups;
