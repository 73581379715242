import React from 'react';
import { Formik } from 'formik';
import UpdateBuildingForm from '../UpdateBuildingForm/UpdateBuildingForm';

const UpdateBuilding = ({
  buildingSelected,
  handleUpdateSubmit
}) => {
  const {
    name, shortName, image,
  } = buildingSelected;

  return (
    <div>
      <Formik
        initialValues={{
          name,
          shortName,
          image: image ? { preview: image, type: 'string' } : null,
        }}
        onSubmit={values => handleUpdateSubmit(values)}
      >
        {formikProps => (
          <UpdateBuildingForm
            {...formikProps}
          />
          )}
      </Formik>
    </div>
  );
};

export default UpdateBuilding;
