import React from 'react';
import { Formik } from 'formik';
import CopyGroupForm from '../CopyGroupForm/CopyGroupForm';

const CopyGroup = ({
  groupSelected,
  interests,
  handleCopySubmit,
  closeModal,
  buildings,
  groups
}) => {
  const {
    building, featured, active, name, description, imageUrl,
  } = groupSelected;

  const interestsUUIDs = groupSelected.interests.map(inter => inter.uuid);

  const handleCopyButton = (values) => {
      const groupCopy = new FormData();
      groupCopy.append('name', name);
      groupCopy.append('building', values.building);
      groupCopy.append('description', description);
      groupCopy.append('active', active);
      groupCopy.append('featured', featured);
      groupCopy.append('interests', JSON.stringify(interestsUUIDs));
      groupCopy.append('imageUrl', imageUrl);
      groupCopy.append('imageCopyUrl', imageUrl.toString());
      
      handleCopySubmit(groupCopy);
    closeModal();
  };

  return (
    <div>
      <Formik
       initialValues={{
        buildingUuid: building.uuid,
        building: ""
      }}
        onSubmit={values => handleCopyButton(values)}
      >
        {formikProps => (
          <CopyGroupForm
            {...formikProps}
            interests={interests}
            buildings={buildings}
            groups={groups}
            groupSelected={groupSelected}
          />
          )}
      </Formik>
    </div>
  );
};

export default CopyGroup;
