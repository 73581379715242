import React from 'react';
import _ from 'lodash';
import Comment from '../Coment/Comment';

const CommentList = ({
  comments,
  updateComment,
  deleteComment,
  chat,
  closeModal,
  buildingName,
}) => {
  const handleRemovedSwitch = (comment) => {
    updateComment(
      { ...comment, removed: !comment.removed, removedBy: 'ADMIN' },
      chat,
    );
  };

  const handleHiddenSwitch = (comment) => {
    updateComment({ ...comment, hidden: !comment.hidden, removedBy: '' }, chat);
  };

  const handleDeleteThread = (comment) => {
    if (
      window.confirm(
        `Are you sure you want to permanently delete ${
          comment.parent ? 'this comment' : 'the whole thread'
        }?`,
      )
    ) {
      deleteComment(comment, chat);
    }
  };

  const renderParentsAndChildren = (parentComment) => {
    let children;
    if (parentComment.children && parentComment.children.length) {
      children = _.map(parentComment.children, (child) => (
        <Comment
          comment={child}
          isChild
          key={child.uuid}
          parentRemoved={parentComment.removed}
          parentHidden={parentComment.hidden}
          handleRemovedSwitch={handleRemovedSwitch}
          handleDeleteThread={handleDeleteThread}
          handleHiddenSwitch={handleHiddenSwitch}
          closeModal={closeModal}
          buildingName={buildingName}
        />
      ));
    }
    return (
      <div key={parentComment.uuid}>
        <Comment
          comment={parentComment}
          handleRemovedSwitch={handleRemovedSwitch}
          handleHiddenSwitch={handleHiddenSwitch}
          handleDeleteThread={handleDeleteThread}
          closeModal={closeModal}
          buildingName={buildingName}
        />
        <div>{children}</div>
      </div>
    );
  };

  const commentsArr = Object.values(comments);
  if (!commentsArr.length) return null;
  return (
    <div>
      {_.map(commentsArr, (comment) => renderParentsAndChildren(comment))}
    </div>
  );
};

export default CommentList;
