import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import {
  getGroupActions,
  chooseGroup,
  createGroup,
  updateProposedGroup,
  patchApprovedStatus,
  getGroupsFromUserBuildings,
} from '../../actions/GroupActions';
import { getInterests } from '../../actions/TagActions';
import { getBuildingsSimple } from '../../actions/BuildingActions';
import GroupsProposals from '../../components/GroupsProposals/GroupsProposals';
import CreateGroups from '../../components/CreateGroups/CreateGroups';
import CustomModal from '../../components/CustomModal/CustomModal';
import RejectGroup from '../../components/RejectGroup/RejectGroup';
import ScheduleCommentModal from '../../components/ScheduleCommentModal/ScheduleCommentModal';

import '../GroupsContainer/GroupsContainer.css';


class GroupsProposalsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showModal: null,
    };
  }

  componentDidMount() {
    const { getGroupActions, getBuildingsSimple, getInterests } = this.props;
    getGroupActions(false);
    getBuildingsSimple();
    getInterests();
  }

  handleShowModal = (status) => {
    const newState = { showModal: status };
    if (!status) {
      newState.reason = '';
    }
    this.setState(newState);
  }

  handleCreateModal = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row.original);
    this.handleShowModal('create');
  }

  handleReject = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row.original);
    this.handleShowModal('reject');
  }

  handleRejectSubmit = () => {
    const { updateProposedGroup, groupSelected } = this.props;
    const { reason } = this.state;
    updateProposedGroup(groupSelected, reason);
    this.handleShowModal(null);
  }

  handleReasonChange = (event) => {
    this.setState({
      reason: event.target.value,
    });
  }

  renderModal = () => {
    const { showModal, reason } = this.state;
    const {
      groupSelected, patchApprovedStatus, buildings, createGroup, interests, getGroupsFromUserBuildings,
    } = this.props;
    let content = null;
    if (showModal === 'create') {
      content = (
        <CreateGroups
          buildings={buildings}
          handleChangeOverlayCreate={() => this.handleShowModal(null)}
          createGroup={createGroup}
          patchApprovedStatus={patchApprovedStatus}
          getGroupsFromUserBuildings={getGroupsFromUserBuildings}
          interests={interests}
          groupSelected={groupSelected}
        />
      );
    } else if (showModal === 'reject') {
      content = (
        <RejectGroup
          proposedGroup={groupSelected}
          reasonText={reason}
          handleChange={this.handleReasonChange}
          handleSubmit={this.handleRejectSubmit}
        />
      );
    } else if (showModal === 'new') {
      content = (
        <CreateGroups
          buildings={buildings}
          handleChangeOverlayCreate={() => this.handleShowModal(null)}
          createGroup={createGroup}
          patchApprovedStatus={patchApprovedStatus}
          getGroupsFromUserBuildings={getGroupsFromUserBuildings}
          interests={interests}
        />
      );
    } else if (showModal === 'schedule') {
      content = (
        <ScheduleCommentModal
          closeForm={() => this.handleShowModal(null)}
        />
      );
    }

    return (
      <CustomModal
        content={content}
        closeModal={() => this.handleShowModal(null)}
      />
    );
  };

  render() {
    const { showModal } = this.state;
    const {
      groupProposals,
      loading,
    } = this.props;
    return (
      <div>
        <Button onClick={() => this.handleShowModal('new')} color="primary">
          Create new group
        </Button>
        <Button onClick={() => this.handleShowModal('schedule')} color="secondary">
          Schedule or Post a Comment
        </Button>
        <GroupsProposals
          groupProposals={groupProposals}
          loading={loading}
          handleCreate={this.handleCreateModal}
          handleReject={this.handleReject}
        />
        {showModal && this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ groups, buildings, tags }) => ({
  groupProposals: groups.groupProposals,
  loading: groups.loading,
  groupSelected: groups.groupSelected,
  buildings: buildings.buildings,
  interests: tags.interests,
});

export default connect(mapStateToProps, {
  getGroupActions,
  chooseGroup,
  createGroup,
  getBuildingsSimple,
  updateProposedGroup,
  patchApprovedStatus,
  getGroupsFromUserBuildings,
  getInterests,
})(GroupsProposalsContainer);
