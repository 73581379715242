import bugsnag from 'bugsnag-js';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import createPlugin from 'bugsnag-react';

import './App.css';
import ProtectedContainer from './ProtectedContainer/ProtectedContainer';
import LoginContainer from './LoginContainer/LoginContainer';
import Forbidden from '../components/403Forbidden/403Forbidden';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');
const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

const App = () => (
  <ErrorBoundary>
    <MuiThemeProvider theme={muiTheme}>
      <div className="App">
        <Switch>
          <Route exact path="/403" component={Forbidden} />
          <Route exact path="/login" component={LoginContainer} />
          <Route path="/" component={ProtectedContainer} />
        </Switch>
      </div>
    </MuiThemeProvider>
  </ErrorBoundary>
);

export default App;
