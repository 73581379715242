import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Snackbar from '../../components/Snackbar/Snackbar';
import { loginWithEmail } from '../../actions/AuthActions';
import '../../components/LoginForm/LoginForm.css';
import LoginForm from '../../components/LoginForm/LoginForm';

class LoginContainer extends Component {
  render() {
    if (this.props.userToken) {
      return <Redirect to="/building" />;
    }

    const { loginWithEmail: loginWithEmailAction } = this.props;
    return (
      <div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => loginWithEmailAction(values)}
        >
          {({
            handleSubmit,
            handleChange,
            loading,
          }) => <LoginForm handleSubmit={handleSubmit} handleChange={handleChange} loading={loading} />}
        </Formik>
        <Snackbar
          snackbarOpen={this.props.snackbarOpen}
          errorMessage={this.props.errorMessage}
          position="bottom"
          autoHideMilliseconds={3000}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  userToken: auth.token,
  loading: auth.loading,
  snackbarOpen: auth.snackbarOpen,
  errorMessage: auth.errorMessage,
});

export default connect(mapStateToProps, { loginWithEmail })(LoginContainer);
