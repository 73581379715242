import {
  FETCHING_BUILDING_DASHBOARD_CONFIG,
  BUILDING_DASHBOARD_CONFIG_FETCHED,
} from "../actions/BuildingDashboardConfigActions";

const initialState = {
  buildingDashboardConfig: {},
  showDashboardLocal: false,
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BUILDING_DASHBOARD_CONFIG:
      return { ...state, loading: true };
    case BUILDING_DASHBOARD_CONFIG_FETCHED:
      if (action.payload.config) {
        return {
          ...state,
          buildingDashboardConfig: action.payload,
          showDashboardLocal: action.payload.config["showPerks"],
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
