import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Field } from 'formik';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import '../GroupEventForm/GroupEventForm.css';
import { Checkbox } from '@material-ui/core';

class ScheduleCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { postNow: true };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }


  componentDidMount() {
    const { values: { building }, getGroups, group } = this.props;
    if (building) {
      getGroups([building], true, true);
    }
    if (building && !group) {
      this.state.postNow = false;
    }
  }

  getGroupSelectPlaceholder = (values, buildingGroups) => {
    if (!values.building) {
      return 'Select building first';
    }
    if (!buildingGroups.length) {
      return 'No groups for this building';
    }
    return 'Select Group';
  }

  getGroupEventSelectPlaceholder = (values, groupGroupEvents) => {
    if (!values.group) {
      return 'Select group first';
    }
    if (!groupGroupEvents.length) {
      return 'No gatherings for this group';
    }
    return 'Select Gathering';
  }

  handleChange = (name, setFieldValue) => (event) => {
    const { getGroups } = this.props;
    if (name === 'building') {
      // get active groups and residents in these buildings
      getGroups([event.target.value], true, true);
    }
    setFieldValue(name, event.target.value, true);
  };

  handleCheckboxChange = (setFieldValue) => (event) => {
    this.setState({
      postNow: event.target.checked,
    });
    setFieldValue('postTime', '');
  }

  render() {
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      selectOptions: { buildings, groups, groupEvents },
      handleSubmit,
      formType,
    } = this.props;
    const { postNow } = this.state;
    const buildingArr = Object.values(buildings);
    const groupArr = Object.values(groups);
    const groupEventArr = Object.values(groupEvents);

    const groupsInBuilding = groupArr.filter((group) => group.building.uuid === values.building);
    const groupEventsInGroup = groupEventArr.filter((groupEvent) => groupEvent.group.uuid === values.group);
    groupsInBuilding.unshift({ uuid: 'null', name: 'CHAT' });
    return (
      <form id="group-event-form" onSubmit={handleSubmit}>
        <div className="half-form-width">
          <label className="select-label" htmlFor="building-select">Building</label>
          <select
            id="building-select"
            value={values.building}
            onChange={this.handleChange('building', setFieldValue)}
            onBlur={() => setFieldTouched('building', true, false)}
            disabled={formType === 'update' || this.props.editMode}
          >
            <option value="" disabled>Select Building</option>
            {_.map(buildingArr, (building) => (
              <option key={building.uuid} value={building.uuid}>
                {building.name}
              </option>
            ))}
          </select>
        </div>
        <div className="half-form-width">
          <label className="select-label" htmlFor="group-select">Group</label>
          <select
            id="group-select"
            value={values.group}
            disabled={!values.building || !groupsInBuilding.length || this.props.editMode}
            onChange={this.handleChange('group', setFieldValue)}
            onBlur={() => setFieldTouched('group', true, false)}
          >
            <option value="" disabled>{this.getGroupSelectPlaceholder(values, groupsInBuilding)}</option>
            {_.map(groupsInBuilding, (group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        {(
        <div className="half-form-width">
          <label className="select-label" htmlFor="group-event-select">Gathering</label>
          <select
            id="group-event-select"
            value={values.groupEvent}
            disabled={!values.building || !values.group || !groupEventsInGroup.length}
            onChange={this.handleChange('groupEvent', setFieldValue)}
            onBlur={() => setFieldTouched('groupEvent', true, false)}
          >
            <option value="" disabled>{this.getGroupEventSelectPlaceholder(values, groupEventsInGroup)}</option>
            {_.map(groupEventsInGroup, (groupEvent) => (
              <option key={groupEvent.uuid} value={groupEvent.uuid}>
                {groupEvent.title}
              </option>
            ))}
          </select>
        </div>
        )}
        <div className="half-form-width">
          <TextField
            id="creatorEmail"
            name="creatorEmail"
            value={values.creatorEmail}
            type="email"
            helperText={touched.creatorEmail ? errors.creatorEmail : ''}
            error={Boolean(errors.creatorEmail) && touched.creatorEmail}
            label={values.building ? "Creator's Email" : 'Select building first'}
            disabled={formType === 'update' || !values.building}
            onChange={this.handleChange('creatorEmail', setFieldValue)}
            onBlur={() => setFieldTouched('creatorEmail', true, false)}
            fullWidth
          />
        </div>
        <TextField
          id="contents"
          name="contents"
          value={values.contents}
          helperText={touched.contents ? errors.contents : ''}
          error={Boolean(errors.contents) && touched.contents}
          label="Comment Contents"
          onChange={this.handleChange('contents', setFieldValue)}
          onBlur={() => setFieldTouched('contents', true, false)}
          fullWidth
          multiline
        />
        <div className="half-form-width">
          <Checkbox
            checked={postNow}
            onChange={this.handleCheckboxChange(setFieldValue)}
          />
          Post now
        </div>
        {!postNow && (
        <div className="half-form-width">
          <TextField
            id="postTime"
            name="postTime"
            value={postNow ? null : values.postTime}
            label="Date & Time of Posting"
            type="datetime-local"
            helperText={touched.postTime ? errors.postTime : ''}
            error={Boolean(errors.postTime) && (touched.postTime)}
            onChange={this.handleChange('postTime', setFieldValue)}
            onBlur={() => setFieldTouched('postTime', true, false)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            disabled={postNow}
          />
        </div>
        )}
        <div className="img-button-container">
          <Field
            name="image"
            component={(formikProps) => <ImageDropzone {...formikProps} />}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </form>
    );
  }
}


export default ScheduleCommentForm;
