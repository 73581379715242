import mapKeys from 'lodash/mapKeys';
import {
  FETCHING_BUILDINGS,
  BUILDINGS_FETCHED,
  FETCHING_BUILDING,
  BUILDING_FETCHED,
  CREATING_BUILDING,
  BUILDING_CSV_DOWNLOADING,
  BUILDING_CSV_DOWNLOADED,
  RESIDENT_SCORE_CSV_DOWNLOADING,
  RESIDENT_SCORE_CSV_DOWNLOADED,
  RESET_CSV,
  OPEN_BUILDING_SNACKBAR,
  ACTIVE_STATUS_CHANGE,
  GET_ADMINS,
  ADMINS_FETCHED,
  UPDATE_ADMIN_ROLE,
  DELETE_ADMIN,
  UPDATED_BUILDING,
  REVIEW_URL_BUILDING_CHANGE,
} from '../actions/BuildingActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';
import { RESET_BUILDINGS } from '../actions/AuthActions';

const initialState = {
  buildings: {},
  loading: true,
  snackbarOpen: false,
  errorMessage: '',
  message: '',
  csv: null,
  admins: [],
  residentScoreCSV: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BUILDINGS:
      return { ...state, loading: true };
    case BUILDINGS_FETCHED:
      return {
        ...state,
        buildings: mapKeys(action.payload, 'uuid'),
        loading: false,
      };
    case FETCHING_BUILDING:
      return { ...state, loading: true };
    case BUILDING_FETCHED:
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.payload.uuid]: action.payload,
        },
        loading: false,
      };
    case RESET_BUILDINGS:
      return initialState;

    case CREATING_BUILDING:
      return {
        ...state,
        loading: true,
      };
    case RESET_CSV:
      return { ...state, csv: null };
    case BUILDING_CSV_DOWNLOADING:
      return { ...state, loading: true };
    case BUILDING_CSV_DOWNLOADED:
      return { ...state, csv: action.payload, loading: false };
    case RESIDENT_SCORE_CSV_DOWNLOADING:
      return { ...state, loading: true };
    case RESIDENT_SCORE_CSV_DOWNLOADED:
      return { ...state, residentScoreCSV: action.payload, loading: false };
    case OPEN_BUILDING_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        errorMessage: action.payload.errorMessage,
        message: action.payload.message,
        loading: false,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    case ACTIVE_STATUS_CHANGE:
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.payload.uuid]: {
            ...state.buildings[action.payload.uuid],
            active: action.payload.active,
          },
        },
      };
    case GET_ADMINS:
      return {
        ...state,
        loading: true,
      };
    case ADMINS_FETCHED: {
      const newAdmins = mapKeys(action.payload, 'uuid');
      return {
        ...state,
        admins: { ...newAdmins },
      };
    }
    case UPDATE_ADMIN_ROLE: {
      return {
        ...state,
        admins: {
          ...state.admins,
          [action.payload.adminId]: {
            ...state.admins[action.payload.adminId],
            role: action.payload.role,
          },
        },
      };
    }
    case DELETE_ADMIN: {
      const admins = { ...state.admins };
      delete admins[action.payload.adminId];
      return { ...state, admins };
    }
    case UPDATED_BUILDING:
      const buildingsCopy = { ...state.buildings };
      buildingsCopy[action.payload.uuid] = action.payload;
      return {
        ...state,
        buildings: buildingsCopy,
      };
    case REVIEW_URL_BUILDING_CHANGE:
      return {
        ...state,
        buildings: {
          ...state.buildings,
          [action.payload.uuid]: {
            ...state.buildings[action.payload.uuid],
            reviewUrl: action.payload.reviewUrl,
          },
        },
      };
    default:
      return state;
  }
};
