import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Field } from 'formik';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import './GroupEventForm.css';

class GroupEventForm extends Component {
  componentDidMount() {
    const { values: { building }, getGroups } = this.props;

    if (building) {
      getGroups([building], true);
    }
  }

  getGroupSelectPlaceholder = (values, buildingGroups) => {
    if (!values.building) {
      return 'Select building first';
    }
    if (!buildingGroups.length) {
      return 'No groups for this building';
    }
    return 'Select Group';
  }

  handleChange = (name, setFieldValue) => (event) => {
    if (name === 'building') {
      // get active groups and residents in these buildings
      this.props.getGroups([event.target.value], true);
    }
    setFieldValue(name, event.target.value, true);
  };

  render() {
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      selectOptions: { buildings, groups },
      handleSubmit,
      formType,
    } = this.props;
    const buildingArr = Object.values(buildings);
    const groupArr = Object.values(groups);

    const groupsInBuilding = groupArr.filter((group) => group.building.uuid === values.building);

    return (
      <form id="group-event-form" onSubmit={handleSubmit}>
        <div className="half-form-width">
          <label className="select-label" htmlFor="building-select">Building</label>
          <select
            id="building-select"
            value={values.building}
            onChange={this.handleChange('building', setFieldValue)}
            onBlur={() => setFieldTouched('building', true, false)}
            disabled={formType === 'update'}
          >
            <option value="" disabled>Select Building</option>
            {_.map(buildingArr, (building) => (
              <option key={building.uuid} value={building.uuid}>
                {building.name}
              </option>
            ))}
          </select>
        </div>
        <div className="half-form-width">
          <label className="select-label" htmlFor="group-select">Group</label>
          <select
            id="group-select"
            value={values.group}
            disabled={formType === 'update' || !values.building || !groupsInBuilding.length}
            onChange={this.handleChange('group', setFieldValue)}
            onBlur={() => setFieldTouched('group', true, false)}
          >
            <option value="" disabled>{this.getGroupSelectPlaceholder(values, groupsInBuilding)}</option>
            {_.map(groupsInBuilding, (group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        <div className="half-form-width">
          <TextField
            id="creatorEmail"
            name="creatorEmail"
            value={values.creatorEmail}
            type="email"
            helperText={touched.creatorEmail ? errors.creatorEmail : ''}
            error={Boolean(errors.creatorEmail) && touched.creatorEmail}
            label={values.building ? "Creator's Email" : 'Select building first'}
            disabled={formType === 'update' || !values.building}
            onChange={this.handleChange('creatorEmail', setFieldValue)}
            onBlur={() => setFieldTouched('creatorEmail', true, false)}
            fullWidth
          />
        </div>
        <TextField
          id="title"
          name="title"
          value={values.title}
          helperText={touched.title ? errors.title : ''}
          error={Boolean(errors.title) && touched.title}
          label="Gathering Title"
          onChange={this.handleChange('title', setFieldValue)}
          onBlur={() => setFieldTouched('title', true, false)}
          fullWidth
          multiline
        />
        <div className="half-form-width">
          <TextField
            id="locationString"
            name="locationString"
            value={values.locationString}
            helperText={touched.locationString ? errors.locationString : ''}
            error={Boolean(errors.locationString) && touched.locationString}
            label="Location"
            onChange={this.handleChange('locationString', setFieldValue)}
            onBlur={() => setFieldTouched('locationString', true, false)}
            fullWidth
          />
        </div>
        <div className="half-form-width">
          <TextField
            id="startTime"
            name="startTime"
            value={values.startTime}
            label="Date & Starting Time"
            type="datetime-local"
            helperText={touched.startTime ? errors.startTime : ''}
            error={Boolean(errors.startTime) && (touched.startTime)}
            onChange={this.handleChange('startTime', setFieldValue)}
            onBlur={() => setFieldTouched('startTime', true, false)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </div>
        <TextField
          id="description"
          name="description"
          value={values.description}
          multiline
          rows={3}
          helperText={touched.description ? errors.description : ''}
          error={Boolean(errors.description) && touched.description}
          label="Gathering Description"
          onChange={this.handleChange('description', setFieldValue)}
          onBlur={() => setFieldTouched('description', true, false)}
          fullWidth
        />
        <div className="what-to-bring">
          <TextField
            id="whatToBring"
            name="whatToBring"
            value={values.whatToBring}
            label="What To Bring"
            onChange={this.handleChange('whatToBring', setFieldValue)}
            onBlur={() => setFieldTouched('whatToBring', true, false)}
            fullWidth
          />
        </div>
        <div className="max">
          <TextField
            id="maxEventAttendees"
            name="maxEventAttendees"
            value={values.maxEventAttendees}
            type="number"
            label="Total Number of Attendees"
            helperText={touched.maxEventAttendees ? errors.maxEventAttendees : ''}
            error={Boolean(errors.maxEventAttendees) && touched.maxEventAttendees}
            onChange={this.handleChange('maxEventAttendees', setFieldValue)}
            onBlur={() => setFieldTouched('maxEventAttendees', true, false)}
            fullWidth
          />
        </div>
        <div className="max">
          <TextField
            id="maxGuests"
            name="maxGuests"
            value={values.maxGuests}
            type="number"
            label="Max Additional Guests per RSVP"
            helperText={touched.maxGuests ? errors.maxGuests : ''}
            error={Boolean(errors.maxGuests) && touched.maxGuests}
            onChange={this.handleChange('maxGuests', setFieldValue)}
            onBlur={() => setFieldTouched('maxGuests', true, false)}
            fullWidth
          />
        </div>
        <div className="img-button-container">
          <Field
            name="image"
            error={touched.image && Boolean(errors.image)}
            component={(formikProps) => <ImageDropzone {...formikProps} />}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </form>
    );
  }
}


export default GroupEventForm;
