/*
NOTE: DIRECTIONS FOR SNACKBAR PROPS

You need to set snackbarOpen and errorMessage or message slices of state in the reducer
that is associated with the actions that you are trying to display errors for

Then, map the state from that reducer to this component in the container that
you added the Snackbar to (it can get a bit complicated -- see AuthActions,
AuthReducer, and LoginContainer for an example of the Redux flow)
*/

import React from 'react';
import { connect } from 'react-redux';
import { SnackbarContent, Snackbar as MUISnackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { closeSnackbar } from '../../actions/ErrorActions';
import './Snackbar.css';

class Snackbar extends React.Component {
  render() {
    const {
      position,
      autoHideMilliseconds,
      snackbarOpen,
      errorMessage,
      message,
      closeSnackbar: closeSnackbarAction,
    } = this.props;

    let snackbarMessage;
    if (errorMessage) {
      snackbarMessage = errorMessage;
      if (message) {
        snackbarMessage += `. ${message}`;
      }
    } else {
      snackbarMessage = message;
    }
    return (
      <MUISnackbar
        anchorOrigin={{
          vertical: position,
          horizontal: 'center',
        }}
        autoHideDuration={autoHideMilliseconds}
        onClose={closeSnackbarAction}
        open={snackbarOpen}
      >
        <SnackbarContent
          style={{
            backgroundColor: errorMessage ? '#d32f2f' : '#A5D6A7',
            margin: '10px',
          }}
          message={
            <span className="snackbar-span">
              {errorMessage ?
                <ErrorIcon className="error-icon-spacing" /> :
                <CheckIcon className="error-icon-spacing" />
              }
              {snackbarMessage}
            </span>
          }
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={closeSnackbarAction}
            >
              <CloseIcon/>
            </IconButton>
          }
        />
      </MUISnackbar>
    );
  }
}

/*
NOTE: OPTIONAL PROPS
The position prop can be set to 'bottom' (ie. the bottom of the window)
The autoHideMilliseconds prop determines when the Snackbar automatically disappears
*/
Snackbar.defaultProps = {
  position: 'top',
  autoHideMilliseconds: 80000,
};

export default connect(null, { closeSnackbar })(Snackbar);
