import { GET_EVENT_STRUCTURES, GET_INTERESTS } from '../actions/TagActions';

const initialState = {
  structures: [],
  interests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_STRUCTURES:
      return { ...state, structures: action.payload };
    case GET_INTERESTS:
      return { ...state, interests: action.payload };
    default:
      return state;
  }
};
