import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { getGroupEvents, deleteGroupEvent } from '../../actions/GroupEventActions';
import { getBuilding } from '../../actions/BuildingActions';
import GroupEventFormContainer from '../GroupEventFormContainer/GroupEventFormContainer';
import GroupEventCommentsContainer from '../GroupEventCommentsContainer/GroupEventCommentsContainer';
import GroupEvents from '../../components/GroupEvents/GroupEvents';
import GroupEventConfirmDelete from '../../components/GroupEventConfirmDelete/GroupEventConfirmDelete';
import CustomModal from '../../components/CustomModal/CustomModal';
import Snackbar from '../../components/Snackbar/Snackbar';
import './GroupEventsContainer.css';
import ScheduleCommentModal from '../../components/ScheduleCommentModal/ScheduleCommentModal';

class GroupsEventsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModal: false,
      modalType: '',
      targetGroupEvent: null,
    };
  }

  componentDidMount() {
    const { getGroupEvents } = this.props;

    getGroupEvents(this.props.match.params.buildingUuid);
    getBuilding(this.props.match.params.buildingUuid);
  }

  handleCloseModal = () => {
    this.setState({
      viewModal: false,
      modalType: '',
      targetGroupEvent: null,
    });
  }

  handleDeleteEventPress = (groupEventId) => {
    const { groupEvents } = this.props;
    this.setState({
      viewModal: true,
      modalType: 'delete',
      targetGroupEvent: groupEvents[groupEventId],
    });
  }

  handleUpdateEventPress = (groupEventId) => {
    const { groupEvents } = this.props;
    this.setState({
      viewModal: true,
      modalType: 'update',
      targetGroupEvent: groupEvents[groupEventId],
    });
  }

  handleCreateEventPress = () => {
    this.setState({
      viewModal: true,
      modalType: 'create',
      targetGroupEvent: null,
    });
  }

  handleScheduleCommentPress = () => {
    this.setState({
      viewModal: true,
      modalType: 'schedule',
    });
  }

  handleDuplicateEventPress = (groupEventId) => {
    const { groupEvents } = this.props;
    this.setState({
      viewModal: true,
      modalType: 'duplicate',
      targetGroupEvent: groupEvents[groupEventId],
    });
  }

  handleViewCommentPress = (groupEventId) => {
    const { groupEvents } = this.props;
    this.setState({
      viewModal: true,
      modalType: 'comments',
      targetGroupEvent: groupEvents[groupEventId],
    });
  }

  renderModal() {
    const { targetGroupEvent, modalType } = this.state;
    let content;

    switch (modalType) {
      case 'create':
        content = (
          <GroupEventFormContainer
            formType={modalType}
            closeForm={this.handleCloseModal}
          />
        );
        break;
      case 'update':
        content = (
          <GroupEventFormContainer
            formType={modalType}
            updatableGroupEvent={targetGroupEvent}
            closeForm={this.handleCloseModal}
          />
        );
        break;
      case 'duplicate':
        content = (
          <GroupEventFormContainer
            formType={modalType}
            updatableGroupEvent={targetGroupEvent}
            closeForm={this.handleCloseModal}
          />
        );
        break;
      case 'delete':
        content = (
          <GroupEventConfirmDelete
            groupEvent={targetGroupEvent}
            deleteGroupEvent={this.props.deleteGroupEvent}
            closeForm={this.handleCloseModal}
          />
        );
        break;
      case 'schedule':
        content = (
          <ScheduleCommentModal closeForm={this.handleCloseModal} />
        );
        break;
      case 'comments':
        content = (
          <GroupEventCommentsContainer
            buildingName={targetGroupEvent.building.name}
            groupEvent={targetGroupEvent}
            closeModal={this.handleCloseModal}
          />
        );
        break;
      default:
        break;
    }

    return <CustomModal content={content} closeModal={this.handleCloseModal} />;
  }

  render() {
    const {
      loading,
      groupEvents,
      errorMessage,
      snackbarOpen,
    } = this.props;
    const { viewModal } = this.state;
    if (loading) return <LinearProgress mode="indeterminate" />;
    const snackbar = (
      <Snackbar
        snackbarOpen={snackbarOpen}
        errorMessage={errorMessage}
      />
    );
    return (
      <div id="GroupEventsContainer">
        {snackbarOpen ? snackbar : null}
        {viewModal ? this.renderModal() : null}
        <GroupEvents
          groupEvents={groupEvents}
          handleUpdateEvent={this.handleUpdateEventPress}
          handleCreateEvent={this.handleCreateEventPress}
          handleScheduleComment={this.handleScheduleCommentPress}
          handleDuplicateEvent={this.handleDuplicateEventPress}
          handleDeleteEvent={this.handleDeleteEventPress}
          handleViewComment={this.handleViewCommentPress}
          buildingName={this.props.building ? this.props.building.name : "building"}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  groupEvents: {
    groupEvents,
    loading,
    snackbarOpen,
    errorMessage,
  },
  buildings,
},  props) => ({
  groupEvents,
  loading,
  snackbarOpen,
  errorMessage,
  building: buildings.buildings[props.match.params.buildingUuid]
});

export default connect(
  mapStateToProps,
  {
    getGroupEvents, getBuilding, deleteGroupEvent,
  },
)(GroupsEventsContainer);
