import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RedeemIcon from '@material-ui/icons/Redeem';
import GroupIcon from '@material-ui/icons/Group';
import SadFaceIcon from '@material-ui/icons/MoodBad';

import './NavigationDrawer.css';
import appVersion from '../../version';

const NavigationDrawer = (props) => (
  <Drawer open={props.drawerOpen} onClose={props.toggleDrawer}>
    <div className="navigation-drawer">
      <List component="nav">
        <ListItem button onClick={props.goToBuildings}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Buildings" />
        </ListItem>

        <ListItem button onClick={props.goToMerchants}>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Merchants" />
        </ListItem>

        <ListItem button onClick={props.goToPerks}>
          <ListItemIcon>
            <RedeemIcon />
          </ListItemIcon>
          <ListItemText primary="Perks" />
        </ListItem>

        <ListItem button onClick={props.goToGroups}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Group Proposals" />
        </ListItem>

        <ListItem button onClick={props.logout}>
          <ListItemIcon>
            <SadFaceIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
      <p style={{ bottom: 0 }}>{`Version ${appVersion}`}</p>
    </div>
  </Drawer>
);

export default NavigationDrawer;
