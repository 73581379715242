import React, { Component } from 'react';
import { connect } from 'react-redux';


import { getNote } from '../../actions/PostActions';
import NoteDetail from '../../components/NoteDetail/NoteDetail';

class NoteDetailContainer extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.getNote(match.params.noteUuid);
  }

  render() {
    return (
      <div>
        <NoteDetail
          note={this.props.note}
          loading={this.props.loading}

        />
      </div>
    );
  }
}
const mapStateToProps = ({ notes }, props) => ({
  loading: notes.loading,
  note: notes.notes[props.match.params.noteUuid],
});
export default connect(mapStateToProps, {
  getNote,
})(NoteDetailContainer);
