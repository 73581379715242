import {
  LOGGING_IN,
  LOGGED_IN,
  LOGOUT,
  OPEN_AUTH_SNACKBAR,
  ROLE_FETCHED
} from '../actions/AuthActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';

const initialState = {
  token: null,
  loading: false,
  snackbarOpen: false,
  errorMessage: '',
  role: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return { ...state, loading: true };
    case LOGGED_IN:
      return { ...state, loading: false };
    case LOGOUT:
      return initialState;
    case OPEN_AUTH_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        errorMessage: action.payload,
        loading: false,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
      case ROLE_FETCHED:
        return {
          ...state,
          role: action.payload,
        };
    default:
      return state;
  }
};
