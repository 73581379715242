import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import {
  getAdmins, getBuildings, changeAdminRole, deleteAdmin,
} from '../../actions/BuildingActions';
import AdminsTable from './AdminsTable';
import { roles } from '../../constants';

class AdminsTableContainer extends Component {
  async componentDidMount() {
    const { getAdmins, getBuildings } = this.props;
    await getAdmins();
    await getBuildings();
  }

  updateRole = (userId, newRole) => {
    const { changeAdminRole } = this.props;
    if (window.confirm('Are you sure you want to change this user\'s role? They will lose/gain some permissions.')) {
      changeAdminRole(userId, newRole);
    }
  }

  render() {
    const { admins, buildings, deleteAdmin } = this.props;

    const buildingsArr = Object.values(buildings);
    const superadmin = !!(buildingsArr?.find((b) => b.role === roles.SUPERADMIN));
    const adminsArr = Object.values(admins);

    if (!buildingsArr.length && adminsArr.length === 0) return <LinearProgress />;

    if (superadmin === false && buildingsArr.length > 0) {
      return <Redirect to="/403" />;
    }


    return (
      <AdminsTable admins={adminsArr} handleChange={this.updateRole} deleteAdmin={deleteAdmin} />
    );
  }
}

const mapStateToProps = (state) => {
  const { buildings } = state;
  return {
    admins: buildings.admins,
    buildings: buildings.buildings,
  };
};

export default connect(mapStateToProps, {
  getAdmins, getBuildings, changeAdminRole, deleteAdmin,
})(AdminsTableContainer);
