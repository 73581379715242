import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { getBuildingResidents, getBuildingResident } from '../../actions/BuildingResidentActions';
import { downloadResidents, downloadResidentsWithScore, resetState } from '../../actions/BuildingActions';
import RenderBuildingResidentDirectory from '../../components/RenderBuildingResidentDirectory/RenderBuildingResidentDirectory';
import getEntrataDataScript from '../../scripts/entrata_script';
import './BuildingResidentContainer.css';

class BuildingResidentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsersOn: false,
      newUsersOn: false,
    };
    this.handleActiveUserSwitch = this.handleActiveUserSwitch.bind(this);
    this.handleNewUserSwitch = this.handleNewUserSwitch.bind(this);
    this.getUserCSV = this.getUserCSV.bind(this);
    this.getResidentScoreCSV = this.getResidentScoreCSV.bind(this);
  }

  componentDidMount() {
    this.props.getBuildingResidents(this.props.buildingUuid);
  }

  getUserCSV() {
    let createDate = moment.utc().subtract(4, 'weeks').format();
    const endDate = moment.utc().format();
    let lastActiveDate = moment.utc().subtract(4, 'weeks').format();
    createDate = this.state.newUsersOn ? createDate : null;
    lastActiveDate = this.state.activeUsersOn ? lastActiveDate : null;
    if (createDate && lastActiveDate) {
      this.props
        .downloadResidents(this.props.buildingUuid, endDate, { createDate, lastActiveDate });
    } else if (createDate) {
      this.props.downloadResidents(this.props.buildingUuid, endDate, { createDate });
    } else if (lastActiveDate) {
      this.props.downloadResidents(this.props.buildingUuid, endDate, { lastActiveDate });
    } else {
      this.props.downloadResidents(this.props.buildingUuid, endDate);
    }
  }
  getResidentScoreCSV() {
    this.props.downloadResidentsWithScore(this.props.buildingUuid);
  }
  findUsers() {
    const recentDate = moment().subtract(4, 'weeks');
    let chosenUsers = this.props.buildingResidents;

    if (this.state.activeUsersOn) {
      chosenUsers = _.filter(chosenUsers, (user) => {
        const UserDate = moment(user.lastActivity);
        return UserDate.isAfter(recentDate);
      });
    }
    if (this.state.newUsersOn) {
      chosenUsers = _.filter(chosenUsers, (user) => {
        const UserDate = moment(user.createdAt);
        return UserDate.isAfter(recentDate);
      });
    }
    return chosenUsers;
  }

  handleResidentCardClick = (buildingResidentUuid) => {
    this.props.history.push(`/user/${buildingResidentUuid}`);
  }

  handleActiveUserSwitch = () => {
    this.setState({ activeUsersOn: !this.state.activeUsersOn });
    this.props.resetState();
  }

  handleNewUserSwitch = () => {
    this.setState({ newUsersOn: !this.state.newUsersOn });
  }

  buildingResidentSlider = () => {
    if (this.state.activeUsersOn || this.state.newUsersOn) {
      return this.findUsers();
    }
    return this.props.buildingResidents;
  }

  render() {
    this.buildingResidentSlider();
    return (
      <div className="building-resident-directory-in-building-detail">
        <RenderBuildingResidentDirectory
          buildingResidents={this.buildingResidentSlider()}
          buildingUuid={this.props.buildingUuid}
          loading={this.props.buildingResidentsLoading}
          handleResidentCardClick={this.handleResidentCardClick}
          residentCSV={this.props.residentCSV}
          buildingName={this.props.building.name}
          handleActiveUserSwitch={this.handleActiveUserSwitch}
          handleNewUserSwitch={this.handleNewUserSwitch}
          getUserCSV={this.getUserCSV}
          activeUsersOn={this.state.activeUsersOn}
          newUsersOn={this.state.newUsersOn}
          history={this.props.history}
          getEntrataData={getEntrataDataScript}
          getResidentScoreCSV={this.getResidentScoreCSV}
          residentScoreCSV = {this.props.residentScoreCSV}
          token={this.props.token}
          role = {this.props.role}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ buildingResidents, buildings, auth }) => ({
  buildingResidentsLoading: buildingResidents.loading,
  buildingResidents: buildingResidents.buildingResidents,
  residentCSV: buildings.csv,
  residentScoreCSV: buildings.residentScoreCSV,
  token: auth.token,
  role: auth.role
});

export default connect(mapStateToProps, {
  getBuildingResidents,
  getBuildingResident,
  downloadResidents,
  downloadResidentsWithScore,
  resetState,
})(BuildingResidentContainer);
