import React from 'react';
import _ from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import RedeemIcon from '@material-ui/icons/Redeem';
import LinkIcon from '@material-ui/icons/Link';

import './MerchantDetail.css';

const MerchantDetail = ({ perks, onPerkClick }) => {
  const linkIcon = <ListItemIcon><LinkIcon /></ListItemIcon>;
  const renderPerkList = _.map(perks, perk => (
    <ListItem
      button
      key={perk.uuid}
      onClick={() => onPerkClick(perk)}
    >
      <ListItemIcon>
        <RedeemIcon />
      </ListItemIcon>
      <ListItemText
        primary={perk.name}
        secondary={perk.description}
      />
      {perk.redeemLink ? linkIcon : null}
    </ListItem>
  ));

  return (
    <List className="merchant-perks-container">
      {Object.keys(perks).length ?
        renderPerkList :
        <p className="merchant-perks-message">No perks found.</p>
      }
    </List>
  );
};

export default MerchantDetail;
