import axios from 'axios';
import bugsnag from 'bugsnag-js';
import { Parser } from 'json2csv';
import Cookies from 'universal-cookie';

import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHED_FEEDBACK = 'FETCHED_FEEDBACK';
export const CREATED_EVENTFEEDBACK_CSV = 'CREATED_EVENFEEDBACK_CSV';

export const getFeedback = eventIds => async (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  const params = {
    where: {
      post: eventIds,
      completed: true,
    },
    limit: 100000,
  };

  try {
    const eventfeedback = await axios({
      url: `${ROOT_URL}/eventfeedback?where=${JSON.stringify(params.where)}&limit=${params.limit}`,
      method: 'GET',
      headers: { token },
    });

    dispatch({
      type: FETCHED_FEEDBACK,
      payload: eventfeedback.data,
    });

    const fields = ['user.firstName', 'user.lastName', 'user.email', 'post.title', 'generalRating', 'createdAt'];
    const parser = new Parser({ fields });
    const csv = parser.parse(eventfeedback.data);

    return dispatch({
      type: CREATED_EVENTFEEDBACK_CSV,
      payload: csv,
    });
  } catch (error) {
    return bugsnagClient.notify(error);
  }
};
