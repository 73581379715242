/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './CreateBuildingUserForm.css';
import { roles } from '../../constants';

const BuildingUserSchema = Yup.object().shape({
  building: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  emails: Yup.array()
    .of(Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid Email',
      )
      .required('Empty email fields not allowed')),
});

const CreateBuildingUserForm = ({ buildings, onBuildingUserSubmit, constrainRoles }) => {
  const buildingArr = Object.values(buildings);
  return (
    <div>
      <h3>Create other Admins</h3>
      <Formik
        initialValues={{ building: '', role: '', emails: [''] }}
        validationSchema={BuildingUserSchema}
        onSubmit={onBuildingUserSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} id="building-user-form">
            <div className="half-form-width">
              <label className="select-label" htmlFor="admin-building-select">Building</label>
              <select
                id="admin-building-select"
                name="building"
                value={values.building}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue('role', '');
                }}
              >
                <option value="" disabled>Select Building</option>
                {buildingArr.map((building) => (
                  <option key={building.uuid} value={building.uuid}>
                    {building.name}
                  </option>
                ))}
              </select>
              <ErrorMessage name="building" render={(msg) => <div className="error-message-label">{msg}</div>} />
            </div>
            <div className="half-form-width">
              <label className="select-label" htmlFor="admin-role-select">Role</label>
              <select
                id="admin-role-select"
                name="role"
                value={values.role}
                onChange={handleChange}
                disabled={!values.building}
              >
                <option value="" disabled>Role</option>
                {values.building && !constrainRoles
                  && (
                    <>
                      <option key={0} value="VCM">VCM</option>
                      <option key={1} value="PM">PM</option>
                      {buildings[values.building].role === roles.SUPERADMIN && <option key={2} value="SUPERADMIN">SUPERADMIN</option>}
                    </>
                  )}
                {values.building && constrainRoles
                  && (
                  <option key={0} value="PM">
                    PM
                  </option>
                  )}
              </select>
              <ErrorMessage name="role" render={(msg) => <div className="error-message-label">{msg}</div>} />
            </div>
            <FieldArray
              name="emails"
              render={(arrayHelpers) => (
                <div id="building-user-emails-container">
                  {values.emails.map((email, index) => (
                    <div className="building-user-email" key={index}>
                      <div>
                        <TextField
                          name={`emails.${index}`}
                          label="Email "
                          onChange={handleChange}
                          value={email}
                          error={
                            errors.emails
                            && errors.emails[index]
                            && touched.emails
                            && touched.emails[index]
}
                          fullWidth
                        />
                        <ErrorMessage name={`emails.${index}`} render={(msg) => <div className="error-message-label">{msg}</div>} />
                      </div>
                      <Button
                        onClick={() => arrayHelpers.remove(index)}
                        disabled={values.emails.length <= 1}
                      >
                        <RemoveIcon />
                      </Button>
                      <Button
                        onClick={() => arrayHelpers.insert(index + 1, '')}
                      >
                        <AddIcon />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateBuildingUserForm;
