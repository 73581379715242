import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

import { logout } from '../../actions/AuthActions';
import BuildingListContainer from '../BuildingListContainer/BuildingListContainer';
import BuildingDetailContainer from '../BuildingDetailContainer/BuildingDetailContainer';
import UploadCSVContainer from '../UploadCSVContainer/UploadCSVContainer';
import EventDetailContainer from '../EventDetailContainer/EventDetailContainer';
import CreateEventContainer from '../CreateEventContainer/CreateEventContainer';
import UserDetailContainer from '../UserDetailContainer/UserDetailContainer';
import MerchantListContainer from '../MerchantListContainer/MerchantListContainer';
import MerchantDetailContainer from '../MerchantDetailContainer/MerchantDetailContainer';
import BuildingPerkContainer from '../BuildingPerkContainer/BuildingPerkContainer';
import CreateBuildingPerkContainer from '../CreateBuildingPerkContainer/CreateBuildingPerkContainer';
import NavigationDrawer from '../../components/NavigationDrawer/NavigationDrawer';
import CreateBuildingContainer from '../CreateBuildingContainer/CreateBuildingContainer';
import NoteDetailContainer from '../NoteDetailContainer/NoteDetailContainer';
import EventFeedbackContainer from '../EventFeedbackContainer/EventFeedbackContainer';
import GroupsContainer from '../GroupsContainer/GroupsContainer';
import SingleGroupContainer from '../GroupsContainer/SingleGroupContainer';
import GroupEventsContainer from '../GroupEventsContainer/GroupEventsContainer';
import AdminsTableContainer from '../AdminsTable/AdminsTableContainer';


class ProtectedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    });
  }

  goToBuildings = () => {
    this.toggleDrawer();
    this.props.history.push('/building');
  };

  goToMerchants = () => {
    this.toggleDrawer();
    this.props.history.push('/merchants');
  };

  goToPerks = () => {
    this.toggleDrawer();
    this.props.history.push('/perks');
  };

  goToGroups = () => {
    this.toggleDrawer();
    this.props.history.push('/groups');
  };

  goToGroupEvents = () => {
    this.toggleDrawer();
    this.props.history.push('/groupevents');
  }

  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <MenuIcon onClick={this.toggleDrawer} className="nav-menu-icon" />
          </Toolbar>
        </AppBar>
        <NavigationDrawer
          goToPerks={this.goToPerks}
          goToMerchants={this.goToMerchants}
          goToBuildings={this.goToBuildings}
          goToGroups={this.goToGroups}
          goToGroupEvents={this.goToGroupEvents}
          toggleDrawer={this.toggleDrawer}
          drawerOpen={this.state.drawerOpen}
          logout={this.props.logout}
        />
        <Switch>
          <Route exact path="/building/new" component={CreateBuildingContainer} />
          <Route exact path="/building/:buildingUuid" component={BuildingDetailContainer} />
          <Route exact path="/building/:buildingUuid/new" component={CreateEventContainer} />
          <Route exact path="/building/:buildingUuid/event/:eventUuid" component={EventDetailContainer} />
          <Route exact path="/building/:buildingUuid/event/:eventUuid/feedback" component={EventFeedbackContainer} />
          <Route exact path="/building/:buildingUuid/note/:noteUuid" component={NoteDetailContainer} />
          <Route exact path="/building/:buildingUuid/uploadCSV" component={UploadCSVContainer} />
          <Route exact path="/user/:userUuid" component={UserDetailContainer} />
          <Route exact path="/merchants" component={MerchantListContainer} />
          <Route exact path="/merchants/:merchantUuid" component={MerchantDetailContainer} />
          <Route exact path="/perks" component={BuildingPerkContainer} />
          <Route exact path="/perks/buildingperk/create" component={CreateBuildingPerkContainer} />
          <Route exact path="/groups" component={GroupsContainer} />
          <Route exact path="/groups/:buildingUuid" component={SingleGroupContainer} />
          <Route exact path="/groupevents/:buildingUuid" component={GroupEventsContainer} />
          <Route path="/building" component={BuildingListContainer} />
          <Route path="/admins" component={AdminsTableContainer} />
          <Redirect to="/building" />
        </Switch>
      </div>
    );
  }
}

export default connect(null, { logout })(ProtectedContainer);
