import React from 'react';
import { Grid, Button } from '@material-ui/core';

const DeleteGroup = ({ groupSelected, handleDeleteSubmit }) => {
  const {
    building,
    description,
    name,
    meta: { groupMemberCount, upcomingGroupEventCount }
  } = groupSelected;
  const getMetaClass = (count) => `detail-label${count ? " warning-text" : ""}`;
  return (
    <div className="group-modal-content">
      <h3>Reject Group</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <div className="detail-content-container">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <p className="detail-label">Name</p>
              </Grid>
              <Grid item xs={9}>
                <p className="detail-label">
                  {name}
                </p>
              </Grid>
              <Grid item xs={3}>
                <p className="detail-label">Building</p>
              </Grid>
              <Grid item xs={9}>
                <p>{building.name}</p>
              </Grid>
              <Grid item sm={3} xs={12}>
                <p className="detail-label">Description</p>
              </Grid>
              <Grid item sm={9} xs={12}>
                <p>{description}</p>
              </Grid>
              <Grid item xs={12}>
                <p className={getMetaClass(groupMemberCount)}>
                  {`Group Members: ${groupMemberCount}`}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className={getMetaClass(upcomingGroupEventCount)}>
                  {`Upcoming Gatherings: ${upcomingGroupEventCount}`}
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        onClick={() => handleDeleteSubmit(groupSelected)}
      >
        Delete
      </Button>
    </div>
  );
};

export default DeleteGroup;
