import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPerks } from '../../actions/perkActions';
import { getBuildings } from '../../actions/BuildingActions';
import { getMerchants } from '../../actions/MerchantActions';
import { createBuildingPerk } from '../../actions/BuildingPerkActions';
import CreateBuildingPerkForm from '../../components/CreateBuildingPerkForm/CreateBuildingPerkForm';

class CreateBuildingPerkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingDropDownSelection: '',
      merchantDropDownSelection: '',
      perkDropDownSelection: '',
      activeStep: 0,
    };
    this.dropDownHandleChange = this.dropDownHandleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  componentDidMount() {
    this.props.getBuildings();
    this.props.getPerks();
    this.props.getMerchants();
  }

  dropDownHandleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleNext = () => {
    const {
      activeStep,
      merchantDropDownSelection,
      perkDropDownSelection,
      buildingDropDownSelection,
    } = this.state;

    if (merchantDropDownSelection !== '') {
      this.props.getPerks(merchantDropDownSelection);
    }
    if (activeStep === 2) {
      this.props.createBuildingPerk(perkDropDownSelection, buildingDropDownSelection);
    }
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      buildingDropDownSelection: '',
      merchantDropDownSelection: '',
      perkDropDownSelection: '',
    });
  };

  render() {
    return (
      <CreateBuildingPerkForm
        buildings={this.props.buildings}
        merchants={this.props.merchants}
        perks={this.props.perks}
        handleChange={this.dropDownHandleChange}
        state={this.state}
        handleNext={this.handleNext}
        handleBack={this.handleBack}
        handleReset={this.handleReset}
      />
    );
  }
}

const mapStateToProps = ({ perks, buildings, merchants }) => ({
  perks: perks.perks,
  perksLoading: perks.loading,
  buildings: buildings.buildings,
  buildingsLoading: buildings.loading,
  merchantsLoading: merchants.loading,
  merchants: merchants.merchants,
});

export default connect(mapStateToProps, {
  getPerks,
  getBuildings,
  getMerchants,
  createBuildingPerk,
})(CreateBuildingPerkContainer);
