import axios from 'axios';
import { push } from 'connected-react-router';
import Cookies from "universal-cookie";
import bugsnag from 'bugsnag-js';
import { ROOT_URL } from '../config';
import { getPerks } from './perkActions';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const SET_MERCHANT_LOADING = 'SET_MERCHANT_LOADING';
export const MERCHANTS_FETCHED = 'MERCHANTS_FETCHED';
export const MERCHANT_FETCHED = 'MERCHANT_FETCHED';
export const OPEN_MERCHANT_SNACKBAR = 'OPEN_MERCHANT_SNACKBAR';

export const getMerchants = (setInitialLoading = true) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: SET_MERCHANT_LOADING,
    payload: setInitialLoading,
  });
  axios({
    method: 'GET',
    url: `${ROOT_URL}/merchant?limit=200&sort=createdAt%20DESC`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: MERCHANTS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getMerchant = merchantUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'GET',
    url: `${ROOT_URL}/merchant/${merchantUuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: MERCHANT_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getMerchantAndPerks = merchantUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'GET',
    url: `${ROOT_URL}/merchant/${merchantUuid}`,
    headers: { token },
  })
    .then((merchantResponse) => {
      dispatch({
        type: MERCHANT_FETCHED,
        payload: merchantResponse.data,
      });

      dispatch(getPerks(merchantUuid));
      dispatch({
        type: SET_MERCHANT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const createMerchant = newMerchant => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/merchant`,
    method: 'POST',
    headers: { token },
    data: newMerchant,
  })
    .then(() => {
      dispatch(getMerchants(false));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_MERCHANT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: `Unable to create this merchant. ${error.response.data.message || ''}`,
        },
      });
    });
};

export const updateMerchant = (merchant, uuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: `${ROOT_URL}/merchant/${uuid}`,
    method: 'PATCH',
    headers: { token },
    data: merchant,
  })
    .then(() => {
      dispatch(push('/merchants'));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_MERCHANT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: `Unable to update this merchant. ${error.response.data.message || ''}`,
        },
      });
    });
};
