import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';

import './RenderCheckInRsvp.css';

const RenderCheckInRsvp = ({ CheckInsOrRsvps }) => _.map(CheckInsOrRsvps, (CheckInOrRsvp) => {
  const { user, totalAttendees } = CheckInOrRsvp;
  const fullName = `${user.firstName} ${user.lastName}`;
  const guests = totalAttendees - 1 ? `+${totalAttendees - 1}` : '';

  return (
    <Chip
      className="rsvp-checkin-chip"
      key={CheckInOrRsvp.uuid}
      avatar={<Avatar src={user.profileImage} />}
      label={`${fullName} ${guests}`}
    />
  );
});


export default RenderCheckInRsvp;
