import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateReviewUrlBuilding } from '../../actions/BuildingActions';
import ReviewUrlForm from '../../components/ReviewUrlForm/ReviewUrlForm';

const ReviewUrlSchema = Yup.object().shape({
  reviewUrl: Yup.string().url().required(),
});

class ReviewUrlFormContainer extends Component {
  handleSubmitPress = (values) => {
    const { building, closeModal } = this.props;
    if (values.reviewUrl) {
      building.reviewUrl = values.reviewUrl;
      this.props.updateReviewUrlBuilding(building);
    }
    closeModal();
  };

  render() {
    const { building, closeModal } = this.props;
    let initialValues; let
      initialTouched;
    let formType;
    if (building.reviewUrl) {
      formType = 'update';
      const { reviewUrl } = building;
      initialValues = {
        reviewUrl,
      };
      initialTouched = {
        reviewUrl: true,
      };
    } else {
      formType = 'create';
      initialValues = {
        reviewUrl: '',
      };
    }

    return (
      <div>
        <h3>
          {building.reviewUrl
            ? `Update review url for ${building.name}`
            : `Create review url for building ${building.name}`}
        </h3>
        <Formik
          initialValues={initialValues}
          initialTouched={initialTouched}
          validateOnMount={!!building.reviewUrl}
          validationSchema={ReviewUrlSchema}
          onSubmit={(values) => this.handleSubmitPress(values, formType)}
        >
          {(formikProps) => (
            <ReviewUrlForm
              {...formikProps}
              closeForm={closeModal}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { buildings, groups } = state;
  return {
    buildings: buildings.buildings,
    groups: groups.groups,
  };
};

export default connect(mapStateToProps, {
  updateReviewUrlBuilding,
})(ReviewUrlFormContainer);
