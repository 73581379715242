import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { getGroups } from '../../actions/GroupActions';
import './ScheduleCommentModal.css';
import { createGroupEventComment } from '../../actions/GroupEventCommentActions';
import {
  createGroupComment,
  updateScheduledGroupComment,
} from '../../actions/GroupCommentActions';
import ScheduleCommentForm from '../ScheduleCommentForm/ScheduleCommentForm';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'string'];
const ScheduleCommentSchema = Yup.object().shape({
  building: Yup.string().required('Required'),
  group: Yup.string().nullable(true),
  creatorEmail: Yup.string().email('Invalid email').required('Required'),
  postTime: Yup.date()
    .min(moment(), 'This date has already occured')
    .max(moment().add(1, 'y'), 'This date is too far in the future'),
});

class ScheduleCommentModal extends Component {
  handleCreateComment = (values) => {
    const {
      closeForm,
      createGroupEventComment,
      createGroupComment,
      updateScheduledGroupComment,
    } = this.props;
    const data = new FormData();
    data.append('building', values.building);
    data.append('creatorEmail', values.creatorEmail);
    data.append('group', values.group);
    if (values.groupEvent) data.append('groupEvent', values.groupEvent);
    if (values.contents) data.append('contents', values.contents.trim());
    if (values.postTime) {
      const selectedBuilding = Object.values(this.props.buildings).filter(
        (b) => b.uuid === values.building,
      )[0];
      data.append(
        'postTime',
        this.formatTime(selectedBuilding, values.postTime, 'formData'),
      );
    }
    if (
      !_.isEmpty(values.image)
      && SUPPORTED_FORMATS.includes(values.image.type)
    ) {
      data.append('images', values.image);
    } else if (
      !_.isEmpty(values.image)
      && !SUPPORTED_FORMATS.includes(values.image.type)
    ) {
      alert('Unsupported image format');
      return;
    }

    if (this.props.targetComment) {
      data.append('uuid', this.props.targetComment.uuid);
      updateScheduledGroupComment(data, this.props.targetComment.uuid);
    } else if (values.groupEvent) createGroupEventComment(data, !!values.postTime);
    else createGroupComment(data, !!values.postTime);

    closeForm();
  };

  formatTime(building, startTime, formatType) {
    if (formatType === 'initialValue') {
      return momentTimezone
        .tz(startTime, building.timezone)
        .format('YYYY-MM-DDTHH:mm');
    }
    if (formatType === 'formData') {
      return moment.tz(startTime, building.timezone).format();
    }
    return startTime;
  }

  render() {
    const {
      loading,
      buildings,
      groups,
      groupEvents,
      getGroups,
      targetComment,
    } = this.props;
    if (loading) {
      return <CircularProgress color="primary" />;
    }

    let initialValues;
    if (!targetComment) {
      initialValues = {
        building: '',
        group: '',
        groupEvent: '',
        creatorEmail: '',
        postTime: '',
        contents: '',
        image: {},
      };
    } else {
      const {
        building, group, user, groupEvent, postTime, contents, images,
      } = targetComment;
      initialValues = {
        building: building.uuid,
        group,
        groupEvent,
        creatorEmail: user.email,
        postTime: this.formatTime(building, postTime, 'initialValue'),
        contents,
        image: images.length
          ? { preview: images[0].image, type: 'string' }
          : null,
      };
    }

    return (
      <div className="create-group-event-container group-modal-content">
        <h3>
          {this.props.targetComment
            ? 'Edit Scheduled Comment'
            : 'Create or Schedule a Comment'}
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={ScheduleCommentSchema}
          onSubmit={(values) => this.handleCreateComment(values)}
        >
          {(formikProps) => (
            <ScheduleCommentForm
              {...formikProps}
              editMode={this.props.targetComment}
              selectOptions={{ buildings, groups, groupEvents }}
              getGroups={getGroups}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { buildings, groups, groupEvents } = state;
  return {
    buildings: buildings.buildings,
    groups: groups.groups,
    groupEvents: groupEvents.groupEvents,
  };
};

export default connect(mapStateToProps, {
  getGroups,
  createGroupEventComment,
  createGroupComment,
  updateScheduledGroupComment,
})(ScheduleCommentModal);
