import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Field } from 'formik';

import './CreateBuildingForm.css';
import ImageDropzone from '../ImageDropzone/ImageDropzone';

const CreateBuildingForm = ({
  values, errors, touched,
  setFieldValue, handleSubmit,
  setFieldTouched, onboard, toggleOnboard
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value, true);
  };

  return (
    <form id="CreateBuildingForm" onSubmit={handleSubmit}>
      <TextField
        name="name"
        className="BuildingName"
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
        label="Building Name"
        onChange={handleChange}
        onBlur={() => setFieldTouched('name', true, false)}
        value={values.name}
        fullWidth
      />
      <div className="SpacingDiv" />
      <TextField
        name="shortName"
        helperText={touched.shortName ? errors.shortName : ''}
        error={touched.shortName && Boolean(errors.shortName)}
        label="Display Short Name"
        onChange={handleChange}
        onBlur={() => setFieldTouched('shortName', true, false)}
        value={values.shortName}
        fullWidth
      />
      <div className="SpacingDiv" />
      <div className="timezone-vcm-container">
        <label htmlFor="timeZone" className="timeZone">
          Time Zone
          <br />
          <select
            name="timeZone"
            className="timeZonesSelect"
            value={values.timeZone}
            onChange={handleChange}
          >
            <option label="Eastern" value="America/New_York">
              America/New_York
            </option>
            <option label="Central" value="America/Chicago">
              America/Chicago
            </option>
            <option label="Mountain" value="America/Denver">
              America/Denver
            </option>
            <option label="Pacific" value="America/Los_Angeles">
              America/Los_Angeles
            </option>
            <option label="Alaska" value="America/Anchorage">
              America/Anchorage
            </option>
            <option label="Hawaii–Aleutian" value="Pacific/Honolulu">
              Pacific/Honolulu
            </option>
            <option label="Arizona" value="America/Phoenix">
              America/Phoenix
            </option>
          </select>
        </label>
        <div className="SpacingDiv" />
        <label htmlFor="timeZone" className="timeZone">
          VCM
          <br />
          <select
            name="VCM"
            className="timeZonesSelect"
            value={values.VCM}
            onChange={handleChange}
          >
            {Array.from({ length: 10 }, (v, k) => k + 1).map((num) => (
              <option label={num} value={num}>
                {num}
              </option>
            )) }
          </select>
        </label>
      </div>

      <div>
        <p>Onboarding</p>
        <div>
        <input type="checkbox" onClick={() => toggleOnboard()} />
        <span>Include Onboarding Raffle</span>
        </div>
        {onboard
        && (
        <div>
          <TextField
            name="raffleDescription"
            error={touched.name && Boolean(errors.raffleDescription)}
            helperText={touched.raffleDescription ? errors.raffleDescription : ''}
            className="BuildingName"
            label="Raffle description"
            onChange={handleChange}
            onBlur={() => setFieldTouched('name', true, false)}
            value={values.raffleDescription}
            fullWidth
          />
          <div className="SpacingDiv" />
          <TextField
            id="raffleEndDate"
            name="raffleEndDate"
            value={values.raffleEndDate}
            label="End date of raffle text"
            type="date"
            helperText={touched.raffleEndDate ? errors.raffleEndDate : ''}
            error={Boolean(errors.raffleEndDate) && (touched.raffleEndDate)}
            onChange={handleChange}
            onBlur={() => setFieldTouched('raffleEndDate', true, false)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </div>
        )}
        <div>
          <Field type="checkbox" name="skippable" />
          <span>Enable Skip button in onboarding</span>
        </div>
      </div>
      <Field
        name="image"
        component={(formikProps) => <ImageDropzone {...formikProps} />}
      />
      <br />
      <Button className="create-building-submit-button" variant="contained" color="primary" type="submit">Submit </Button>
    </form>

  );
};

export default CreateBuildingForm;
