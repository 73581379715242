import {
  FETCHING_BUILDING_CONFIG,
  BUILDING_CONFIG_FETCHED,
} from '../actions/BuildingConfigActions';

const initialState = {
  buildingConfig: {},
  hidePerks: false,
  showGoogleReview: false,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BUILDING_CONFIG:
      return { ...state, loading: true };
    case BUILDING_CONFIG_FETCHED:
      if(action.payload.config) {
        return {
          ...state,
          buildingConfig: action.payload,
          hidePerks: Object.values(action.payload.config)[0]['hidePerks'],
          showGoogleReview: Object.values(action.payload.config)[0]['showGoogleReview'],
          loading: false
          };
      }
      return {
        ...state,
        loading: false
        };
    default:
      return state;
  }
};
