import { push } from 'connected-react-router';
import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from 'universal-cookie';
import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHING_BUILDINGS = 'FETCHING_BUILDINGS';
export const BUILDINGS_FETCHED = 'BUILDINGS_FETCHED';
export const FETCHING_BUILDING = 'FETCHING_BUILDING';
export const BUILDING_FETCHED = 'BUILDING_FETCHED';
export const CREATING_BUILDING = 'CREATING_BUILDING';
export const UPDATING_BUILDING = 'UPDATING_BUILDING';
export const UPDATED_BUILDING = 'UPDATED_BUILDING';
export const CREATING_CODE = 'CREATING_CODE';
export const CREATED_CODE = 'CREATED_CODE';
export const CHANGING_CODE_STATUS = 'CHANGING_CODE_STATUS';
export const CODE_STATUS_CHANGED = 'CODE_STATUS_CHANGED';
export const CODE_DELETED = 'CODE_DELETED';
export const OPEN_BUILDING_SNACKBAR = 'OPEN_BUILDING_SNACKBAR';
export const BUILDING_CSV_DOWNLOADING = 'BUILDING_CSV_DOWNLOADING';
export const BUILDING_CSV_DOWNLOADED = 'BUILDING_CSV_DOWNLOADED';
export const RESET_CSV = 'RESET_CSV';
export const ACTIVE_STATUS_CHANGE = 'ACTIVE_STATUS_CHANGE';
export const GET_ADMINS = 'GET_ADMINS';
export const ADMINS_FETCHED = 'ADMINS_FETCHED';
export const UPDATE_ADMIN_ROLE = 'UPDATE_ADMIN_ROLE';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const REVIEW_URL_BUILDING_CHANGE = 'REVIEW_URL_BUILDING_CHANGE';
export const RESIDENT_SCORE_CSV_DOWNLOADING = 'RESIDENT_SCORE_CSV_DOWNLOADING';
export const RESIDENT_SCORE_CSV_DOWNLOADED = 'RESIDENT_SCORE_CSV_DOWNLOADED';



export const getBuildings = () => (dispatch) => {
  const cookies = new Cookies();
  dispatch({
    type: FETCHING_BUILDINGS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/user/buildings`,
    headers: { token: cookies.get('token') },
  })
    .then((response) => {
      dispatch({
        type: BUILDINGS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getBuildingsSimple = () => (dispatch) => {
  const cookies = new Cookies();
  dispatch({
    type: FETCHING_BUILDINGS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/v2/user/buildings`,
    headers: { token: cookies.get('token') },
  })
    .then((response) => {
      dispatch({
        type: BUILDINGS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};



export const createBuilding = (newBuilding) => (dispatch) => {
  const cookies = new Cookies();

  dispatch({
    type: CREATING_BUILDING,
  });
  axios({
    url: `${ROOT_URL}/building`,
    method: 'POST',
    headers: { token: cookies.get('token') },
    data: newBuilding,
  })
    .then((response) => {
      dispatch({
        type: BUILDING_FETCHED,
        payload: response.data,
      });
      dispatch(push('/building/'));
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_BUILDING_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.response.data.message,
        },
      });
    });
};
export const resetState = () => (dispatch) => {
  const cookies = new Cookies();

  dispatch({
    type: RESET_CSV,
  });
};
export const getBuilding = (buildingUuid) => (dispatch) => {
  const cookies = new Cookies();

  dispatch({
    type: FETCHING_BUILDING,
  });
  axios({
    method: 'GET',
    url: `${ROOT_URL}/v2/user/building/${buildingUuid}`,
    headers: { token: cookies.get('token') },
  })
    .then((response) => {
      dispatch({
        type: BUILDING_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const downloadResidents = (buildingUuid, endDate, filterDateObj) => (
  dispatch,
) => {
  const data = {};
  const cookies = new Cookies();

  if (filterDateObj) {
    if (filterDateObj.createDate) {
      data.createDate = filterDateObj.createDate;
    }
    if (filterDateObj.lastActiveDate) {
      data.lastActiveDate = filterDateObj.lastActiveDate;
    }
  }
  data.endDate = endDate;
  dispatch({
    type: BUILDING_CSV_DOWNLOADING,
  });
  axios({
    url: `${ROOT_URL}/building/${buildingUuid}/residents/csv`,
    method: 'POST',
    headers: { token: cookies.get('token') },
    data,
  })
    .then((response) => {
      dispatch({
        type: BUILDING_CSV_DOWNLOADED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const downloadResidentsWithScore = (buildingUuid) => (
  dispatch,
) => {
  const cookies = new Cookies();
  dispatch({
    type: RESIDENT_SCORE_CSV_DOWNLOADING,
  });
  axios({
    url: `${ROOT_URL}/building/${buildingUuid}/residents-score/csv`,
    method: 'POST',
    headers: { token: cookies.get('token') }
  })
    .then((response) => {
      dispatch({
        type: RESIDENT_SCORE_CSV_DOWNLOADED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateActiveBuilding = (building) => (dispatch) => {
  const data = { active: !building.active };
  const cookies = new Cookies();

  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/building/${building.uuid}`,
    headers: { token: cookies.get('token') },
    data,
  })
    .then((response) => {
      dispatch({
        type: ACTIVE_STATUS_CHANGE,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const getAdmins = () => (dispatch) => {
  dispatch({
    type: GET_ADMINS,
  });

  const cookies = new Cookies();

  axios({
    method: 'GET',
    url: `${ROOT_URL}/buildinguser/all`,
    headers: { token: cookies.get('token') },
  })
    .then((response) => {
      dispatch({
        type: ADMINS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const createBuildingUsers = (data) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    method: 'POST',
    url: `${ROOT_URL}/buildinguser/emails`,
    headers: { token: cookies.get('token') },
    data,
  })
    .then((response) => {
      dispatch({
        type: OPEN_BUILDING_SNACKBAR,
        payload: {
          errorMessage: response.data.errorMessage,
          message: response.data.message,
        },
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const createVerifiedUser = (values) => () => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: `${ROOT_URL}/user/verified/${values.building}`,
    method: 'POST',
    headers: {token},
    data: values,
  })
    .then(() => alert('User successfully created and verified.'))
    .catch((error) => {
      alert('User with that email already exists');
      bugsnagClient.notify(error);
    });
};

export const updateBuildingCode = (codeId) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    url: `${ROOT_URL}/buildingcode/${codeId}`,
    method: 'PATCH',
    headers: { token: cookies.get('token') },
  })
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {
      alert(
        'There was an error while generating a new code. Please try again later.',
      );
      bugsnagClient.notify(err);
    });
};

export const changeAdminRole = (adminId, role) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    url: `${ROOT_URL}/buildinguser/${adminId}`,
    method: 'PATCH',
    headers: { token: cookies.get('token') },
    data: { role },
  })
    .then((res) => {
      dispatch({
        type: UPDATE_ADMIN_ROLE,
        payload: {
          adminId: res.data[0].uuid,
          role,
        },
      });
    })
    .catch((err) => {
      alert(
        'There was an error while updating this admin. Please try again later.',
      );
      bugsnagClient.notify(err);
    });
};

export const deleteAdmin = (adminId) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    url: `${ROOT_URL}/buildinguser/${adminId}`,
    method: 'DELETE',
    headers: { token: cookies.get('token') },
  })
    .then(() => {
      dispatch({
        type: DELETE_ADMIN,
        payload: {
          adminId,
        },
      });
    })
    .catch((err) => {
      alert(
        `There was an error while deleting this admin. Please try again later. Error: ${err}`,
      );
      bugsnagClient.notify(err);
    });
};

export const updateBuilding = (id, formData) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    url: `${ROOT_URL}/building/${id}`,
    method: 'PATCH',
    headers: { token: cookies.get('token') },
    data: formData,
  })
    .then((response) => {
      dispatch({
        type: UPDATED_BUILDING,
        payload: response.data,
      });
    })
    .catch((err) => {
      alert(
        'There was an error while updating a building. Please try again later.',
      );
      bugsnagClient.notify(err);
    });
};

export const updateVCM = (id, VCM) => (dispatch) => {
  const cookies = new Cookies();

  axios({
    url: `${ROOT_URL}/building/${id}/vcm`,
    method: 'PATCH',
    headers: { token: cookies.get('token') },
    data: { VCM },
  })
    .then((response) => {
      dispatch({
        type: UPDATED_BUILDING,
        payload: response.data,
      });
    })
    .catch((err) => {
      alert(
        'There was an error while changing building\'s VCM. Please try again later.',
      );
      bugsnagClient.notify(err);
    });
};

export const updateReviewUrlBuilding = (building) => (dispatch) => {
  const cookies = new Cookies();

  const data = { reviewUrl: building.reviewUrl };
  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/building/${building.uuid}`,
    headers: { token: cookies.get('token') },
    data,
  })
    .then((response) => {
      dispatch({
        type: REVIEW_URL_BUILDING_CHANGE,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};
