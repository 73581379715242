import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import CSVDropzone from '../CSVDropzone/CSVDropzone';

import { uploadResidentCSV, clearUploadData } from '../../actions/BuildingResidentActions';
import { getBuilding } from '../../actions/BuildingActions';
import Snackbar from '../../components/Snackbar/Snackbar';
import './UploadCSVContainer.css';

class UploadCSVContainer extends Component {
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.displayUploadData = this.displayUploadData.bind(this);
  }

  componentDidMount() {
    this.props.clearUploadData();

    if (!this.props.buildings[this.props.match.params.buildingUuid]) {
      this.props.getBuilding(this.props.match.params.buildingUuid);
    }
  }

  handlePress() {
    this.props.history.push(`/building/${this.props.match.params.buildingUuid}`);
  }

  handleDrop(csvFile) {
    this.props.uploadResidentCSV(csvFile, this.props.match.params.buildingUuid);
  }

  displayUploadData() {
    const headCell = (text) => {
      if (text === 'not added to the database') {
        return (<TableCell>Reason</TableCell>);
      }
      return null;
    };

    const tableCell = (user) => {
      if (user.rejectionReason) {
        return (<TableCell>{user.rejectionReason}</TableCell>);
      }
      return null;
    };

    const createUsersTable = (userArray, text) => {
      if (userArray.length === 0) {
        return (<div />);
      }

      return (
        <section className="table-section">
          <p>
The following users were
            {text}
:
          </p>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email Address</TableCell>
                  <TableCell>Last Name</TableCell>
                  {headCell(text)}
                </TableRow>
              </TableHead>
              <TableBody>
                {userArray.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    {tableCell(user)}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </section>
      );
    };

    if (this.props.loading) {
      return <CircularProgress className="loading" color="primary" />;
    } if (Object.keys(this.props.uploadData).length > 0) {
      return (
        <div className="results">
          <h2 className="csv-h2">{this.props.uploadData.message}</h2>
          {createUsersTable(this.props.uploadData.updatedUsers, 'updated')}
          {createUsersTable(this.props.uploadData.createdUsers, 'created')}
          {createUsersTable(this.props.uploadData.badUsers, 'not added to the database')}
        </div>
      );
    }

    const note = 'If any columns appear blank in your results, it is likely because your CSV file headers are incorrectly formatted';
    const examples = 'Examples of properly formatted headers: email -- lastName -- roomNumber';
    return (
      <div className="results">
        <p className="note">
          {note}
          <br />
          <br />
          {examples}
        </p>
      </div>
    );
  }

  render() {
    const building = this.props.buildings[this.props.match.params.buildingUuid];
    const buildingName = building ? building.name : '';

    return (
      <div className="upload-csv-container">
        <Snackbar
          snackbarOpen={this.props.snackbarOpen}
          errorMessage={this.props.errorMessage}
        />
        <Button variant="contained" color="inherit" onClick={() => this.handlePress()}>
          Back
        </Button>
        <h1 className="csv-h1">
          Upload Resident CSV for
          {' '}
          {buildingName}
        </h1>
        <CSVDropzone handleDrop={this.handleDrop} />
        {this.displayUploadData()}
      </div>
    );
  }
}

const mapStateToProps = ({ buildingResidents, buildings }) => ({
  buildings: buildings.buildings,
  uploadData: buildingResidents.uploadData,
  snackbarOpen: buildingResidents.snackbarOpen,
  errorMessage: buildingResidents.errorMessage,
  loading: buildingResidents.loading,
});

export default connect(mapStateToProps, {
  uploadResidentCSV,
  clearUploadData,
  getBuilding,
})(UploadCSVContainer);
