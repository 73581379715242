import React, { Component } from 'react';
import { connect } from 'react-redux';
import momentTimezone from 'moment-timezone';

import { getEvent, editEvent, deleteEvent, checkinsToCsv } from '../../actions/PostActions';
import { getBuildingResidents, checkInBuildingResident } from '../../actions/BuildingResidentActions';
import { getEventStructures, getInterests } from '../../actions/TagActions';
import Snackbar from '../../components/Snackbar/Snackbar';
import EventDetail from '../../components/EventDetail/EventDetail';

class EventDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editEventClicked: false,
      deleteEventModalOpen: false,
      toggleResidentCheckIn: false,
    };
    this.onEditEventButtonClick = this.onEditEventButtonClick.bind(this);
    this.onDeleteEventButtonClick = this.onDeleteEventButtonClick.bind(this);
    this.onDeleteEventModalNotConfirmed = this.onDeleteEventModalNotConfirmed.bind(this);
    this.onConfirmDeleteEventButtonClick = this.onConfirmDeleteEventButtonClick.bind(this);
    this.onCheckInResidentButtonClick = this.onCheckInResidentButtonClick.bind(this);
    this.onChangeSendPushNotificationSwitch = this.onChangeSendPushNotificationSwitch.bind(this);
    this.onChangeSendFeedbackSwitch = this.onChangeSendFeedbackSwitch.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.getEvent(match.params.eventUuid);
    this.props.getBuildingResidents(match.params.buildingUuid);
    this.props.checkinsToCsv(match.params.eventUuid);
    this.props.getEventStructures();
    this.props.getInterests();
  }

  onEditEventButtonClick() {
    const prevEditState = this.state.editEventClicked;
    this.setState({ editEventClicked: !prevEditState });
  }

  onCheckInResidentButtonClick() {
    const prevEditState = this.state.toggleResidentCheckIn;
    this.setState({ toggleResidentCheckIn: !prevEditState });
  }

  onDeleteEventButtonClick() {
    this.setState({ deleteEventModalOpen: true });
  }

  onDeleteEventModalNotConfirmed() {
    this.setState({ deleteEventModalOpen: false });
  }

  onConfirmDeleteEventButtonClick() {
    const { event } = this.props;
    this.props.deleteEvent(event);
  }

  onChangeSendPushNotificationSwitch(eventUuid, currentValue) {
    const editedEvent = new FormData();
    editedEvent.append('uuid', eventUuid);
    editedEvent.append('pushNotifyResidents', !currentValue);
    this.props.editEvent(editedEvent);
  }

  onChangeSendFeedbackSwitch(eventUuid, currentValue) {
    const editedEvent = new FormData();
    editedEvent.append('uuid', eventUuid);
    editedEvent.append('requestResidentFeedback', !currentValue);
    this.props.editEvent(editedEvent);
  }

  handleResidentCardClick = (buildingResidentUuid) => {
    this.props.checkInBuildingResident(this.props.event.uuid, buildingResidentUuid, 'checkIn');
  }

  eventEditSubmitHandler = (values) => {
    const data = new FormData();
    data.append('uuid', values.uuid);
    data.append('title', values.title);
    data.append('description', values.description);
    data.append('startTime', momentTimezone.tz(values.startTime, values.building.timezone).toDate().toUTCString());
    data.append('type', 'event');
    data.append('locationString', values.locationString);
    data.append('author', values.author.uuid);
    data.append('building', values.building.uuid);
    data.append('maxGuests', values.maxGuests);
    data.append('maxEventAttendees', values.maxEventAttendees);
    data.append('structures', JSON.stringify(values.structures));
    data.append('interests', JSON.stringify(values.interests));
    if (values.image) {
      data.append('image', values.image);
    }
    this.props.editEvent(data);
    const prevEditState = this.state.editEventClicked;
    this.setState({ editEventClicked: !prevEditState });
  }

  handleEventFeedback = () => {
    const { event } = this.props;
    this.props.history.push(`/building/${event.building.uuid}/event/${event.uuid}/feedback`);
  }

  render() {
    return (
      <div>
        <Snackbar
          snackbarOpen={this.props.snackbarOpen}
          errorMessage={this.props.errorMessage}
        />
        <EventDetail
          buildingResidents={this.props.buildingResidents}
          onConfirmDeleteEventButtonClick={this.onConfirmDeleteEventButtonClick}
          onDeleteEventModalNotConfirmed={this.onDeleteEventModalNotConfirmed}
          deleteEventModalOpen={this.state.deleteEventModalOpen}
          event={this.props.event}
          loading={this.props.loading}
          CheckInCsv={this.props.CheckInCsv}
          editEventClicked={this.state.editEventClicked}
          onEditEventButtonClick={this.onEditEventButtonClick}
          onDeleteEventButtonClick={this.onDeleteEventButtonClick}
          eventEditSubmitHandler={this.eventEditSubmitHandler}
          buildingResidentsLoading={this.props.buildingResidentsLoading}
          handleResidentCardClick={this.handleResidentCardClick}
          onCheckInResidentButtonClick={this.onCheckInResidentButtonClick}
          toggleResidentCheckIn={this.state.toggleResidentCheckIn}
          onChangeSendPushNotificationSwitch={this.onChangeSendPushNotificationSwitch}
          onChangeSendFeedbackSwitch={this.onChangeSendFeedbackSwitch}
          structures={this.props.structures}
          interests={this.props.interests}
          handleEventFeedback={this.handleEventFeedback}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ events, buildingResidents, tags }, props) => ({
  event: events.events[props.match.params.eventUuid],
  errorMessage: events.errorMessage,
  snackbarOpen: events.snackbarOpen,
  CheckInCsv: events.csv,
  buildingResidentsLoading: buildingResidents.loading,
  buildingResidents: buildingResidents.buildingResidents,
  loading: events.loading,
  structures: tags.structures,
  interests: tags.interests,
});

export default connect(mapStateToProps, {
  getEvent,
  editEvent,
  deleteEvent,
  checkinsToCsv,
  getBuildingResidents,
  checkInBuildingResident,
  getEventStructures,
  getInterests,
})(EventDetailContainer);
