import axios from "axios";
import bugsnag from "bugsnag-js";
import Cookies from "universal-cookie";

import { ROOT_URL } from "../config";

const bugsnagClient = bugsnag("8822370330acd587983bc49c6f829cd4");

export const FETCHING_BUILDING_DASHBOARD_CONFIG =
  "FETCHING_BUILDING_DASHBOARD_CONFIG";
export const BUILDING_DASHBOARD_CONFIG_FETCHED =
  "BUILDING_DASHBOARD_CONFIG_FETCHED";
export const getBuildingDashboardConfig = (buildingId) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  dispatch({
    type: FETCHING_BUILDING_DASHBOARD_CONFIG,
  });

  axios({
    method: "GET",
    url: `${ROOT_URL}/buildingdashboardconfig/${buildingId}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: BUILDING_DASHBOARD_CONFIG_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateBuildingDashboardConfig = ({
  showDashboardLocal,
  buildingId,
}) => () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  let data = { config: { showPerks: showDashboardLocal } };

  axios({
    url: `${ROOT_URL}/buildingdashboardconfig/${buildingId}`,
    method: "PATCH",
    headers: { token },
    data,
  }).catch((err) => {
    alert(
      "There was an error while updating building config. Please try again later."
    );
    bugsnagClient.notify(err);
  });
};
