import _ from 'lodash';
import {
  SET_GROUP_COMMENT_LOADING,
  GET_GROUP_COMMENTS,
  UPDATE_GROUP_COMMENT,
  UPDATE_BUILDING_CHAT_COMMENT,
  GET_BUILDING_COMMENTS,
  SET_SCHEDULED_GROUP_COMMENTS,
  DELETE_SCHEDULED_GROUP_COMMENTS,
  UPDATE_SCHEDULED_GROUP_COMMENTS,
} from '../actions/GroupCommentActions';

const initialState = {
  comments: {},
  buildingComments: {},
  scheduledComments: {},
  loading: false,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_GROUP_COMMENT_LOADING:
      return { ...state, loading: payload };
    case GET_GROUP_COMMENTS: {
      const comments = _.mapKeys(payload, 'uuid');
      return { ...state, comments };
    }
    case GET_BUILDING_COMMENTS: {
      const buildingComments = _.mapKeys(payload, 'uuid');
      return { ...state, buildingComments };
    }
    case SET_SCHEDULED_GROUP_COMMENTS: {
      const scheduledComments = payload;
      return { ...state, scheduledComments };
    }
    case DELETE_SCHEDULED_GROUP_COMMENTS: {
      return { ...state, scheduledComments: state.scheduledComments.filter((c) => c.uuid !== payload.uuid) };
    }
    case UPDATE_SCHEDULED_GROUP_COMMENTS: {
      return {
        ...state,
        scheduledComments: state.scheduledComments.map((c) => {
          if (c.uuid === payload.uuid) {
            c.contents = payload.contents;
            c.postTime = payload.postTime;
          }
          return c;
        }),
      };
    }
    case UPDATE_GROUP_COMMENT: {
      const { parent, uuid: commentId } = payload;
      const commentsCopy = { ...state.comments };

      if (parent) {
        const commentCopy = commentsCopy[parent.uuid];
        const childComments = { ...commentCopy.children };

        commentCopy.children = _.map(childComments, (child) => {
          if (child.uuid === commentId) {
            return payload;
          }
          return child;
        });
        commentsCopy[parent.uuid] = commentCopy;
      } else {
        commentsCopy[commentId] = payload;
      }
      return { ...state, comments: commentsCopy };
    }
    case UPDATE_BUILDING_CHAT_COMMENT: {
      const { parent, uuid: commentId } = payload;
      const commentsCopy = { ...state.buildingComments };

      if (parent) {
        const commentCopy = commentsCopy[parent.uuid];
        const childComments = { ...commentCopy.children };

        commentCopy.children = _.map(childComments, (child) => {
          if (child.uuid === commentId) {
            return payload;
          }
          return child;
        });
        commentsCopy[parent.uuid] = commentCopy;
      } else {
        commentsCopy[commentId] = payload;
      }
      return { ...state, buildingComments: commentsCopy };
    }
    default:
      return state;
  }
};
