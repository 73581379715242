import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Field } from 'formik';

import './LoginForm.css';

class LoginForm extends Component {
  render() {
    const {
      handleSubmit,
      handleChange,
      loading,
    } = this.props;


    if (loading) {
      return <LinearProgress mode="indeterminate" className="progress-bar" />;
    }

    return (
      <div className="login-form-container">
        <h3>Login</h3>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Field onChange={handleChange} id="email" component={TextField} label="email" type="email" required fullWidth />
          <Field onChange={handleChange} id="password" component={TextField} label="password" type="password" required fullWidth />
          <Button variant="contained" fullWidth color="primary" type="submit">
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

export default LoginForm;
