import React from 'react';
import size from 'lodash/size';
import {
  LinearProgress, Button, TableContainer, Paper,
} from '@material-ui/core';
import styled from 'styled-components';
import Table from '../Table/Table';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;

const GroupsRejected = ({
  rejectedGroups, loading, reviewRejectedGroup, deleteRejectedGroup,
}) => {
  const rejectedGroupsColumns = React.useMemo(() => [{
    Header: 'Suggesting User',
    id: 'suggestingUser',
    columns: [
      {
        Header: 'Name',
        accessor: ({ suggestingUser: user }) => `${user.firstName} ${user.lastName}`,
        id: 'name',
      },
    ],
  },
  {
    Header: 'Rejected Group',
    id: 'rejected-group',
    columns: [
      {
        Header: 'Title',
        id: 'title.title',
        accessor: 'title',
      },
      {
        Header: 'Building',
        id: 'building.name',
        accessor: 'building.name',
      },
    ],
  },
  {
    Header: 'Additional Info',
    id: 'additional',
    columns: [
      {
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: 225,
      },
      {
        Header: 'Rejection Reason',
        accessor: 'rejectionReason',
        id: 'rejectionReason',
        width: 250,
      },
      {
        Header: 'Actions',
        id: 'rejected_actions',
        width: 90,
        Cell: ({ row }) => (
          <div>
            <div>
              <Button
                color="primary"
                onClick={() => reviewRejectedGroup(row)}
              >
                REVIEW
              </Button>
            </div>
            <div>
              <Button
                color="secondary"
                onClick={() => deleteRejectedGroup(row)}
              >
                DELETE
              </Button>
            </div>
          </div>
        ),
      },
    ],
  },
  ], [reviewRejectedGroup]);

  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }

  if (!size(rejectedGroups)) {
    return null;
  }

  const arr = Object.values(rejectedGroups).map((group) => group);
  const groups = arr.filter((group) => group.suggestingUser
    && group.building && !group.approved && group.rejected);

  return (
    <TableContainer className="tableContainer" component={Paper}>
      <h2>Rejected Groups</h2>
      <Styles>
        <Table
          tableName="rejected"
          data={groups}
          totalDataCount={groups.length}
          columns={rejectedGroupsColumns}
        />
      </Styles>
    </TableContainer>
  );
};

export default GroupsRejected;
