import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import {
  getBuildings, updateActiveBuilding, createBuildingUsers, createVerifiedUser, updateBuilding, updateVCM,
} from '../../actions/BuildingActions';
import BuildingList from '../../components/BuildingList/BuildingList';
import CustomModal from '../../components/CustomModal/CustomModal';
import CreateBuildingUserForm from '../../components/CreateBuildingUserForm/CreateBuildingUserForm';
import CreateVerifiedUserForm from '../../components/CreateVerifiedUserForm/CreateVerifiedUserForm';
import Snackbar from '../../components/Snackbar/Snackbar';
import UpdateBuilding from '../../components/UpdateBuilding/UpdateBuilding';
import { roles } from '../../constants';

class BuildingListContainer extends Component {
  state = {
    displayModal: false, displayNewVerified: false, displayUpdateModal: false, buildingSelected: null,
  }

  componentDidMount() {
    const cookies = new Cookies();
    const token = cookies.get('token');

    if (!token) {
      this.props.history.push('/login');
    } else {
      this.props.getBuildings();
    }
  }

  onBuildingSelectHandler = (buildingUuid) => {
    this.props.history.push(`/building/${buildingUuid}`);
  }

  onBuildingChangeActive = (building) => {
    this.props.updateActiveBuilding(building);
  }

  onBuildingUserSubmit = (values) => {
    this.setState({ displayModal: false });
    const lowerCaseEmails = values.emails.map((email) => email.toLowerCase());
    this.props.createBuildingUsers(
      {
        ...values,
        emails: lowerCaseEmails,
      },
    );
  }


  handleUpdateModal = (row) => {
    this.setState({ buildingSelected: row });
    this.setUpdateModal(true);
  }

  onCreateVerifiedUserPress = (values) => {
    this.setState({ displayNewVerified: false });

    this.props.createVerifiedUser({
      ...values,
      email: values.email.toLowerCase(),
      verified: true,
      verifiedPhone: true,
    });
  }

  updateBuilding = (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('shortName', values.shortName);
    if (values.image && values.image.preview !== this.state.buildingSelected.image || !values.image) {
      formData.append('image', values.image);
    }
    this.props.updateBuilding(this.state.buildingSelected.uuid, formData);
    this.setUpdateModal(false);
  }

  setModalDisplay = (display) => this.setState({ displayModal: display });

  setVerifiedModalDisplay = (display) => this.setState({ displayNewVerified: display });

  handleChange = (event, id) => {
    const { value } = event.target;
    const { updateVCM } = this.props;
    if (value) updateVCM(id, value);
  }

  setUpdateModal = (display) => this.setState({ displayUpdateModal: display });

  render() {
    const {
      buildings,
      loading,
      snackbarOpen,
      errorMessage,
      message,
    } = this.props;
    const { displayModal, displayNewVerified, displayUpdateModal } = this.state;

    const buildingsArr = Object.values(buildings);
    const restrictedAdmin = buildingsArr?.find((b) => b.role === roles.PM);
    const superadmin = buildingsArr?.find((b) => b.role === roles.SUPERADMIN);
    return (
      <div>
        <BuildingList
          buildings={buildings}
          onBuildingSelectHandler={this.onBuildingSelectHandler}
          loading={loading}
          onBuildingChangeActive={this.onBuildingChangeActive}
          onCreateVerifiedUserPress={() => this.setVerifiedModalDisplay(true)}
          onAddUserPress={() => this.setModalDisplay(true)}
          forbidCreating={restrictedAdmin}
          isSuperAdmin={!!superadmin}
          handleChange={this.handleChange}
          onUpdateBuildingPress={this.handleUpdateModal}
        />
        {displayModal && (
          <CustomModal
            closeModal={() => this.setModalDisplay(false)}
            content={(
              <CreateBuildingUserForm
                onBuildingUserSubmit={this.onBuildingUserSubmit}
                buildings={buildings}
                constrainRoles={restrictedAdmin}
              />
            )}
          />
        )}
        {displayNewVerified
        && (
        <CustomModal
          closeModal={() => this.setVerifiedModalDisplay(false)}
          content={(
            <CreateVerifiedUserForm
              onCreateVerifiedUserPress={this.onCreateVerifiedUserPress}
              buildings={buildings}
              constrainRoles={restrictedAdmin}
            />
        )}
        />
        )}
        {displayUpdateModal
          && (
          <CustomModal
            closeModal={() => this.setUpdateModal(false)}
            content={(
              <UpdateBuilding
                buildingSelected={this.state.buildingSelected}
                handleUpdateSubmit={this.updateBuilding}
              />
        )}
          />
          )}
        <Snackbar
          snackbarOpen={snackbarOpen}
          errorMessage={errorMessage}
          message={message}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
  loading: buildings.loading,
  errorMessage: buildings.errorMessage,
  message: buildings.message,
  snackbarOpen: buildings.snackbarOpen,
});

export default connect(
  mapStateToProps,
  {
    getBuildings, updateActiveBuilding, createBuildingUsers, createVerifiedUser, updateBuilding, updateVCM,
  },
)(BuildingListContainer);
