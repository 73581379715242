import React from 'react';
import size from 'lodash/size';
import {
  LinearProgress,
  Button,
  IconButton,
  TableContainer,
  Paper
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import styled from 'styled-components';
import Table from '../Table/Table';


import '../GroupsProposals/GroupsProposals.css';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;

const GroupsApproved = ({
  loading,
  groups,
  handleUpdateGroup,
  handleDeleteGroup,
  handleCopyGroup,
  handleViewComments,
}) => {
  const filteredGroups = groups.filter((group) => group.building);
  const tableArray = React.useMemo(() => [
    {
      Header: 'Group',
      id: 'group',
      columns: [
        {
          Header: 'Title',
          accessor: 'name',
          id: 'name',
          filter: 'fuzzyText',
        },
        {
          Header: 'Building',
          width: 140,
          accessor: 'building.name',
          id: 'buildingname',
          filter: 'fuzzyText',
        },
      ],
    },
    {
      Header: 'Additional Info',
      id: 'additionalinfo',
      columns: [
        {
          Header: 'Description',
          accessor: 'description',
          id: 'description',
          width: 320,
          filter: 'fuzzyText',
        },
        {
          Header: 'Active',
          id: 'active',
          filter: 'includes',
          width: 90,
          Cell: ({ row }) => (
            <div>
              { row.original.active
                ? <div className="led led-green" />
                : <div className="led led-red" />}
            </div>
          ),
        },
        {
          Header: 'Featured',
          id: 'featured',
          filter: 'includes',
          width: 90,
          Cell: ({ row }) => (
            <div>
              { row.original.featured
                ? <div className="led led-green" />
                : <div className="led led-red" />}
            </div>
          ),
        },
        {
          Header: 'Actions',
          id: 'actions',
          width: 110,
          Cell: ({ row }) => (
            <div>
              <IconButton
                aria-label="Edit"
                color="primary"
                size="small"
                onClick={() => handleUpdateGroup(row.original)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="Delete"
                color="secondary"
                size="small"
                onClick={() => handleDeleteGroup(row.original)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="Copy"
                color="primary"
                size="small"
                onClick={() => handleCopyGroup(row.original)}
              >
                <CopyIcon />
              </IconButton>
            </div>
          ),
        },
        {
          Header: 'Comments',
          id: 'comments',
          width: 125,
          Cell: ({ row }) => {
            const { meta: { totalCommentCount } } = row.original;
            if (totalCommentCount) {
              return (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => handleViewComments(row.original.uuid)}
                >
                  {`${totalCommentCount} comment${totalCommentCount > 1 ? 's' : ''}`}
                </Button>
              );
            }
            return null;
          },
        },
      ]
    }
  ], [handleUpdateGroup, handleDeleteGroup, handleCopyGroup, handleViewComments]);

  if (loading) {
    return <LinearProgress mode="indeterminate" />;
  }

  if (!size(groups)) {
    return null;
  }


  return (
    <TableContainer className="tableContainer" component={Paper}>
      <h2>Created Groups</h2>
      <Styles>
        <Table
          tableName="groups"
          data={filteredGroups}
          totalDataCount={groups.length}
          columns={tableArray}
        />
      </Styles>
    </TableContainer>
  );
};

export default GroupsApproved;
