import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { getMerchantAndPerks, updateMerchant } from '../../actions/MerchantActions';

import CustomModal from '../../components/CustomModal/CustomModal';
import PerkFormContainer from '../PerkFormContainer/PerkFormContainer';
import Snackbar from '../../components/Snackbar/Snackbar';
import MerchantDetail from '../../components/MerchantDetail/MerchantDetail';
import MerchantFormContainer from '../MerchantFormContainer/MerchantFormContainer';

class MerchantDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: null,
      updatableObject: {},
    };
  }

  componentDidMount() {
    this.props.getMerchantAndPerks(this.props.match.params.merchantUuid);
  }

  formForModal = (type, updatableObject = {}) => {
    this.setState({
      modalType: type,
      updatableObject,
    });
  }

  render() {
    const {
      merchantLoading,
      merchant,
      perks,
      perksLoading,
      snackbarOpen,
      errorMessage,
    } = this.props;
    const { modalType, updatableObject } = this.state;

    if (merchantLoading) {
      return <LinearProgress mode="indeterminate" />;
    }
    let formComponent;
    if (modalType === 'createPerk') {
      formComponent = (
        <PerkFormContainer
          closeModal={() => this.formForModal(null)}
          merchant={merchant}
        />
      );
    } else if (modalType === 'updateMerchant') {
      formComponent = (
        <MerchantFormContainer
          updatableMerchant={merchant}
          closeModal={() => this.formForModal(null)}
        />
      );
    } else if (modalType === 'updatePerk') {
      formComponent = (
        <PerkFormContainer
          perkToUpdate={updatableObject}
          merchant={merchant}
          closeModal={() => this.formForModal(null)}
        />
      );
    }
    return (
      <div id="merchant-detail-container">
        {modalType ? (
          <CustomModal
            content={formComponent}
            closeModal={() => this.formForModal(null)}
          />
        ) : null}
        <div>
          <h4>Merchant Details</h4>
          <p>{merchant.name}</p>
          <p>{merchant.category.charAt(0).toUpperCase() + merchant.category.slice(1)}</p>
          <p>{merchant.building?.name || ''}</p>
          <Button
            style={{ paddingLeft: '0' }}
            variant="text"
            className="edit-merchant-button"
            color="primary"
            aria-label="edit merchant"
            onClick={() => this.formForModal('updateMerchant')}
          >
            Edit Merchant
          </Button>
        </div>
        <div>
          { perksLoading
            ? <CircularProgress color="primary" />
            : (
              <div>
                <Fab
                  className="create-perk-button"
                  color="primary"
                  aria-label="add"
                  onClick={() => this.formForModal('createPerk')}
                >
                  <AddIcon />
                </Fab>
                <MerchantDetail
                  onPerkClick={(perk) => this.formForModal('updatePerk', perk)}
                  loading={merchantLoading}
                  perks={perks}
                />
              </div>
            )}
        </div>
        <Snackbar
          snackbarOpen={snackbarOpen}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ merchants, perks }, props) => ({
  merchantLoading: merchants.loading,
  merchant: merchants.merchants[props.match.params.merchantUuid],
  perksLoading: perks.loading,
  perks: perks.perks,
  snackbarOpen: perks.snackbarOpen || merchants.snackbarOpen,
  errorMessage: perks.errorMessage || merchants.errorMessage,
});

export default connect(mapStateToProps, {
  getMerchantAndPerks, updateMerchant,
})(MerchantDetailContainer);
