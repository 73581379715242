import React, { Component } from 'react';

import GroupsApprovedContainer from '../GroupsApprovedContainer/GroupsApprovedContainer';
import GlobalDiscussionContainer from '../GlobalDiscussionContainer/GlobalDiscussionContainer';
import './GroupsContainer.css';

class GroupsContainer extends Component {
  render() {
    return (
      <div id="GroupsContainer">
        <GroupsApprovedContainer buildingUuid={this.props.match.params.buildingUuid} />
        <GlobalDiscussionContainer buildingUuid={this.props.match.params.buildingUuid} />
      </div>
    );
  }
}

export default GroupsContainer;
