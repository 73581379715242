import mapKeys from 'lodash/mapKeys';

import {
  FETCHING_EVENTS, EVENTS_FETCHED, EVENT_FETCHED, GET_CHECK_INS,
  EVENT_CSV_DOWNLOADED, EVENT_CSV_DOWNLOADING, OPEN_EVENT_SNACKBAR,
} from '../actions/PostActions';
import { CLOSE_SNACKBAR } from '../actions/ErrorActions';
import { RESET_EVENTS } from '../actions/AuthActions';

const initialState = {
  loading: true,
  events: {},
  csv: null,
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EVENTS:
      return { ...state, loading: true };
    case EVENTS_FETCHED:
      return { ...state, events: mapKeys(action.payload, 'uuid'), loading: false };
    case EVENT_FETCHED:
      return {
        ...state,
        events: {
          ...state.events, [action.payload.uuid]: action.payload,
        },
        loading: false,
      };
    case GET_CHECK_INS: {
      const eventWithCheckIns = {
        ...state.events[action.payload.eventUuid], checkIns: action.payload.checkIns,
      };
      const eventsWithCheckIns = { ...state.events, [action.payload.eventUuid]: eventWithCheckIns };
      return { ...state, events: eventsWithCheckIns };
    }
    case RESET_EVENTS:
      return initialState;
    case EVENT_CSV_DOWNLOADING:
      return { ...state, loading: true };
    case EVENT_CSV_DOWNLOADED:
      return { ...state, csv: action.payload, loading: false };
    case OPEN_EVENT_SNACKBAR: {
      return {
        ...state,
        snackbarOpen: true,
        errorMessage: action.payload.message,
      };
    }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
};
