import mapKeys from 'lodash/mapKeys';
import { FETCHING_BUILDINGPERKS, BUILDINGPERKS_FETCHED, HANDLE_FORM_CHANGE, BUILDINGPERK_VALID_CHANGE } from '../actions/BuildingPerkActions';

const initialState = {
  loading: true,
  buildingPerks: {},
  autoSuggestFormValue: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BUILDINGPERKS:
      return { ...state, loading: true };
    case BUILDINGPERKS_FETCHED:
      return { ...state, buildingPerks: mapKeys(action.payload, 'uuid'), loading: false };
    case HANDLE_FORM_CHANGE:
      return { ...state, autoSuggestFormValue: action.payload };
    case BUILDINGPERK_VALID_CHANGE: {
      // This takes just the perk that was edited and only changes the valid attribute
      const buildingPerkWithNewValid = {
        ...state.buildingPerks[action.payload.uuid], valid: action.payload.valid,
      };
      // then we add that edited perk into the whole buildingPerks object
      const buildingPerksWithUpdatedBuildingPerk = {
        ...state.buildingPerks, [action.payload.uuid]: buildingPerkWithNewValid,
      };
      return { ...state, buildingPerks: buildingPerksWithUpdatedBuildingPerk };
    }
    default:
      return state;
  }
};
