import axios from 'axios';
import bugsnag from 'bugsnag-js';
import _ from 'lodash';
import Cookies from "universal-cookie";
import { ROOT_URL } from '../config';
import { BUILDINGS_FETCHED } from './BuildingActions';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const SET_GROUP_EVENT_LOADING = 'SET_GROUP_EVENT_LOADING';
export const OPEN_GROUP_EVENT_SNACKBAR = 'OPEN_GROUP_EVENT_SNACKBAR';
export const GET_GROUP_EVENTS = 'GET_GROUP_EVENTS';
export const CREATE_GROUP_EVENT = 'CREATE_GROUP_EVENT';
export const UPDATE_GROUP_EVENT = 'UPDATE_GROUP_EVENT';
export const DELETE_GROUP_EVENT = 'DELETE_GROUP_EVENT';

export const getGroupEvents = (buildingId) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: SET_GROUP_EVENT_LOADING,
    payload: true,
  });
  axios({
    method: 'GET',
    url: `${ROOT_URL}/groupevent`,
    headers: { token },
    params: {
      building: [buildingId],
      sort: 'startTime DESC',
      cancelled: false,
    },
  })
    .then((groupEventResponse) => {
      dispatch({
        type: GET_GROUP_EVENTS,
        payload: {
          groupEvents: groupEventResponse.data,
        },
      });
      dispatch({
        type: SET_GROUP_EVENT_LOADING,
        payload: false,
      });
    })    .catch((error) => {
      bugsnagClient.notify(error);
    });;
};

export const createGroupEvent = (groupEvent) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'POST',
    url: `${ROOT_URL}/groupevent`,
    headers: { token },
    data: groupEvent,
  })
    .then((response) => {
      dispatch({
        type: CREATE_GROUP_EVENT,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.response.status === 404 ? "Creator email or group provided didn't match any data in the building"
            : error.response.data.message,
        },
      });
    });
};

export const updateGroupEvent = (groupEventId, groupEvent) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/groupevent/${groupEventId}`,
    headers: { token },
    data: groupEvent,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_GROUP_EVENT,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.response.data.message,
        },
      });
    });
};

export const deleteGroupEvent = (groupEventId) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'DELETE',
    url: `${ROOT_URL}/groupevent/${groupEventId}`,
    headers: { token },
    data: groupEventId,
  })
    .then((response) => {
      dispatch({
        type: DELETE_GROUP_EVENT,
        payload: {
          groupEventId: response.data.uuid,
        },
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.response.data.message,
        },
      });
    });
};
