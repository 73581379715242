import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from "universal-cookie";

import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHING_BUILDINGPERKS = 'FETCHING_BUILDINGPERKS';
export const BUILDINGPERKS_FETCHED = 'BUILDINGPERKS_FETCHED';
export const HANDLE_FORM_CHANGE = 'HANDLE_FORM_CHANGE';
export const BUILDINGPERK_VALID_CHANGE = 'BUILDINGPERK_VALID_CHANGE';

export const getBuildingPerks = buildingUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: FETCHING_BUILDINGPERKS,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/v2/perk?building=${buildingUuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: BUILDINGPERKS_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const handleAutoSuggestFormChange = value => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: HANDLE_FORM_CHANGE,
    payload: value,
  });
};

export const updatePerkClick = () => (dispatch) => {
  dispatch({
    type: FETCHING_BUILDINGPERKS,
  });
};

export const createBuildingPerk = (perkUuid, buildingUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  const data = {};
  data.perk = perkUuid;
  axios({
    method: 'POST',
    url: `${ROOT_URL}/building/${buildingUuid}/buildingperk/create`,
    headers: { token },
    data,
  })
    .then(() => {
      dispatch({
        type: BUILDINGPERKS_FETCHED,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateBuildingPerkValid = (buildingPerkUuid, currentValue) =>
  (dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    
    const newBooleanValue = !currentValue;
    const data = {};
    data.valid = newBooleanValue;

    axios({
      method: 'PATCH',
      url: `${ROOT_URL}/buildingPerk/${buildingPerkUuid}`,
      headers: { token },
      data,
    })
      .then((response) => {
        dispatch({
          type: BUILDINGPERK_VALID_CHANGE,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        bugsnagClient.notify(error);
      });
  };
