import { ContactSupportOutlined } from '@material-ui/icons';
import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from "universal-cookie";

import { ROOT_URL } from '../config';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const FETCHING_BUILDING_CONFIG = 'FETCHING_BUILDING_CONFIG';
export const BUILDING_CONFIG_FETCHED = 'BUILDING_CONFIG_FETCHED';

export const getBuildingConfig = (id) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: FETCHING_BUILDING_CONFIG,
  });

  axios({
    method: 'GET',
    url: `${ROOT_URL}/buildingconfig/${id}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: BUILDING_CONFIG_FETCHED,
        payload: response.data,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateBuildingConfig = ({buildingConfig, hidePerks, showGoogleReview, id}) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
    let data={config: {hidePerks, showGoogleReview, building: id}}
    if(buildingConfig.config) {
      const configJson = Object.values(buildingConfig.config)[0];
      configJson['hidePerks'] = hidePerks;
      configJson['showGoogleReview'] = showGoogleReview;

      data = {
        config: buildingConfig.config
      }
    } 
   
    axios({
      url: `${ROOT_URL}/buildingconfig/${buildingConfig.uuid}`,
      method: 'PATCH',
      headers: { token },
      data
    }).then(() => {
      dispatch(getBuildingConfig(id))
    }).catch((err) => {
      alert('There was an error while updating building config. Please try again later.');
      bugsnagClient.notify(err);
    });
};
