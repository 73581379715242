import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getGroupsFromUserBuildings,
  chooseGroup,
  updateTheGroup,
  deleteTheGroup,
  createGroup
} from '../../actions/GroupActions';
import { getInterests } from '../../actions/TagActions';
import GroupCommentsContainer from '../GroupCommentsContainer/GroupCommentsContainer';
import UpdateGroup from '../../components/UpdateGroup/UpdateGroup';
import CopyGroup from '../../components/CopyGroup/CopyGroup';
import DeleteGroup from '../../components/DeleteGroup/DeleteGroup';
import CustomModal from '../../components/CustomModal/CustomModal';
import GroupsApproved from '../../components/GroupsApproved/GroupsApproved';
import Snackbar from '../../components/Snackbar/Snackbar';
import '../GroupsContainer/GroupsContainer.css';


class GroupsApprovedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      overlayComments: false,
      targetGroup: null,
    };
  }

  componentDidMount() {
    const { getGroupsFromUserBuildings, getInterests } = this.props;
    getGroupsFromUserBuildings(this.props.buildingUuid);
    getInterests();
  }

  handleShowModal = (status) => {
    this.setState({ showModal: status });
  }

  handleCommentModal = (row) => {
    this.setState({
      showModal: 'comment',
      targetGroup: row,
    });
  }

  handleUpdateModal = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row);
    this.handleShowModal('update');
  }

  handleCopyModal = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row);
    this.handleShowModal('copy');
  }

  handleDeleteModal = (row) => {
    const { chooseGroup } = this.props;
    chooseGroup(row);
    this.handleShowModal('delete');
  }

  handleDeleteSubmit = (group) => {
    const { deleteTheGroup } = this.props;
    const confirmText = 'Most groups should be updated to inactive. Are you sure you want to delete this group? Deleting the group will delete all members, rsvps, and any data we have stored regarding it.';
    if (window.confirm(confirmText)) {
      deleteTheGroup(group);
      this.handleShowModal(null);
    }
  }

  renderModal = () => {
    const {
      interests,
      buildings,
      groupSelected,
      updateTheGroup: updateTheGroupAction,
      createGroup: createGroupAction,
      groups
    } = this.props;
    const { showModal, targetGroup } = this.state;
    let content;

    if (showModal === 'update') {
      content = (
        <UpdateGroup
          groupSelected={groupSelected}
          handleUpdateSubmit={updateTheGroupAction}
          closeModal={() => this.handleShowModal(null)}
          interests={interests}
        />
      );
    } else if (showModal === 'delete') {
      content = (
        <DeleteGroup
          groupSelected={groupSelected}
          handleDeleteOverlay={this.handleDeleteOverlay}
          handleDeleteSubmit={this.handleDeleteSubmit}
        />
      );
    } else if (showModal === 'comment') {
      content = <GroupCommentsContainer group={targetGroup} closeModal={() => this.handleShowModal(null)} />;
    } else if (showModal === 'copy') {
      content = (
        <CopyGroup
          groupSelected={groupSelected}
          handleCopySubmit={createGroupAction}
          closeModal={() => this.handleShowModal(null)}
          interests={interests}
          buildings={buildings}
          groups={groups}
        />
      );
    }
    return <CustomModal content={content} closeModal={() => this.handleShowModal(null)} />;
  };

  render() {
    const {
      loading,
      snackbarOpen,
      errorMessage,
      groups,
      interests,
      buildings
    } = this.props;
    const { showModal } = this.state;
    const groupsArr = Object.values(groups, 'uuid');
    const snackbar = (
      <Snackbar
        snackbarOpen={snackbarOpen}
        errorMessage={errorMessage}
      />
    );

    return (
      <div className="marginTop1">
        {snackbarOpen ? snackbar : null}
        {showModal ? this.renderModal() : null}
        <GroupsApproved
          groups={groupsArr}
          loading={loading}
          handleUpdateGroup={this.handleUpdateModal}
          handleDeleteGroup={this.handleDeleteModal}
          handleViewComments={this.handleCommentModal}
          handleCopyGroup={this.handleCopyModal}
          interests={interests}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  groups, buildings, groupEvents: { snackbarOpen, errorMessage }, tags,
}) => ({
  groups: groups.groups,
  groupSelected: groups.groupSelected,
  loading: groups.loading,
  buildings: buildings.buildings,
  groupUpdateOverlayForm: groups.groupUpdateOverlayForm,
  snackbarOpen,
  errorMessage,
  interests: tags.interests,
});

export default connect(
  mapStateToProps,
  {
    getGroupsFromUserBuildings,
    createGroup,
    chooseGroup,
    updateTheGroup,
    deleteTheGroup,
    getInterests,
  },
)(GroupsApprovedContainer);
