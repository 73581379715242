import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';

import EventFeedbackGraph from '../../components/EventFeedbackGraph/EventFeedbackGraph';
import EventFeedbackNone from '../../components/EventFeedbackNone/EventFeedbackNone';
import { getFeedback } from '../../actions/EventFeedbackActions';
import { getBuildings } from '../../actions/BuildingActions';
import GoBacktoBuildingDetail from '../../components/GoBackToBuildingDetail/GoBacktoBuildingDetail';
import './EventFeedbackContainer.css';


class EventFeedbackContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventFeedbackAnimation: 'animated fadeInUp delay-1s',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match) {
      this.props.getFeedback(match.params.eventUuid);
      this.props.getBuildings();
    }
  }

  goBack = (buildingInfo) => {
    const eventId = this.props.match ? this.props.match.params.eventUuid : 0;
    this.props.history.push(`/building/${buildingInfo.uuid}/event/${eventId}`);
  }

  render() {
    const { match } = this.props;

    const eventId = match;
    let buildingInfo = null;

    if (match) {
      buildingInfo = this.props.buildings.buildings[match.params.buildingUuid];
    }

    if (this.props.loading) {
      return <CircularProgress color="primary" />;
    }

    if (!this.props.feedback.length) {
      return (
        <div>
          <EventFeedbackNone buildingInfo={buildingInfo} />
          <GoBacktoBuildingDetail
            buildingInfo={buildingInfo}
            goBack={this.goBack}
          />
        </div>
      );
    }

    const graphTitle = this.props.feedback[0].post.title;
    return (
      <div id="event-feedback-container">
        { buildingInfo ?
          <Card id="building-title" className="animated fadeInUp">
            {buildingInfo.name}
          </Card> : null }
        <div id="EventFeedback">
          <EventFeedbackGraph
            csv={this.props.csv}
            feedback={this.props.feedback}
            eventOrBuilding={eventId}
            graphTitle={graphTitle}
            eventFeedbackAnimation={this.state.eventFeedbackAnimation}
          />
        </div>
        <GoBacktoBuildingDetail
          buildingInfo={buildingInfo}
          goBack={this.goBack}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ eventfeedback: { loading, feedback, csv }, buildings }) => (
  {
    feedback, loading, csv, buildings,
  }
);

export default connect(
  mapStateToProps,
  { getFeedback, getBuildings },
)(EventFeedbackContainer);
