import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './403Forbidden.css';
import angryPenguin from '../../images/403Penguin.png';
import { logout } from '../../actions/AuthActions';


class Forbidden extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <div>
        <div className="forbidden">
          <p>nein! nein! nein! nein!</p>
          <h1>Verboten!!</h1>
          <img src={angryPenguin} alt="403 Penguin" />
        </div>
        <div>
          <Link to="/login"> Back </Link>
        </div>
      </div>
    );
  }
}


export default connect(null, { logout })(Forbidden);
