import axios from 'axios';
import bugsnag from 'bugsnag-js';
import { ROOT_URL } from '../config';
import Cookies from "universal-cookie";

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const SET_PERK_LOADING = 'SET_PERK_LOADING';
export const PERK_CREATED = 'PERK_CREATED';
export const PERK_FETCHED = 'PERK_FETCHED';
export const PERKS_FETCHED = 'PERKS_FETCHED';
export const OPEN_PERK_SNACKBAR = 'OPEN_PERK_SNACKBAR';

export const getPerk = perkUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  dispatch({
    type: SET_PERK_LOADING,
    payload: true,
  });
  axios({
    method: 'GET',
    url: `${ROOT_URL}/perk/${perkUuid}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: PERK_FETCHED,
        payload: response.data,
      });
      dispatch({
        type: SET_PERK_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const createPerk = newPerk => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'POST',
    url: `${ROOT_URL}/perk`,
    headers: { token },
    data: newPerk,
  })
    .then(({ data }) => {
      dispatch({
        type: PERK_CREATED,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_PERK_SNACKBAR,
        payload: 'Unable to create this perk. Please try again later, or contact support.',
      });
      bugsnagClient.notify(error);
    });
};

export const updatePerk = (perk, perkUuid) => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    method: 'PATCH',
    url: `${ROOT_URL}/perk/${perkUuid}`,
    headers: { token },
    data: perk,
  })
    .then(({ data }) => {
      dispatch({
        type: PERK_CREATED,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_PERK_SNACKBAR,
        payload: `Unable to update this perk. ${error.response.data.message}`,
      });
      bugsnagClient.notify(error);
    });
};

export const getPerks = merchantUuid => (dispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  const params = {
    where: {
      merchant: merchantUuid,
    },
  };
  axios({
    method: 'GET',
    url: `${ROOT_URL}/perk/all?where=${JSON.stringify(params.where)}`,
    headers: { token },
  })
    .then((response) => {
      dispatch({
        type: PERKS_FETCHED,
        payload: response.data,
      });
      dispatch({
        type: SET_PERK_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};
