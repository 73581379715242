import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getMerchants, createMerchant } from '../../actions/MerchantActions';
import MerchantFormContainer from '../MerchantFormContainer/MerchantFormContainer';
import Snackbar from '../../components/Snackbar/Snackbar';
import MerchantList from '../../components/MerchantList/MerchantList';
import CustomModal from '../../components/CustomModal/CustomModal';

class MerchantListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { viewModal: false };
  }
  componentDidMount() {
    this.props.getMerchants();
  }

  onMerchantSelectHandler = (merchantUuid) => {
    this.props.history.push(`/merchants/${merchantUuid}`);
  }

  handleModalView = (viewable) => {
    this.setState({ viewModal: viewable });
  }

  render() {
    const { viewModal } = this.state;
    const { snackbarOpen, errorMessage } = this.props;
    return (
      <div>
        <MerchantList
          merchants={this.props.merchants}
          loading={this.props.merchantsLoading}
          onMerchantSelectHandler={this.onMerchantSelectHandler}
          handleCreateMerchant={() => this.handleModalView(true)}
        />
        {viewModal ? (
          <CustomModal
            content={<MerchantFormContainer closeModal={() => this.handleModalView(false)} />}
            closeModal={() => this.handleModalView(false)}
          />
        ) : null}
        <Snackbar
          snackbarOpen={snackbarOpen}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ merchants }) => ({
  merchantsLoading: merchants.loading,
  merchants: merchants.merchants,
  snackbarOpen: merchants.snackbarOpen,
  errorMessage: merchants.errorMessage,
});

export default connect(
  mapStateToProps,
  { getMerchants, createMerchant },
)(MerchantListContainer);
