import React, { Component } from 'react';
import { Field } from 'formik';
import { TextField, Button } from '@material-ui/core';
import Tags from '../Tags/Tags';
import './PerkForm.css';

class PerkForm extends Component {
  render() {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      touched,
      errors,
      closeModal,
      interests,
      values,
    } = this.props;

    return (
      <form id="PerkForm" autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name && touched.name}
          helperText={touched.name ? errors.name : ''}
          fullWidth
        />
        <TextField
          name="description"
          label="Description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.description && touched.description}
          helperText={touched.description ? errors.description : ''}
          rows={4}
          multiline
          fullWidth
        />
        <TextField
          name="redeemLink"
          label="Redemption Link"
          value={values.redeemLink}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.redeemLink && touched.redeemLink}
          helperText={touched.redeemLink ? errors.redeemLink : ''}
          fullWidth
        />
        <Field
          name="interests"
          component={formikProps => (
            <Tags
              {...formikProps}
              type="interests"
              tagObjects={interests}
            />)
          }
        />
        <div className="button-container">
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export default PerkForm;
