import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBuildingsSimple } from '../../actions/BuildingActions';

import BuildingChatCommentsContainer from '../BuildingChatCommentsContainer/BuildingChatCommentsContainer';
import CustomModal from '../../components/CustomModal/CustomModal';
import GlobalDiscussion from '../../components/GlobalDiscussion/GlobalDiscussion';
import Snackbar from '../../components/Snackbar/Snackbar';
import '../GroupsContainer/GroupsContainer.css';

class GlobalDiscussionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      targetBuilding: null,
    };
  }

  async componentDidMount() {
    const { getBuildingsSimple } = this.props;
    await getBuildingsSimple();
  }

  handleShowModal = (status) => {
    this.setState({ showModal: status });
  }

  handleCommentModal = (row) => {
    this.setState({
      showModal: 'comment',
      targetBuilding: row,
    });
  }

  renderModal = () => {
    const { showModal, targetBuilding } = this.state;
    let content;

    if (showModal === 'comment') {
      content = <BuildingChatCommentsContainer building={targetBuilding} closeModal={() => this.handleShowModal(null)} />;
    }
    return <CustomModal content={content} closeModal={() => this.handleShowModal(null)} />;
  };

  render() {
    const {
      snackbarOpen,
      errorMessage,
      buildings,
      loading,
      buildingUuid
    } = this.props;
    const { showModal } = this.state;
    const snackbar = (
      <Snackbar
        snackbarOpen={snackbarOpen}
        errorMessage={errorMessage}
      />
    );

    return (
      <div className="marginTop1">
        {snackbarOpen ? snackbar : null}
        {showModal ? this.renderModal() : null}
        <GlobalDiscussion
          buildings={[buildings[buildingUuid]]}
          loading={loading}
          handleViewComments={this.handleCommentModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
  loading: buildings.loading,
});

export default connect(
  mapStateToProps, { getBuildingsSimple },
)(GlobalDiscussionContainer);
