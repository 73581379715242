import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import momentTimezone from "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import * as Yup from "yup";
import { getGroups } from "../../actions/GroupActions";
import {
  createGroupEvent,
  updateGroupEvent,
} from "../../actions/GroupEventActions";
import GroupEventForm from "../../components/GroupEventForm/GroupEventForm";
import "../../components/GroupEventForm/GroupEventForm.css";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "string"];
const GroupEventSchema = Yup.object().shape({
  building: Yup.string().required("Required"),
  group: Yup.string().required("Required"),
  creatorEmail: Yup.string().email("Invalid email").required("Required"),
  title: Yup.string()
    .max(100, "The title must be no more than 100 characters")
    .required("Required"),
  startTime: Yup.date()
    .min(moment(), "This date has already occured")
    .max(moment().add(1, "y"), "This date is too far in the future")
    .required("Required"),
  locationString: Yup.string()
    .max(250, "The title must be no more than 250 characters")
    .required("Required"),
  description: Yup.string()
    .min(20, "The description must be at least 20 characters")
    .required("Required"),
  maxGuests: Yup.number()
    .min(0, 'Max Guests must be at least 0')
    .max(30, 'Max Guests cannot exceed 30 guests')
    .required('Please include the maximum number of guests one can bring'),
  maxEventAttendees: Yup.number()
    .min(0, 'Max Capacity must be at least 0')
    .max(500, 'Max Capacity cannot exceed 500')
    .required('Please include the maximum number of spaces available'),
  image: Yup.mixed()
    .required("Uploading an image is required")
    .test(
      "fileFormat",
      "Image must be .jpg, .jpeg, or .png",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

class GroupEventFormContainer extends Component {
  handleSubmitPress = (values, formType) => {
    if (formType === "create") {
      this.handleCreateGroupEvent(values);
    }
    if (formType === "duplicate") {
      this.handleCreateGroupEvent(values);
    }
    if (formType === "update") {
      this.handleUpdateGroupEvent(values);
    }
  };

  handleCreateGroupEvent = (values) => {
    const { closeForm, buildings } = this.props;
    const data = new FormData();
    const building = buildings[values.building];
    const timeInTimezone = moment
      .tz(values.startTime, building.timezone)
      .format();
    data.append("title", values.title.trim());
    data.append("description", values.description.trim());
    data.append("type", "event");
    data.append("group", values.group);
    data.append("creatorEmail", values.creatorEmail);
    data.append("locationString", values.locationString.trim());
    data.append("whatToBring", values.whatToBring.trim());
    if (values.maxEventAttendees)
      data.append("maxEventAttendees", values.maxEventAttendees);
    if (values.maxGuests) data.append("maxGuests", values.maxGuests);
    data.append("startTime", timeInTimezone);
    data.append("image", values.image);

    this.props.createGroupEvent(data);
    closeForm();
  };

  handleUpdateGroupEvent = (values) => {
    const { updatableGroupEvent, closeForm } = this.props;
    const data = new FormData();
    const building = this.props.buildings[values.building];
    const timeInTimezone = moment
      .tz(values.startTime, building.timezone)
      .format();
    data.append("title", values.title.trim());
    data.append("description", values.description.trim());
    data.append("type", "event");
    data.append("locationString", values.locationString.trim());
    data.append("whatToBring", values.whatToBring.trim());
    data.append("creatorEmail", values.creatorEmail);
    if (values.maxEventAttendees)
      data.append("maxEventAttendees", values.maxEventAttendees);
    if (values.maxGuests) data.append("maxGuests", values.maxGuests);
    data.append("startTime", timeInTimezone);
    if (values.image !== updatableGroupEvent.image) {
      data.append("image", values.image);
    }

    this.props.updateGroupEvent(updatableGroupEvent.uuid, data);
    closeForm();
  };

  renderFormTitle() {
    const { updatableGroupEvent, formType } = this.props;
    if (formType === "duplicate")
      return `Duplicate ${updatableGroupEvent.title}`;
    if (formType === "update") return `Update ${updatableGroupEvent.title}`;
    return "Create a Group Gathering";
  }

  render() {
    const {
      loading,
      buildings,
      groups,
      updatableGroupEvent,
      formType,
    } = this.props;
    if (loading) {
      return <CircularProgress color="primary" />;
    }

    let initialValues;
    let initialTouched;
    if (updatableGroupEvent) {
      const {
        building,
        group,
        creator,
        image,
        title,
        startTime,
        locationString,
        description,
        whatToBring,
        maxEventAttendees,
        maxGuests,
      } = updatableGroupEvent;
      initialValues = {
        building: formType === "duplicate" ? "" : building.uuid,
        group: formType === "duplicate" ? "" : group.uuid,
        creatorEmail: formType === "duplicate" ? "" : creator.email,
        image:
          formType === "duplicate" ? null : { preview: image, type: "string" },
        title,
        startTime: startTime,
        locationString,
        description,
        whatToBring,
        maxEventAttendees,
        maxGuests,
      };
      initialTouched = {
        building: true,
        group: true,
        creatorEmail: true,
        title: true,
        startTime: true,
        locationString: true,
        description: true,
        whatToBring: true,
        maxEventAttendees: true,
        maxGuests: true,
        image: true,
      };
    } else {
      initialValues = {
        building: "",
        group: "",
        creatorEmail: "",
        title: "",
        startTime: "",
        locationString: "",
        description: "",
        whatToBring: "",
        maxEventAttendees: 0,
        maxGuests: 0,
        image: {},
      };
    }

    return (
      <div className="create-group-event-container">
        <h3>{this.renderFormTitle()}</h3>
        <Formik
          initialValues={initialValues}
          initialTouched={initialTouched}
          validateOnMount={!!updatableGroupEvent}
          validationSchema={GroupEventSchema}
          onSubmit={(values) => this.handleSubmitPress(values, formType)}
        >
          {(formikProps) => (
            <GroupEventForm
              {...formikProps}
              selectOptions={{ buildings, groups }}
              getGroups={this.props.getGroups}
              updateForm={Boolean(updatableGroupEvent)}
              formType={this.props.formType}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { buildings, groups } = state;
  return {
    buildings: buildings.buildings,
    groups: groups.groups,
  };
};

export default connect(mapStateToProps, {
  getGroups,
  createGroupEvent,
  updateGroupEvent,
})(GroupEventFormContainer);
